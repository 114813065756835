import EportalDashboard from "containers/private/e-portal/dashboard";
import HostelPortal from "containers/private/e-portal/hostel-portal";
import EPShortCoursesListing from "containers/private/e-portal/short-courses/listing";
import GenerateChallan from "containers/private/e-portal/generate-challan";
import MyVouchers from "containers/private/e-portal/my-vouchers/Index";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import EportalViewProfile from "containers/private/e-portal/profile/view-profile";
import ApplicationList from "containers/private/e-portal/admissions/listing";
import CreateEportalAdmissionsList from "containers/private/e-portal/admissions/create";
import CareerListing from "containers/private/e-portal/careers/career-listing";
import EditEPortalCareer from "containers/private/e-portal/careers/edit-career";
import AppliedJobsListing from "containers/private/e-portal/careers/applied-jobs";
import EportalViewCareerProfile from "containers/private/e-portal/careers/career-profile";
import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import EportalTestingServices from "containers/private/e-portal/testing-services";
import EditEPortalProfile from "containers/private/e-portal/admissions/edit-profile";
import EditEPStudentProfile from "containers/private/e-portal/profile/edit-profile";
import ViewEPStudentProfile from "containers/private/e-portal/profile/view-profile";

const EportalRoutes: FC = () => {
  const { getPathToSetRoute } = useUtils();
  return (
    <Routes>
      <Route path={getPathToSetRoute(siteRoutes.ePortalDashboard)} Component={EportalDashboard} />
      <Route path={getPathToSetRoute(siteRoutes.ePortalShortCoursesListing)} Component={EPShortCoursesListing} />
      <Route path={getPathToSetRoute(siteRoutes.ePortalEditProfile, true)} Component={EditEPortalProfile} />
      <Route path={getPathToSetRoute(siteRoutes.ePortalMyVouchers)} Component={MyVouchers} />
      <Route path={getPathToSetRoute(siteRoutes.ePortalChallanListing)} Component={GenerateChallan} />
      <Route path={getPathToSetRoute(siteRoutes.eportalHostelPortal)} Component={HostelPortal} />
      <Route path={getPathToSetRoute(siteRoutes.viewEportalProfile, true)} Component={EportalViewProfile} />
      <Route
        path={getPathToSetRoute(siteRoutes.eportalAdmissionsListing, true)}
        Component={ApplicationList}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createEportalAdmissionList, true)}
        Component={CreateEportalAdmissionsList}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.eportalCareersListing, true)}
        Component={CareerListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.createEportalCareer, true)}
        Component={EditEPortalCareer}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.eportalAppliedJobListing,true)}
        Component={AppliedJobsListing}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.eportalCarrerProfile, true)}
        Component={EportalViewCareerProfile}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.eportalTestingServiceListing)}
        Component={EportalTestingServices}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.editEPStudentProfile, true)}
        Component={EditEPStudentProfile}
      />
        <Route
        path={getPathToSetRoute(siteRoutes.viewEPStudentProfile, true)}
        Component={ViewEPStudentProfile}
      />
    </Routes>
  );
};

export default EportalRoutes;
