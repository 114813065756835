import { FC, Fragment, useEffect, useState } from "react";
import { RoomsListingMain, RoomsListingSection, RoomsListingTop } from "./style";
import { DeleteTableSvg, EditTableSvg, ExcelSvg, PdfSvg, RoomActionIconSvg, SearchFieldSvg } from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import { useNavigate } from "react-router-dom";
import useAlert from "hooks/useAlert";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useStore from "hooks/useStore";
import Pagination from "components/particles/table/pagination";
import dummyRoom from "../../../../../assets/images/common/others/avatar-8.png"
import useOrganization from "../../useHooks";

interface AssetsListingProps { }

const RoomsListing: FC<AssetsListingProps> = ({ }) => {
    const breadcrumbLinks = [
        { title: "Infrastructure & Asset Management /", path: "" },
        { title: "Buildings /", path: siteRoutes.buildingListing },
        { title: "Rooms", path: siteRoutes.roomsListing },
    ]
    const columns: string[] = [
        'Image',
        'Title',
        'Number',
        'Type',
        'Length',
        'Width',
        'Floors',
        'Action'
    ];
    const navigate = useNavigate();

    const { getRoomListById, deleteBuildingRoomById } = useOrganization();
    const { confirmationPopup } = useAlert();
    const { hasAccess } = useStore();

    // const [queryParams, setQueryParams] = useState<any>({ search: '', page: 1 });
    const [search, setSearch] = useState("");
    const [data, setData] = useState<any>([])
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        totalRecords: 1,
    });
    const goToCreateRooms = () => {
        navigate(siteRoutes.createRooms);
    }

    const handleDeleteRoom = async (id: number) => {
        const result = await confirmationPopup();

        if (result?.isConfirmed) {
            deleteBuildingRoomById(id, setData, pagination, setPagination);
            getRoomListById(setData, "All", setPagination);
        };
    }
    const onPageChange = (pageInfo: { selected: number }) => {
        const { selected: page } = pageInfo;
        setPagination({ ...pagination, page: page + 1 });
        getBuildingRoomsList(page + 1, search);
    };

    const goToUpdateBuildingRoomById = (id: number) => {
        navigate(`${siteRoutes.createRooms}?id=${id}`);
    }

    const getBuildingRoomsList = (page: number, search: string) => {
        const queryParams = {
            per_page: pagination.per_page,
            page,
            search,
        };
        // setData([]); // temporary set due to page get prev page some items
        getRoomListById(setData, queryParams, setPagination);
    }

    useEffect(() => {
        getBuildingRoomsList(pagination.page, search);
    }, [pagination.page, search]);

    console.log(pagination);

    return (
        <RoomsListingMain>
            <RoomsListingTop>
                <div className="left">
                    <span className="page-heading">Rooms</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
                <div className="right">
                    {
                        hasAccess(sitePermissions.createBuilding) && <div className="create-org-btn">
                            <button className="lg-rounded-btn" onClick={goToCreateRooms} >
                                + Add Rooms
                            </button>
                        </div>
                    }
                </div>
            </RoomsListingTop>
            <RoomsListingSection className="content-radius-shadow">
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        {hasAccess(sitePermissions.downloadBuildingListPdf) && <div className="export-btn">
                            <span>
                                <PdfSvg className="icon" />
                            </span>
                            <span className="text">PDF</span>
                        </div>}
                        {
                            hasAccess(sitePermissions.downloadBuildingListExcel) && <div className="export-btn">
                                <span>
                                    <ExcelSvg className="icon" />
                                </span>
                                <span className="text">Excel</span>
                            </div>
                        }


                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input type="search" placeholder="Search" />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item: any, index: number) => {
                                return <tr key={index}>
                                    <td>
                                        <div className="room-image">
                                            <img src={item?.room_image ?? dummyRoom} alt="" />
                                        </div>
                                    </td>
                                    <td>{item?.room_name}</td>
                                    <td>{item?.room_number}</td>
                                    <td>{item?.room_type?.room_type_title}</td>
                                    <td>{item?.length} square feet</td>
                                    <td>{item?.width} square feet</td>

                                    <td>{item?.floor ?? "-"}</td>
                                    <td>
                                        <div className="table-action-icons">
                                            {
                                                hasAccess(sitePermissions.editBuilding) && (
                                                    <div className="action-icon" onClick={() => goToUpdateBuildingRoomById(item?.id)}>
                                                        <EditTableSvg />
                                                    </div>
                                                )
                                            }

                                            {
                                                hasAccess(sitePermissions.deleteBuilding) &&
                                                <div className="action-icon" onClick={() => handleDeleteRoom(item.id)}>
                                                    <DeleteTableSvg />
                                                </div>
                                            }

                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                    <Pagination {...pagination} onPageChange={onPageChange} />
                </Fragment>
            </RoomsListingSection>
        </RoomsListingMain>
    )
}

export default RoomsListing;