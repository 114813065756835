import { FC, Fragment, useEffect, useState } from "react";
import {
  AdditionalInfoWrapper,
  BasicInfoWrapper,
  EDContentSection,
  EportalDashboardMain,
  EportalDashboardTop,
  NoticeBoardSection,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  AdmissionSvg,
  CareerSvg,
  ChallanSvg,
  ShortCoursesSvg,
} from "assets/images/e-portal/svgs";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import dummyCoverImage from "assets/images/common/others/eportal-cover.jpg";
import dummyProfileImage from "assets/images/common/others/dummy-avatar.png";
import outlines from "assets/images/common/others/outlines.png";

import {
  DeleteTableSvg,
  DownloadApplicationSvg,
  DownloadPrimaryTableSvg,
  DownloadTableActionSvg,
  EditTableSvg,
  EventCalendarSvg,
  MetallicGrayUploadTableSvg,
  ProfileWhiteSvg,
  TableCloudStorageSvg,
  TabPrimaryActionMenu,
  UpcommingEventSvg
} from "assets/images/common/svgs";
import { useSelector } from "react-redux";
import useEportal from "../useHooks";
import DataNotFound from "components/particles/table/data-not-found";
import { confirmationPopup, infoPopup } from "utils/helpers/common/alert-service";
import useUtils from "hooks/useUtils";
import useMultiClickOutside from "hooks/multi-click-outside";
import useStore from "hooks/useStore";
import { AddAdmissionApplicationDTO } from "utils/helpers/models/e-portal/add-admission-application.dto";
import ViewStudentApplication from "./components/view-application";

interface EportalDashboardProps { }

const EportalDashboard: FC<EportalDashboardProps> = ({ }) => {
  const breadcrumbLinks = [
    { title: "Admission Eportal /", path: "" },
    { title: "Eportal Home", path: siteRoutes.ePortalDashboard },
  ];
  const {currentIndex,refs,setOpen} = useMultiClickOutside();
  const navigate = useNavigate();
  const [myVouchers, setMyVouchers] = useState<any[]>([]);
  const [applications, setApplications] = useState<any[]>([]);
  const [shortCourses, setShortCourses] = useState<any[]>([]);
  const [openViewAppDialog, setOpenViewAppDialog] = useState<boolean>(false);
  const [applicationToView, setApplicationToView] = useState<AddAdmissionApplicationDTO | null>(null); 
  const { user, isLoading } = useSelector((state: any) => state.sharedReducer);
  const {getOrganization} = useStore();
  const organization = getOrganization();
  const { getMyVouchers, getStudentApplications, admissionDownloadApplication, deleteUserAdmissionApplication, admissionGenerateFeeChallan } = useEportal();
  const { getDateFromDateTime } = useUtils();
  const features = [
    // {
    //   title: "User Profile",
    //   icon: MobileProfileSvg,
    //   link: siteRoutes.viewEportalProfile,
    // },

    //    commit due to backend team decission
    // {
    //   title: "Generate Challan",
    //   icon: ChallanSvg,
    //   link: siteRoutes.ePortalChallanListing,
    // },
    // {
    //   title: "Generate Vouchersd",
    //   icon: VoucherSvg,
    //   link: siteRoutes.ePortalMyVouchers,
    {
      title: "Admission",
      icon: AdmissionSvg,
      link: siteRoutes.ePortalEditProfile,
    },
    // },
    {
      title: "Careers",
      icon: CareerSvg,
      link: siteRoutes.eportalCareersListing,
      notAvailable: true
    },
    // {
    //   title: "Testing Service",
    //   icon: TestingServiceSvg,
    //   link: "#",
    //   // link: "/private/e-portal/user-profile",
    // },
    // {
    //   title: "Hostel",
    //   icon: HostelSvg,
    //   // link: siteRoutes.eportalHostelPortal,
    //   link: "#",
    // },
    {
      title: "Short Courses",
      icon: ShortCoursesSvg,
      link: "#",
      notAvailable: true
      // link: siteRoutes.ePortalShortCoursesListing,
    },
    {
      title: "My Vouchers",
      icon: ChallanSvg,
      link: "#",
      notAvailable: true
    },
  ];

  const adminApplicationColumns: string[] = [
    "Application No.",
    "program",
    "applied Date",
    "action",
  ];
  const shortCoursesColumns: string[] = [
    "Course Name",
    "Start Date",
    "Fees",
    "Duration",
    "Action",
  ];
  const voucherColumns: string[] = [
    "title",
    "challan no.",
    "due date",
    "amount",
    "status",
    "action",
  ];
  const navigateTo = (quickLink: any) => {
    const { link, title, notAvailable = false } = quickLink;
    if (notAvailable) {
      infoPopup('This feature is currently not available');
    } else {
      if (title === 'Admission') {
        let linkToGo = link;
        if (user?.is_already_apply) {
          if (applications.length) {
            linkToGo = siteRoutes.eportalAdmissionsListing
          } else {
            linkToGo = siteRoutes.createEportalAdmissionList;
          }
        }

        navigate(linkToGo);
      } else {
        navigate(link);
      }
    }
  }

  useEffect(() => {
    getMyVouchers(setMyVouchers);
    getStudentApplications(setApplications);
  }, []);

  const downloadApplication = (app_no: string) => {
    admissionDownloadApplication(app_no);
  }

  const downloadVoucher = (app_no: string) => {
    admissionGenerateFeeChallan(app_no);
  }

  const toggleDropdown = (index: number) => {
    const appsHelper = applications.map((application, i) => {
      if(index !== i){
        return{
          ...application,
          isDropdownOpen: false
        }
      }else{
        return application;
      }
    });
    appsHelper[index].isDropdownOpen = !appsHelper[index].isDropdownOpen;
    setApplications([...appsHelper]);
  };

  const handleOpenViewAppDialog = (application: AddAdmissionApplicationDTO) => {
    setOpenViewAppDialog(true);
    setApplicationToView(application);
    // setApplications
  }

  const handleDeleteApplication = async (appId: number) => {
    const result = await confirmationPopup();
    if(result.isConfirmed){
      deleteUserAdmissionApplication(appId, setApplications);
    }
  }

  return (
    <EportalDashboardMain>
      <EportalDashboardTop>
        <div className="left">
          <span className="page-heading">Eportal Home</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </EportalDashboardTop>
      <EDContentSection>
        <BasicInfoWrapper>
          <div className="cover-part">
            <img src={organization?.cover_image ?? dummyCoverImage} alt="" />
          </div>
          <div className="info-section">
            <div className="info-section-left">
              <div className="profile-info">
                <div className="profile-image">
                  <img src={user?.profile_image ?? dummyProfileImage} alt="" />
                </div>
                <div className="user-name">
                  <span>{user?.name}</span>
                </div>
              </div>
            </div>
            <div className="info-section-right">
              <div className="buttons-part">
                <div className="profile-btn">
                  <button className="lg-rounded-btn iconed bold" onClick={() => navigate(siteRoutes.editEPStudentProfile)}>
                    <div className="icon">
                      <ProfileWhiteSvg />
                    </div>
                    <span>Edit Profile</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </BasicInfoWrapper>

        <AdditionalInfoWrapper>
          <div className="left-section">
            <div className="section quick-links-section">
              <div className="section-header">
                <div className="heading">
                  <span>Quick Links</span>
                </div>
              </div>
              <div className="section-content">
                <div className="quick-links-wrapper">
                  {features.map((item: any, index: number) => {
                    const Icon = item.icon;
                    return (
                      <div
                        className="particular-link"
                        key={index}
                        onClick={() => navigateTo(item)}
                      >
                        <div className="inner-content">
                          <div className="feature-icon">
                            <Icon className="icon" />
                          </div>
                          <div className="feature-text">
                            <span>{item.title}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="section admin-applications">
              <div className="section-header">
                <div className="heading">
                  <span>Admission Applications</span>
                </div>
              </div>
              <div className="section-content">
                <div className={`data-table ${currentIndex !== null ? 'menu-opened' : ''}`}>
                  <table className="bottom-bordered-cells">
                    <thead>
                      <tr>
                        {adminApplicationColumns.map((column, index) => {
                          return (
                            <th key={index} className="uppercase">
                              {column}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {applications.map((application, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="mw-100 underline cp" onClick={() => handleOpenViewAppDialog(application)}>
                              {application.app_no}
                              </div>
                            </td>
                            <td>{application?.program}</td>
                            <td>{getDateFromDateTime(application?.app_date)}</td>
                            <td>
                              <div className="action-menu" ref={el => refs.current.push(el!)}>
                                <div
                                  className="menu-icon cp"
                                  onClick={() =>
                                    setOpen(index)
                                  }
                                >
                                  <TabPrimaryActionMenu className="icon" />
                                </div>
                                {currentIndex === index && (
                                  <div className="table-menu-dropdown">
                                    {application?.p_fee_template_link && <div
                                      className="particular-menu cp"
                                      onClick={() => downloadVoucher(application?.app_no)}
                                    >
                                      <div className="action-icon">
                                        <DownloadApplicationSvg className="icon" />
                                      </div>
                                      <span className="title">Download Fee Challan</span>
                                    </div>}
                                    <div
                                      className="particular-menu cp"
                                      onClick={() => downloadApplication(application?.app_no)}
                                    >
                                      <div className="action-icon">
                                        <DownloadPrimaryTableSvg className="icon" />
                                      </div>
                                      <span className="title">
                                        Download Application
                                      </span>
                                    </div>
                                    <div
                                      className="particular-menu cp"
                                      onClick={() => handleDeleteApplication(application?.id)}
                                    >
                                      <div className="action-icon">
                                        <DeleteTableSvg className="icon" />
                                      </div>
                                      <span className="title">
                                        Delete Application
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Fragment>
                    <DataNotFound show={!isLoading && !applications.length} />
                  </Fragment>
                </div>
              </div>
            </div>
            <div className="section short-courses">
              <div className="section-header">
                <div className="heading">
                  <span>Enrolled Short courses</span>
                </div>
              </div>
              <div className="section-content">
                <div className="data-table">
                  <table className="bottom-bordered-cells">
                    <thead>
                      <tr>
                        {shortCoursesColumns.map((column, index) => {
                          return (
                            <th key={index} className="uppercase">
                              {column}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {shortCourses.map((course, index) => {
                        return (
                          <tr key={index}>
                            <td>Web-Development</td>
                            <td>2023-08-21</td>
                            <td>6000</td>
                            <td>2-Months</td>
                            <td>
                              <div className="table-action-icons">
                                <div className="action-icon">
                                  <EditTableSvg />
                                </div>
                                <div className="action-icon">
                                  <DeleteTableSvg />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Fragment>
                    <DataNotFound show={!isLoading && !shortCourses.length} />
                  </Fragment>
                </div>
              </div>
            </div>
            <div className="section vouchers">
              <div className="section-header">
                <div className="heading">
                  <span>Voucher Details</span>
                </div>
              </div>
              <div className="section-content">
                <div className="data-table">
                  <table className="bottom-bordered-cells">
                    <thead>
                      <tr>
                        {voucherColumns.map((column, index) => {
                          return (
                            <th key={index} className="uppercase">
                              {column}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {myVouchers.map((voucher, index) => {
                        return (
                          <tr key={index}>
                            <td>Test Fee</td>
                            <td>2626541253</td>
                            <td>2023-08-21</td>
                            <td>1000</td>
                            <td>
                              <span className="status-tile green">Paid</span>
                            </td>
                            <td>
                              <div className="table-action-icons">
                                <div className="action-icon">
                                  <DownloadTableActionSvg />
                                </div>
                                <div className="action-icon">
                                  <MetallicGrayUploadTableSvg />
                                </div>
                                <div className="action-icon">
                                  <TableCloudStorageSvg />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Fragment>
                  <DataNotFound show={!myVouchers.length && !isLoading} />
                </Fragment>
              </div>
            </div>
          </div>
          <div className="right-section">
            <div className="section upcoming-events-section">
              <div className="section-header">
                <div className="heading">
                  <span>Up-Coming Events</span>
                </div>
              </div>
              <div className="upcomming-event-section">
                <div className="event-top">
                  <div className="top-right">Upcoming Events</div>
                  <div className="top-left">View All</div>
                </div>
                {[].length ? (
                  [].map(() => (
                    <div className="event-description-main">
                      <div className="">
                        <UpcommingEventSvg />
                      </div>
                      <div className="event-desctiption-container">
                        <div className="event-title">UCV International Conference</div>
                        <div className="even-timings">
                          <EventCalendarSvg className="fill" />
                          <div className="times">
                            15 November 2024 <span>09:10AM - 10:50PM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-records">No Events Found</div>
                )}
              </div>
            </div>
            <NoticeBoardSection className="content-radius-shadow">
              <div className="notice-wrapper">
                {[].length ? (
                  [].map((item, index) => (
                    <div key={index}>
                      <div className="issuance-date">
                        <span className="date">15 November 2024</span>
                      </div>
                      <div className="particular-notice">
                        <div className="left">
                          <div className="notice-thumbnail">
                            <img src={outlines} alt="outlines" />
                          </div>
                          <div className="notice-info">
                            <div className="notice-title">
                              Fixation of Last Date to Deposit Annual Fee for Bachelor Degree Programs Fall 2024
                            </div>
                            <div className="notice-description">
                              Note: No other channels will be accepted for clearance from now on
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-records">No Notices Found</div>
                )}

              </div>
            </NoticeBoardSection>
          </div>
        </AdditionalInfoWrapper>
      </EDContentSection>
      {openViewAppDialog ? <ViewStudentApplication application={applicationToView} setApplications={applications} setOpen={setOpenViewAppDialog}/> : ''}
    </EportalDashboardMain>
  );
};

export default EportalDashboard;
