import { ChangeEvent, FC, useEffect, useState } from "react";
import Select from "react-select";
import { CreateOrgMain, Form, TopSection } from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import profileLogo from "assets/images/organization/others/profile-logo.png";
import Editor from "components/particles/forms/editor";
import { useForm } from "react-hook-form";
import { OrganizationDTO } from "utils/helpers/models/organization/organization.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useUtils from "hooks/useUtils";
import useOrganization from "../../useHooks";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const CreateOrganization: FC = () => {
  const breadcrumbLinks = [
    { title: "Organization /", path: siteRoutes.organizationListing },
    { title: "Add Organization", path: siteRoutes.createOrganization },
  ];
  const { getQueryParams } = useUtils();
  let [formData, setFormData] = useState<OrganizationDTO>(
    new OrganizationDTO()
  );
  const {
    createOrganization,
    getCitiesSuperAdmin,
    getDistrictsSuperAdmin,
    getOrganizationById,
    updateOrganization,
    getStatesBySuperAdmin,
  } = useOrganization();
  const params = getQueryParams();

  const [cities, setCities] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<OrganizationDTO>({
    defaultValues: formData,
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    formData[name as keyof OrganizationDTO] = value as never;
    setFormData({ ...formData });
    trigger(name);
  };

  const onSubmit = (data: OrganizationDTO) => {
    if (!formData.logo) {
      warningToaster(warningMessages.logoRequiredMsg);
      return;
    }
    const form_data = new FormData();
    for (let key in data) {
      form_data.append(key, data[key as keyof OrganizationDTO]);
    }
    if (params?.id) {
      updateOrganization(form_data, params?.id);
    } else {
      createOrganization(form_data);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getOrganizationById(params?.id, getValues, setValue, setFormData);
    }
    getCitiesSuperAdmin(setCities, "All", formData.city);
    getDistrictsSuperAdmin(setDistricts, "All", formData.state);
    getStatesBySuperAdmin(setStates);
  }, []);

  const handleUpload = (event: any, name: keyof typeof formData) => {
    const file = event.target.files![0];
    if (file) {
      setValue(name, file);
      setFormData(prev => ({ ...prev, [name]: URL.createObjectURL(file) }));
      trigger([name]);
    }
    console.log(name);
  };

  const resetForm = () => {
    for (let key in getValues()) {
      setValue(key as keyof OrganizationDTO, "");
    }
    formData = new OrganizationDTO();
    setFormData({ ...formData });
  };

  const onRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target as any;
    setValue(name, value);
    setFormData({ ...formData, [name]: value });
    trigger([name]);
  };
  // Prepare data for react-select
  const cityOptions = cities.map((city: any) => ({
    value: city.id,
    label: city.title,
  }));

  const districtOptions = districts.map((district: any) => ({
    value: district.id,
    label: district.title,
  }));

  const stateOptions = states.map((state: any) => ({
    value: state.id,
    label: state.name,
  }));

  const handleStateChange = (option: any) => {
    setValue("state", option?.value);
    setFormData({ ...formData, state: option?.value });
    trigger("state");
    if (option?.value) {
      getDistrictsSuperAdmin(setDistricts, "All", option.value);
    } else {
      setDistricts([]);
      setCities([]);
    }
  };
  const handleDistrictChange = (option: any) => {
    setValue("district", option?.value);
    setFormData({ ...formData, district: option?.value });
    trigger("district");
    if (option?.value) {
      getCitiesSuperAdmin(setCities, "All", option.value);
    } else {
      setCities([]);
    }
  };

  //   cover_image
  // fav_icon
  // sidebar_dark_logo
  // sidebar_light_logo
  // dark_logo
  // login_page_image 


  const imageSUpdateObj = [
    { htmlFor: "org-logo", imgSrc: formData?.logo ?? profileLogo, alterImg: "organization logo", name: "logo", label: "Organization Logo", isRequired: true },
    { htmlFor: "sidebar_dark_logo", imgSrc: formData?.sidebar_dark_logo ?? profileLogo, alterImg: "side dark logo", name: "sidebar_dark_logo", label: "Side Bar Dark Logo", isRequired: true },
    { htmlFor: "sidebar_light_logo", imgSrc: formData?.sidebar_light_logo ?? profileLogo, alterImg: "side light logo", name: "sidebar_light_logo", label: "Side Bar Light Logo", isRequired: true },
    { htmlFor: "dark_logo", imgSrc: formData?.dark_logo ?? profileLogo, alterImg: "dark logo", name: "dark_logo", label: "Dark Logo", isRequired: true },
    { htmlFor: "fav_icon", imgSrc: formData?.fav_icon ?? profileLogo, alterImg: "Fav Icon", name: "fav_icon", label: "Favicon", isRequired: true },
    { htmlFor: "cover_image", imgSrc: formData?.cover_image ?? profileLogo, alterImg: "Cover Image", name: "cover_image", label: "Cover Image", isRequired: true },
    { htmlFor: "login_page_image ", imgSrc: formData?.login_page_image ?? profileLogo, alterImg: "login page Logo", name: "login_page_image", label: "Login Page Image ", isRequired: true },
  ];

  useEffect(() => {
    imageSUpdateObj.map((item: any) => register(item?.name, { required: item?.isRequired }))
  }, []);

  return (
    <CreateOrgMain>
      <TopSection>
        <span className="page-heading">
          {params?.id ? "Update" : "Add"} Organization
        </span>
        {!params?.id && <Breadcrumb links={breadcrumbLinks} />}
      </TopSection>
      <Form className="content-radius-shadow" onSubmit={handleSubmit(onSubmit)}>

        <div className="common-fields">
          {/* Organization Name */}
          <div className="input-field">
            <label>Organization Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("name", { required: true })}
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="xyz"
                />
              </div>
              <FormErrorMessage error={errors?.name} />
            </div>
          </div>

          {/* Website */}
          <div className="input-field">
            <label>Website</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="url"
                  placeholder="http://xyzdomain.com"
                  {...register("website", { required: true })}
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.website} />
            </div>
          </div>

          {/* Email */}
          <div className="input-field">
            <label>Email</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="email"
                  placeholder="xyz@gmail.com"
                  {...register("email", { required: true })}
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.email} />
            </div>
          </div>

          {/* Phone */}
          <div className="input-field">
            <label>Phone</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="tel"
                  placeholder="+92 000000000"
                  {...register("phone", { required: true })}
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.phone} />
            </div>
          </div>

          {/* Address */}
          <div className="input-field">
            <label>Address</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  placeholder="Street abc"
                  {...register("address1", { required: true })}
                  value={formData.address1}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.address1} />
            </div>
          </div>

          {/* State */}
          <div className="input-field">
            <label>Select State</label>
            <div className="field-wrap">
              <Select
                options={stateOptions}
                onChange={handleStateChange}
                value={stateOptions.find(
                  (option) => option.value == formData.state
                )}
                placeholder="Select State"
              />

              <FormErrorMessage error={errors?.state} />
            </div>
          </div>
          {/* District */}
          {districtOptions && (
            <div className="input-field">
              <label>District</label>
              <div className="field-wrap">
                <Select
                  options={districtOptions}
                  onChange={handleDistrictChange}
                  value={districtOptions.find(
                    (option) => option.value === formData.district
                  )}
                  placeholder="Select District"
                  isDisabled={!formData.state}
                />
                <FormErrorMessage error={errors?.district} />
              </div>
            </div>
          )}

          {/* City */}
          {cityOptions && (
            <div className="input-field">
              <label>City</label>
              <div className="field-wrap">
                <Select
                  options={cityOptions}
                  onChange={(option: any) => {
                    setValue("city", option?.value);
                    setFormData({ ...formData, city: option?.value });
                    trigger("city");
                  }}
                  value={cityOptions.find(
                    (option) => option.value === formData.city
                  )}
                  isDisabled={!formData.district}
                  placeholder="Select City"
                />
                <FormErrorMessage error={errors?.city} />
              </div>
            </div>
          )}
          {/* Password (only for create) */}
          {!params?.id && (
            <div className="input-field">
              <label>Password</label>
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="text"
                    {...register("password", { required: true })}
                    placeholder="Enter Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                <FormErrorMessage error={errors?.password} />
              </div>
            </div>
          )}

          {/* Domain Name */}
          <div className="input-field">
            <label>Domain Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("username", { required: true })}
                  placeholder="Enter Domain name"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.username} />
            </div>
          </div>

          <div className="input-field">
            <label>C Name</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  {...register("cname", { required: false })}
                  placeholder="Enter Your C Name"
                  value={formData.cname}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors?.cname} />
            </div>
          </div>

          {/* Structure Type Radio Buttons */}
          <div className="radio-field">
            <label>Allow Organization to Add Structure Type</label>
            <div className="field-wrap">
              <div className="field">
                <label htmlFor="yes">Yes</label>
                <input
                  type="radio"
                  id="yes"
                  value={1}
                  checked={formData.add_structure_type == 1}
                  {...register("add_structure_type", { required: true })}
                  onChange={onRadioChange}
                />
              </div>
              <div className="field">
                <label htmlFor="no">No</label>
                <input
                  type="radio"
                  id="no"
                  value={0}
                  checked={formData.add_structure_type == 0}
                  {...register("add_structure_type", { required: true })}
                  onChange={onRadioChange}
                />
              </div>
            </div>
            <FormErrorMessage error={errors?.add_structure_type} />
          </div>

          <div className="input-field">
            <label htmlFor="color">
              Theme color
            </label>
            <div className="field-wrap">
              <div
                className="field color-field"
              >
                <input
                  type="color"
                  {...register("primary_color", { required: true })}
                  value={formData.primary_color}
                  onChange={handleChange}
                />
                <span>
                  {formData.primary_color}
                </span>
              </div>
              <FormErrorMessage error={errors.primary_color} />
            </div>
          </div>
        </div>

        <div className="image-fields">
          <div className="organiztion-admin-top-images">
            <div className="image-field ">
              <label className="image">
                {formData?.logo ? (
                  <img src={(formData as any).logo} />
                ) : (
                  <div>
                    <p className="placeholder-heading">Upload Logo</p>
                    Dimensions must be of 300*300
                  </div>
                )}
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) =>
                    handleUpload(e, "logo" as keyof typeof formData)
                  }
                />
              </label>
            </div>
            <div>
            <label htmlFor="org-logo">Organization Logo</label>
            
            </div>
            <FormErrorMessage
              error={errors?.["logo" as keyof OrganizationDTO]}
            />
          </div>
          <div className="organiztion-admin-top-images">
            <div className="image-field light">
              <label className="side-bar-image">
                {formData?.sidebar_dark_logo ? (
                  <img src={(formData as any).sidebar_dark_logo} />
                ) : (
                  <div>
                    <p className="placeholder-heading">Upload Logo</p>
                    Dimensions must be of 300*300
                  </div>
                )}
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) =>
                    handleUpload(
                      e,
                      "sidebar_dark_logo" as keyof typeof formData
                    )
                  }
                />
              </label>
            </div>
            <div>
            <label htmlFor="sidebar_dark_logo">Side Bar Dark Logo</label>
            </div>
            <FormErrorMessage
              error={errors?.["sidebar_dark_logo" as keyof OrganizationDTO]}
            />
          </div>
          <div className="organiztion-admin-top-images">
            <div className="image-field">
              <label className="side-bar-image">
                {formData?.sidebar_light_logo ? (
                  <img src={(formData as any).sidebar_light_logo} />
                ) : (
                  <div>
                    <p className="placeholder-heading">Upload Logo</p>
                    Dimensions must be of 300*300
                  </div>
                )}
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) =>
                    handleUpload(
                      e,
                      "sidebar_light_logo" as keyof typeof formData
                    )
                  }
                />
              </label>
            </div>
            <div>
            <label htmlFor="org-logo">Side Bar Light Logo</label>
            </div>
            <FormErrorMessage
              error={errors?.["sidebar_light_logo" as keyof OrganizationDTO]}
            />
          </div>
          <div className="organiztion-admin-top-images">
            <div className="image-field light">
              <label className="image">
                {formData?.dark_logo ? (
                  <img src={(formData as any).dark_logo} />
                ) : (
                  <div>
                    <p className="placeholder-heading">Upload Logo</p>
                    Dimensions must be of 300*300
                  </div>
                )}
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) =>
                    handleUpload(e, "dark_logo" as keyof typeof formData)
                  }
                />
              </label>
            </div>
            <div>
            <label htmlFor="dark_logo">Dark Logo</label>
            </div>
            <FormErrorMessage
              error={errors?.["dark_logo" as keyof OrganizationDTO]}
            />
          </div>
          <div className="organiztion-admin-top-images">
            <div className="image-field light">
              <label className="image">
                {formData?.fav_icon ? (
                  <img src={(formData as any).fav_icon} />
                ) : (
                  <div>
                    <p className="placeholder-heading">Upload Icon</p>
                    Dimensions must be of 300*300
                  </div>
                )}
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) =>
                    handleUpload(e, "fav_icon" as keyof typeof formData)
                  }
                />
              </label>
            </div>
            <div>
            <label htmlFor="fav_icon">Favicon</label>
            </div>
            <FormErrorMessage
              error={errors?.["fav_icon" as keyof OrganizationDTO]}
            />
          </div>
        </div>
        <div className="bottom-upload">
          <div className="organiztion-upload-images cover">
            <div className="image-field light">
              <label className="image">
                {formData?.cover_image ? (
                  <img src={(formData as any).cover_image} />
                ) : (
                  <div>
                    <p className="placeholder-heading">Upload Image</p>
                    Dimensions must be of 300*300
                  </div>
                )}
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) =>
                    handleUpload(e, "cover_image" as keyof typeof formData)
                  }
                />
              </label>
            </div>
            <div>
            <label htmlFor="org-logo">Cover Image</label>
            </div>
            <FormErrorMessage
              error={errors?.["cover_image" as keyof OrganizationDTO]}
            />
          </div>
          <div className="organiztion-upload-images">
            <div className="image-field light">
              <label className="login-image">
                {formData?.login_page_image ? (
                  <img src={(formData as any).login_page_image} />
                ) : (
                  <div>
                    <p className="placeholder-heading">Upload Image</p>
                    Dimensions must be of 300*300
                  </div>
                )}
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) =>
                    handleUpload(e, "login_page_image" as keyof typeof formData)
                  }
                />
              </label>
            </div>
            <div>
            <label htmlFor="login_page_image">Login Page Image</label>
            </div>
            <FormErrorMessage
              error={errors?.["login_page_image" as keyof OrganizationDTO]}
            />
          </div>
        </div>

        <div className="detail-fields">
          {/* Mission */}
          <div className="editor-field">
            <label>Mission</label>
            <div className="field-wrap">
              <div className="field">
                <Editor
                  value={formData.mission}
                  onChange={(name: string, value: string) =>
                    handleChange({ target: { name, value } })
                  }
                  name="mission"
                />
                <input
                  type="text"
                  className="d-none"
                  {...register("mission", { required: false })}
                />
              </div>
            </div>
          </div>

          {/* Vision */}
          <div className="editor-field">
            <label>Vision</label>
            <div className="field-wrap">
              <div className="field">
                <Editor
                  value={formData.vision}
                  onChange={(name: string, value: string) =>
                    handleChange({ target: { name, value } })
                  }
                  name="vision"
                />
                <input
                  type="text"
                  className="d-none"
                  {...register("vision", { required: false })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="action-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            <button className="lg-rounded-btn" type="submit">
              {params?.id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </Form>
    </CreateOrgMain>
  );
};

export default CreateOrganization;
