import { FC, useState } from "react";
import {
    ChangePasswordMain,
    ChangePasswordContainer,
    ChangePasswordWrapper,
} from "./style";

import { CloseMediumSvg, EyeHidingSvg, EyeSvg } from "assets/images/common/svgs";
import { ChangePasswordDTO } from "utils/helpers/models/e-portal/change-passwords.dto";
import { useForm } from "react-hook-form";
import useEportal from "containers/private/e-portal/useHooks";

interface ChangePasswordProps {
    setOpen: (open: boolean) => void;
}

const ChangePasswordModal: FC<ChangePasswordProps> = ({ setOpen }) => {

    const { changePasswordByUser } = useEportal();

    const [showPassword, setShowPassword] = useState({
        oldpassword: false,
        password: false,
        confirmPassword: false,
    });

    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        watch,
        formState: { errors },
    } = useForm<ChangePasswordDTO>({
        mode: "onChange",
    });

    const password = watch("new_password");

    const handleChange = (event: any) => {
        const { value, name } = event.target;
        setValue(name, value);
        trigger([name]);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const onSubmit = (data: ChangePasswordDTO) => {
        changePasswordByUser(data);
    };

    return (
        <ChangePasswordMain>
            <ChangePasswordContainer>
                <ChangePasswordWrapper className="p-custom-scrollbar-8">
                    <div className="header">
                        <div className="empty"></div>
                        <div className="heading">
                            <span>Change Password</span>
                        </div>
                        <div className="close-icon cp" onClick={handleCloseModal}>
                            <CloseMediumSvg className="icon" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* Old Password Field */}
                        <div className="input-field">
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type={showPassword.oldpassword ? "text" : "password"}
                                        autoComplete="current_password"
                                        placeholder="Current Password"
                                        {...register("current_password", { required: "Old Password is required" })}
                                        onChange={handleChange}
                                    />
                                    <span
                                        className="field-icon"
                                        onClick={() =>
                                            setShowPassword({
                                                ...showPassword,
                                                oldpassword: !showPassword.oldpassword,
                                            })
                                        }
                                    >
                                        {showPassword.oldpassword ? (
                                            <EyeSvg className="password-icon" />
                                        ) : (
                                            <EyeHidingSvg className="password-icon" />
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* New Password Field */}
                        <div className="input-field">
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type={showPassword.password ? "text" : "password"}
                                        placeholder="New Password"
                                        autoComplete="new-password"
                                        {...register("new_password", {
                                            required: "New Password is required",
                                            minLength: {
                                                value: 8,
                                                message: "Password must be at least 8 characters",
                                            },
                                            validate: {
                                                hasUpperCase: (value) =>
                                                    /[A-Z]/.test(value) || "Password must contain at least one uppercase letter",
                                                hasLowerCase: (value) =>
                                                    /[a-z]/.test(value) || "Password must contain at least one lowercase letter",
                                                hasNumber: (value) =>
                                                    /[0-9]/.test(value) || "Password must contain at least one number",
                                            },
                                        })}
                                        onChange={handleChange}
                                    />
                                    <span
                                        className="field-icon"
                                        onClick={() =>
                                            setShowPassword({
                                                ...showPassword,
                                                password: !showPassword.password,
                                            })
                                        }
                                    >
                                        {showPassword.password ? (
                                            <EyeSvg className="password-icon" />
                                        ) : (
                                            <EyeHidingSvg className="password-icon" />
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="input-field">
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type={showPassword.confirmPassword ? "text" : "password"}
                                        autoComplete="new-password"
                                        {...register("new_password_confirmation", {
                                            required: "Confirm Password is required",
                                            validate: (value) =>
                                                value === password || "Passwords do not match",
                                        })}
                                        placeholder="Confirm New Password"
                                        onChange={handleChange}
                                    />
                                    <span
                                        className="field-icon"
                                        onClick={() =>
                                            setShowPassword({
                                                ...showPassword,
                                                confirmPassword: !showPassword.confirmPassword,
                                            })
                                        }
                                    >
                                        {showPassword.confirmPassword ? (
                                            <EyeSvg className="password-icon" />
                                        ) : (
                                            <EyeHidingSvg className="password-icon" />
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* Error Messages */}
                        {(errors?.current_password || errors?.new_password_confirmation || errors?.new_password) && (
                            <div className="error-main">
                                <div className="error-box">
                                    {errors?.current_password && <p>{errors.current_password.message}</p>}
                                    {errors?.new_password_confirmation && <p>{errors.new_password_confirmation.message}</p>}
                                    {errors?.new_password && <p>{errors.new_password.message}</p>}
                                </div>
                            </div>
                        )}

                        {/* Action Buttons */}
                        <div className="action-buttons">
                            <div className="buttons">
                                <button
                                    className="lg-rounded-btn gray"
                                    type="button"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                                <button className="lg-rounded-btn" type="submit">
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </form>
                </ChangePasswordWrapper>
            </ChangePasswordContainer>
        </ChangePasswordMain>
    );
};

export default ChangePasswordModal;
