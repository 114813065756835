
import EmailTemplateCreate from "containers/private/email-template/create";
import EmailTemplateListing from "containers/private/email-template/listing";

import useUtils from "hooks/useUtils";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const EmailTemplateRoutes: FC<any> = () => {
  const { getPathToSetRoute } = useUtils();
  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.emailTemplateCreate)}
        Component={EmailTemplateCreate}
      />
      <Route
        path={getPathToSetRoute(siteRoutes.emailTemplateListing)}
        Component={EmailTemplateListing}
      />
    </Routes>
  );
};

export default EmailTemplateRoutes;
