import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useEffect, useState } from "react";
import {
  CreateFeeTypeSection,
  CreateFeeTypeMain,
  CreateFeeTypeTop,
} from "./style";
import {
  CreateAdmissionQuotaDTO,
  DynamicField,
} from "utils/helpers/models/admissions/create-quota.dto";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useUtils from "hooks/useUtils";
import useAdmissions from "containers/private/admissions/useHooks";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useOrganization from "containers/private/organization/useHooks";
import { FeeTypesDTO } from "utils/helpers/models/academics/academic-session.dto";
import useAcademics from "../../useHooks";

export const CreateFeeType: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    { title: "Fee Types /", path: siteRoutes.academicFeeTypeListing },
    { title: "Add Fee Types", path: siteRoutes.createAcademicFeeType },
  ];
  const [categories, setCategories] = useState<any[]>([]);
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FeeTypesDTO>();
  const [formData, setFormData] = useState<FeeTypesDTO>(
    new FeeTypesDTO()
  );
  const {createFeeTypes ,updateFeeTypes,getFeeTypesById} = useAcademics()
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const onSubmit = () => {
    if (params?.id) {
      updateFeeTypes(params?.id, formData);
    } else {
      createFeeTypes(formData)
    }
   
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };


  const {
 
    getStructureTypesForProgram,
  } = useOrganization();

  useEffect(() => {
    if (params?.id) {
      getFeeTypesById(params?.id, formData, setValue, setFormData);
    }
    getStructureTypesForProgram( setCategories);
  }, [])
console.log(formData);


  return (
    <CreateFeeTypeMain>
      <CreateFeeTypeTop>
        <div className="left">
          <span className="page-heading"> Add Fee Type</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreateFeeTypeTop>

      <CreateFeeTypeSection className="content-radius-shadow" onSubmit={handleSubmit(onSubmit)}>
        <div className="common-fields">
          <div className="input-field">
            <label>Fee Type Title</label>
            <div className="field-wrap">
              <div className="field">
                <input type="text" id="" placeholder="Enter Title"
                  {...register("title", { required: true })}
                  onChange={handleChange}
                  value={formData?.title} />
              </div>
              <FormErrorMessage error={errors.is_active} /> 
            </div>
          </div>
          <div className="input-field">
            <label>Status</label>
            <div className="field-wrap">
              <div className="field">
                <select   {...register("is_active", { required: true })}
                  onChange={handleChange}
                  value={formData?.is_active}>
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">De-Active</option>
                </select>
              </div>
              <FormErrorMessage error={errors.is_active} /> 
            </div>
          </div>
          <div className="input-field">
            <label>Select Department</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("department_id", { required: true })}
                  onChange={handleChange}
                  value={formData?.department_id}
                >
                  <option value={""}>Select Department</option>
                  {categories.map((item: any, index: number) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.department_id} /> 
            </div>
          </div>
        </div>
        <div className="submit-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button">
              Reset
            </button>

            <button className="lg-rounded-btn black">Save & Add more</button>

            <button className="lg-rounded-btn">Save & Exit</button>
          </div>
        </div>
      </CreateFeeTypeSection>
    </CreateFeeTypeMain>
  );
};

export default CreateFeeType;
