import { FC, useEffect, useState } from "react";
import { AllocateCourseRoomsMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useAcademics from "containers/private/academics/useHooks";
import { AddCourseRoomDTO } from "utils/helpers/models/academics/academic-session.dto";
import { warningToaster } from "utils/helpers/common/alert-service";
import { UpdatePasswordDTO } from "utils/helpers/models/organization/add-board.dto";
import useOrganization from "containers/private/organization/useHooks";
interface AllocateCourseRoomsProps {
  setOpen: Function;
  admin_Id: any;
}

const UpdatePassword: FC<AllocateCourseRoomsProps> = ({
  setOpen,
  admin_Id,
}) => {
  const { UpdateOrgAdminPassword } = useOrganization();
  const [formData, setFormData] = useState<UpdatePasswordDTO>({
    ...new UpdatePasswordDTO(),
    admin_Id,
  });

  const onSubmit = () => {
    if (!formData.password) {
      warningToaster("Please select the room");
    } else {
      UpdateOrgAdminPassword(admin_Id, formData, setOpen);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  // console.log(admin_Id);

  return (
    <AllocateCourseRoomsMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Update Password</span>
            </div>
            <div className="close-icon cp" onClick={() => setOpen(false)}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="common-fields">
              <div className="input-field">
                <label>Enter Password</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      placeholder="Enter Password"
                      value={formData.password}
                      name="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
                <button
                  className="lg-rounded-btn"
                  type="button"
                  onClick={onSubmit}
                >
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AllocateCourseRoomsMain>
  );
};

export default UpdatePassword;
