import { FC, useState } from "react"
import { SettingsMain, ZoomBtn, ZoomSettings } from "./style";
import useUtils from "hooks/useUtils";
import useStore from "hooks/useStore";
interface SettingsProps { }

const SettingsPopup: FC<SettingsProps> = () => {

    const { setSiteZoomLevel, getSiteZoomLevel } = useStore();
    const [zoomLevel, setZoomLevel] = useState<number>(getSiteZoomLevel());
    const { setCSSVariable } = useUtils();

    const enhanceZoomLevel = () => {
        if(zoomLevel < 100 ){
            const zoomLevelToSet = zoomLevel+10;
            setZoomLevel(zoomLevelToSet);
            setSiteZoomLevel(zoomLevelToSet);
        }
    }

    const reduceZoomLevel = () => {
        if(zoomLevel>80){
            const zoomLevelToSet = zoomLevel-10;
            setZoomLevel(zoomLevelToSet);
            setSiteZoomLevel(zoomLevelToSet);
        }
    }

    const resetZoomLevel = () => {
        setZoomLevel(100);
        setCSSVariable('--zoom-level', '80%');
    }

    return (
        <SettingsMain>
            <ZoomSettings>
                <div className="particular-setting">
                    <div className="heading">
                        <span>Zoom level</span>
                    </div>
                    <div className="btns">
                        <ZoomBtn onClick={enhanceZoomLevel} zoomOverflowed={zoomLevel === 100}>
                            <span className="operator">+</span>
                        </ZoomBtn>
                        <ZoomBtn zoomOverflowed={false} onClick={resetZoomLevel}>
                            <span>Reset</span>
                        </ZoomBtn>
                        <ZoomBtn onClick={reduceZoomLevel} zoomOverflowed={zoomLevel === 80}>
                            <span className="operator">-</span>
                        </ZoomBtn>
                    </div>
                </div>
                <div className="bottom-border"></div>
            </ZoomSettings>
        </SettingsMain>
    )
}

export default SettingsPopup;