import { FC, Fragment, useEffect, useState } from "react";
import {
  StudentInfo,
  EportalViewProfileMain,
  EportalViewProfileTopSection,
  InfoField,
  InfoSection,
  BasicInfo,
  EducationalInfo,
} from "./style";
import { EducationalInfoSvg, EmployeeInfoSvg } from "assets/images/common/svgs";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useEportal from "../../useHooks";
import dummyImage from "../../../../../assets/images/common/others/download.png";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";

interface EportalViewProfileProps {}

const ViewEPStudentProfile: FC<EportalViewProfileProps> = () => {
  const { hasAccess } = useStore();
  const [formData, setFormData] = useState<any>({});

  const columns: string[] = [
    "Sr.",
    "Certificate/Degree",
    "Institute",
    "Year",
    "Rollno",
    "Certificate No.",
    "Total Marks / CGPA",
    "Obtained Marks / GPA",
  ];

  const { getProfile, getQualificationsByUser } = useEportal();

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [filters, setFilters] = useState<any>({
    categories_types_id: "",
    parent_id: "",
    city_id: "",
    search: "",
  });
  const [tableData, setTableData] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllQualificationByUser(page + 1);
  };

  const getAllQualificationByUser = (page: number = 1) => {
    const filterOptions = { ...filters };

    for (let key in filterOptions) {
      if (!filters[key]) {
        delete filterOptions[key];
      }
    }
    const queryParams = {
      per_page: pagination.per_page,
      page,
      ...filterOptions,
    };
    getQualificationsByUser(setTableData, queryParams, setPagination);
  };
  const navigate = useNavigate();

  useEffect(() => {
    getProfile(setFormData);
    getAllQualificationByUser();
  }, []);
  console.log(formData);

  return (
    <EportalViewProfileMain>
      <EportalViewProfileTopSection>
        <span className="page-heading">View Profile</span>
     
      </EportalViewProfileTopSection>

      <InfoSection>
        <StudentInfo>
        

          <div className="info-container employee-info">
          <div className="profile-pic-section ">
            <div className="content-section">
              <div className="profile-picture">
                <img
                  src={
                    formData.profile_image ? formData.profile_image : dummyImage
                  }
                  alt=""
                />
              </div>
              <div className="user-name">{formData.name}</div>
           
            </div>
          </div>

            <div className="fields-section">
              <InfoField gray={false} studentInfo>
                <span className="title">First Name</span>
                <span className="info">{formData?.first_name}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Last Name</span>
                <span className="info">{formData?.last_name}</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
                <span className="title">Father Name</span>
                <span className="info">{formData?.father_name}</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
                <span className="title">Gender</span>
                <span className="info">{formData?.gender}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Email</span>
                <span className="info">{formData?.email}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Phone No</span>
                <span className="info"> {formData.phone_no ? formData.phone_no : "-"}</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
              <span className="title">CNIC/B.Form</span>
              <span className="info">
                {formData.cnic}
              </span>
            </InfoField>
            <InfoField gray={true} studentInfo>
              <span className="title">Address</span>
              <span className="info">
                {formData?.address}
              </span>
            </InfoField>
            <InfoField gray={false} studentInfo>
                <span className="title">Permanent Mailing Address</span>
                <span className="info">{formData?.m_address}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Permanent Address</span>
                <span className="info">{formData?.p_address}</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
                <span className="title">Date Of Birth</span>
                <span className="info">{formData?.date_of_birth}</span>
              </InfoField>
              <InfoField gray={true} studentInfo>
                <span className="title">Domicile City</span>
                <span className="info">{formData?.domicile}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Current Mailing Address</span>
                <span className="info">{formData?.m_city}</span>
              </InfoField>
              <InfoField gray={false} studentInfo>
                <span className="title">Current Address (City & Tehsil)</span>
                <span className="info"> {formData.city?.title}</span>
              </InfoField>
            
             
            </div>
          </div>
        </StudentInfo>
   
        
      </InfoSection>
    </EportalViewProfileMain>
  );
};

export default ViewEPStudentProfile;
