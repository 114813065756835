import { FC } from "react";
import { BreadCrumbLink, BreadCrumbMain } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "hooks/useStore";
import { ROUTES_PERMISSIONS } from "utils/constants/pages-routes";

interface BreadcrumbProps {
    links: BreadcrumbLink[]
}

export interface BreadcrumbLink {
    title: string;
    path: string;
}

const Breadcrumb: FC<BreadcrumbProps> = ({ links }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { hasAccess } = useStore();

    const handleNavigation = (route: string) => {
        // console.log(route.split('?'))
        // console.log(route.split('?')[0], 'splitted path');
        const permission = ROUTES_PERMISSIONS.find(permission => permission.path === route.split('?')[0])?.permission;
        if (hasAccess(permission as string)) {
            navigate(route);
        }
    };
    return (
        <BreadCrumbMain>
            {links.map((link, index) => {
                return (
                    <BreadCrumbLink
                        active={pathname === link.path.split('?')[0]}
                        key={index}
                        onClick={() => handleNavigation(link.path)}
                    >
                        {link.title}
                    </BreadCrumbLink>
                );
            })}
        </BreadCrumbMain>
    );
};

export default Breadcrumb;
