import { FC, useEffect, useState } from "react";
import { Main, STCreateContent, STCreateTop } from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import { useForm } from "react-hook-form";
import { AddEmailTemplateDTO } from "utils/helpers/models/email-templates/email-templates.dto";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useUtils from "hooks/useUtils";
import useOrganization from "containers/private/organization/useHooks";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import Editor from "components/particles/forms/editor";
import useEmailTemplate from "../UseHooks";
interface CreateStructureTypeProps { }
// create email
const EmailTemplateCreate: FC<CreateStructureTypeProps> = () => {

  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const breadcrumbLinks = [
    {
      title: "Email Template /",
      path: siteRoutes.emailTemplateListing,
    },
    {
      title: `${params?.id ? "Update" : "Create"} Email Template `,
      path: siteRoutes.emailTemplateCreate,
    },
  ];
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
  } = useForm<AddEmailTemplateDTO>({
    defaultValues: new AddEmailTemplateDTO(),
  });

  const {
    createEmailTemplate,
    getEmailTemplateKeys,
    getEmailTemplateListById,
    updateEmailTemplateListById,
  } = useEmailTemplate();

  let [formData, setFormData] = useState<AddEmailTemplateDTO>(new AddEmailTemplateDTO());
  const [tempKey, setTempKey] = useState<any[]>([]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    formData[name as keyof AddEmailTemplateDTO] = value as never;
    setFormData({ ...formData });
    trigger(name);
  };
  const onSubmit = (
    body: AddEmailTemplateDTO,
    addMore: boolean = false,
    resetForm: Function
  ) => {
    if (params?.id) {
      updateEmailTemplateListById(params?.id, addMore, resetForm, body);
    } else {
      createEmailTemplate(body, addMore, resetForm);
    }
  };

  const resetForm = () => {
    for (let key in getValues()) {
      setValue(key as keyof AddEmailTemplateDTO, "");
    }
    formData = new AddEmailTemplateDTO();
    setFormData({ ...formData });
    console.log("formdata reset", formData);
  };

  useEffect(() => {
    if (params?.id) {
      getEmailTemplateListById(params?.id, formData, setFormData, setValue);
    }
    getEmailTemplateKeys(setTempKey);
    register("body", { required: true })
  }, []);

  return (
    <Main>
      <STCreateTop>
        <div className="heading">
          <span className="page-heading">
            {params?.id ? "Update" : "Add"} Email Template
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </STCreateTop>
      <STCreateContent className="content-radius-shadow">
        <div className="detail">
          <div className="page-sub-heading">
            <span>{params?.id ? "Update" : "Add"} Email Template</span>
          </div>
        </div>

        <div className="form-section">
          <form>
            <div className="fields-section">

              <div className="input-field">
                <label>Template Name</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      {...register("template_name", { required: true })}
                      value={formData?.template_name}
                      onChange={handleChange}
                      placeholder="Template Name"
                    />
                  </div>
                  <FormErrorMessage error={errors.template_name} />
                </div>
              </div>
              <div className="input-field">
                <label>Subject</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="text"
                      {...register("subject", { required: true })}
                      value={formData?.subject}
                      onChange={handleChange}
                      placeholder="Subject"
                    />
                  </div>
                  <FormErrorMessage error={errors.subject} />
                </div>
              </div>

              <div className="input-field">
                <label>Key</label>
                <div className="field-wrap">
                  <div className="field">
                    <select
                      {...register("key", { required: true })}
                      value={formData?.key}
                      onChange={handleChange}
                    >
                      <option value="">user_registration / forget_password</option>
                      {tempKey?.map((item: any, index: number) => (
                        <option value={item.key_label} key={index}>
                          {item.key_label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <FormErrorMessage error={errors?.key} />
                </div>
              </div>


            </div>

            <div className="fields-section">
              <div className="editor-field">
                <label>Email Body</label>
                <div className="field-wrap">
                  <div className="field">
                    <Editor
                      value={formData?.body}
                      onChange={(name: string, value: string) =>
                        handleChange({ target: { name, value } })
                      }
                      name="body"
                    />
                  </div>
                  <FormErrorMessage error={errors.body} />
                </div>
              </div>

            </div>


            <div className="action-buttons">
              <div className="buttons">
                <button
                  type="button"
                  className="lg-rounded-btn gray"
                  onClick={resetForm}
                >
                  Reset
                </button>
                {/* {!params?.id && (
                  <button
                    type="button"
                    className="lg-rounded-btn green"
                    onClick={handleSubmit((data: AddEmailTemplateDTO) =>
                      onSubmit(data, true, resetForm)
                    )}
                  >
                    Save & Add More
                  </button>
                )} */}
                <button
                  className="lg-rounded-btn"
                  onClick={handleSubmit((data: AddEmailTemplateDTO) =>
                    onSubmit(data, false, resetForm)
                  )}
                >
                  {params?.id ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </STCreateContent>
    </Main>
  );
};

export default EmailTemplateCreate;
