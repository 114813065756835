
import { ChangeEvent, FC, useEffect, useState } from "react";
import { CreateRoomSection, CreateRoomMain, CreateRoomTop } from "./style";
import { SmallUploadSvg } from "assets/images/common/svgs";
import { SubmitHandler, useForm } from "react-hook-form";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { AddRoomDto } from "utils/helpers/models/organization/add-rooms.dto";

import FormErrorMessage from "components/particles/forms/form-error-message";
import Breadcrumb from "components/particles/breadcrumb";
import useUtils from "hooks/useUtils"
import squareAvatar from "assets/images/common/others/avatar-square-image.png";
import useOrganization from "../../useHooks";;

const CreateRooms: FC = () => {

    const breadcrumbLinks = [
        { title: "Infrastructure & Asset Management/", path: "" },
        { title: "Buildings /", path: siteRoutes.buildingListing },
        { title: "Rooms/", path: siteRoutes.roomsListing },
        { title: "Add Rooms", path: siteRoutes.createRooms },
    ];

    const {
        createRoomForBuilding,
        getBuildingRoomForupdate,
        getBuildingListForAddRoom,
        updateRoomForBuildingById,
    } = useOrganization();
    const { getQueryParams } = useUtils();
    const params = getQueryParams();

    let [formData, setFormData] = useState<AddRoomDto>(new AddRoomDto());
    const [buildings, setGetBuilding] = useState<any[]>([]);

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        trigger,
        getValues,
    } = useForm<AddRoomDto>();


    const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {

        const { files, name } = event.target as any;
        const file = files![0];
        if (file) {
            setValue(name, file);
            trigger([name]);
            setFormData({ ...formData, room_image: URL.createObjectURL(file) });
        }

    };

    const resetForm = () => {
        for (let key in getValues()) {
            setValue(key as keyof AddRoomDto, "");
        }
        formData = new AddRoomDto();
        setFormData({ ...formData });
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setValue(name, value);
        (formData as any)[name] = value;
        setFormData({ ...formData });
        trigger([name]);
    };

    const onSubmit = (data: AddRoomDto, addMore: boolean = false) => {

        const formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key as keyof AddRoomDto]);
        }
        if (params?.id) {
            updateRoomForBuildingById(params?.id, formData);
        } else {
            createRoomForBuilding(
                formData,
                addMore,
                resetForm,
            );
        }
    };

    useEffect(() => {
        if (params.id) {
            getBuildingRoomForupdate(params?.id, setFormData);
        }
    }, [params?.id]);

    useEffect(() => {
        getBuildingListForAddRoom(setGetBuilding);
    }, [])

    return (

        <CreateRoomMain>
            <CreateRoomTop>
                <div className="left">
                    <span className="page-heading">Add Rooms</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
            </CreateRoomTop>
            <CreateRoomSection className="p-custom-scrollbar-8">

                <form>
                    <div className="common-fields">
                        <div className="upload-field">
                            <label>Room Image</label>
                            <div className="field-wrapper">
                                <label className="file-name-section" htmlFor="room-image">
                                    <div className="inner-content">
                                        <div className="upload-text">
                                            <div className="upload-icon">
                                                <SmallUploadSvg className="icon" />
                                            </div>
                                            <span className="text">
                                                Upload Room Image
                                            </span>
                                        </div>
                                        <div className="upload-restrictions">
                                            select jpg/png image with maximum size of 900 KB
                                        </div>
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    className="d-none"
                                    {...register("room_image", { required: false })}
                                    id="room-image"
                                    onChange={handleUpload}
                                />
                                <div className="uploaded-image cnic">
                                    <img src={formData?.room_image ? formData?.room_image : squareAvatar} alt="" />
                                </div>
                            </div>
                            <FormErrorMessage error={errors?.room_image} />
                        </div>
                    </div>
                    <div className="common-fields">
                        <div className="input-field">
                            <label>Building</label>
                            <div className="field-wrap">

                                <div className="field">
                                    <select
                                        {...register("building_id", { required: true })}
                                        value={formData?.building_id || ""}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Building</option>
                                        {
                                            buildings && buildings.map((item: any, index: number) => (
                                                <option key={item.id} value={item.id}>{item.title}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <FormErrorMessage error={errors.building_id} />
                            </div>
                        </div>
                        <div className="input-field">
                            <label>Room Title</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type="text"
                                        {...register("room_name", { required: true })}
                                        value={formData?.room_name}
                                        onChange={handleChange}
                                        placeholder="Add Room Title"
                                    />
                                </div>
                                <FormErrorMessage error={errors.room_name} />
                            </div>
                        </div>
                        <div className="input-field">
                            <label>Room No</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type="text"
                                        {...register("room_number", { required: true })}
                                        value={formData?.room_number}
                                        onChange={handleChange}
                                        placeholder="Add Room No"
                                    />
                                </div>
                                <FormErrorMessage error={errors.room_number} />
                            </div>
                        </div>
                    </div>

                    <div className="common-fields">
                        <div className="input-field">
                            <label>Room Type</label>
                            <div className="field-wrap">
                                <div className="field">

                                    <select
                                        {...register("room_type_id", { required: true })}
                                        value={formData?.room_type_id || ""}
                                        onChange={handleChange}
                                    >
                                        <option>Select Room Type</option>
                                        {
                                            buildings && buildings.map((item: any, index: number) => (
                                                <option key={item.id} value={item.id}>{index + 1} Room Type</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <FormErrorMessage error={errors.room_type_id} />
                            </div>
                        </div>
                        <div className="input-field">
                            <label>Exam Seating Capacity</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type="number"
                                        {...register("exam_seating_capacity", { required: true })}
                                        value={formData?.exam_seating_capacity}
                                        onChange={handleChange}
                                        placeholder="Seating Capacity"
                                    />
                                </div>
                                <FormErrorMessage error={errors.exam_seating_capacity} />
                            </div>
                        </div>
                        <div className="input-field">
                            <label>Seating Capacity</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type="number"
                                        {...register("seating_capacity", { required: true })}
                                        value={formData?.seating_capacity}
                                        onChange={handleChange}
                                        placeholder="Seating Capacity"
                                    />
                                </div>
                                <FormErrorMessage error={errors.seating_capacity} />
                            </div>
                        </div>
                    </div>
                    <div className="common-fields">
                        <div className="input-field">
                            <label>Room Length</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type="number"
                                        {...register("length", { required: true })}
                                        value={formData?.length}
                                        onChange={handleChange}
                                        placeholder="Room Length"
                                    />
                                </div>
                                <FormErrorMessage error={errors.length} />
                            </div>
                        </div>
                        <div className="input-field">
                            <label>Room Width</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type="number"
                                        {...register("width", { required: true })}
                                        value={formData?.width}
                                        onChange={handleChange}
                                        placeholder="Room Width"
                                    />
                                </div>
                                <FormErrorMessage error={errors.width} />
                            </div>
                        </div>

                        <div className="input-field">
                            <label>Select Floor</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <select
                                        {...register("floor", { required: true })}
                                        value={formData?.floor}
                                        onChange={handleChange}
                                    >
                                        <option disabled>Select Floor</option>
                                        {
                                            [1, 2, 3, 4].map((index: number) => (
                                                <option value={index}>{index} Floor</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <FormErrorMessage error={errors.floor} />
                            </div>
                        </div>
                    </div>

                    <div className="common-fields">
                        <div className="input-field">
                            <label>Is Available</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <select
                                        {...register("is_available", { required: true })}
                                        value={formData?.is_available}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Floor</option>
                                        <option value={1}>Available</option>
                                        <option value={0}>Not Available</option>
                                    </select>
                                </div>
                                <FormErrorMessage error={errors.is_available} />
                            </div>
                        </div>
                        <div className="input-field">
                            <label>Select Is Lab</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <select
                                        {...register("is_lab", { required: true })}
                                        value={formData?.is_lab}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Is Lab</option>
                                        <option value={1}>Lab</option>
                                        <option value={0}>Not Lab</option>
                                    </select>
                                </div>
                                <FormErrorMessage error={errors.is_lab} />
                            </div>
                        </div>

                        {/* <div className="radio-field">
                            <label htmlFor="no">Is Available</label>
                            <div className="field-wrap">
                                <div className="field">
                                    <input
                                        type="radio"
                                        id="isavailable"
                                        {...register('is_available', { required: true })}
                                        value={1}
                                        checked={formData.is_available == 1}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="isavailable">Yes</label>
                                </div>
                                <div className="field">
                                    <input
                                        type="radio"
                                        id="notavailable"
                                        {...register('is_available', { required: true })}
                                        value={0}
                                        checked={formData.is_available == 0}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="notavailable">No</label>
                                </div>
                            </div>
                        </div> */}

                    </div>


                    <div className="action-buttons">
                        <div className="buttons">

                            <button className="lg-rounded-btn gray" onClick={resetForm} type="reset">
                                Reset
                            </button>
                            {!params?.id && (
                                <button
                                    className="lg-rounded-btn spring"
                                    onClick={handleSubmit((formData: AddRoomDto) =>
                                        onSubmit(formData, true)
                                    )}

                                >
                                    Save & Add more
                                </button>
                            )}
                            <button
                                className="lg-rounded-btn"
                                onClick={handleSubmit((formData: AddRoomDto) =>
                                    onSubmit(formData)
                                )}
                            >
                                {params?.id ? "Update" : "Save"} & Exit
                            </button>

                        </div>
                    </div>
                </form>
            </CreateRoomSection>

        </CreateRoomMain >
    );
};

export default CreateRooms;

