import { VoucherTypeDTO } from "../finance/voucher-type.dto";

export class AddQuotaProgramLinkDTO {
  quota_id: string = "";
  program_id: number[] = [];
  quota_seats: number = 0;
  acc_voucher_id: string = "";
  acc_app_id: string = "";
  voucher_type_id: string = "";
  voucher_type!: VoucherTypeDTO;
}
