import AdmissionDashboard from "containers/private/desicion-support-system/admission-dashboards";
import SystemLogs from "containers/private/system-administration/logs";

import useUtils from "hooks/useUtils";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const DSSystemRoutes: FC<any> = () => {
  const { getPathToSetRoute } = useUtils();
  return (
    <Routes>
      <Route
        path={getPathToSetRoute(siteRoutes.admissionDashboard)}
        Component={AdmissionDashboard}
      />
    </Routes>
  );
};

export default DSSystemRoutes;
