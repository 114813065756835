export enum domains {
    superAdmin = 'localhost',
    mainDomain = 'main-domain',
    subDomain = 'sub-domain',
    subDeepDomain = 'eportal.org.umscloud.com'
}

export enum defaultRoutes {
    unAuthStudent = '/student/student-login',
    authStudent = '/private/student-e-portal',
    unAuthSuperAdmin = '/super-admin-login',

} 

export enum OrgStructureTypes{
    campus = 'campus',
    faculty = 'faculty',
    department = 'department'
}

export enum HEADER_UTILITIES{
    PROFILE = 'profile',
    NOTIFICATIONS = 'notifications',
    SETTINGS = 'settings',
    THEME = 'theme'

}