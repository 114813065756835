import { AddProgramDTO } from "../organization/add-program.dto";

export class PlanOfStudyDTO{
    id!: number;
    title:string = '';
    program_id:string = '';
    grade_template_id!:string;
    result_template_id!:string;
    total_semesters !: number;
    is_validated!: number;
    plan_of_study_courses_count!: number;
    students_count!: number;
    total_theory_credit_hours!: number;
    total_lab_credit_hours!: number;
    program!: AddProgramDTO;
    pos_id!: number;
    }