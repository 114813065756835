export class OrganizationDTO {
  id!: number;
  name: string = "";
  logo: any = null;
  cover_image: any = null;
  fav_icon: any = null;
  sidebar_dark_logo: any = null;
  sidebar_light_logo: any = null;
  dark_logo: any = null;
  login_page_image : any = null;
  email: string = "";
  password: string = "";
  phone: string = "";
  state: string = "";
  city: string = "";
  add_structure_type: number | string = 0;
  zip_code: number = 0;
  address1: string = "";
  website: string = "";
  username: string = "";
  district: string = "";
  vision: string = "";
  mission: string = "";
  primary_color: string = "#128B9B";
  // domain_name: string = ''
  domain: string = "";
  cname: string = "";
}
