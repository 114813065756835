import { FC, useEffect, useState } from "react";
import {
  AdminDashboarMain,
  ADashboardTop,
  ADashboradSection,
  LinkSection,
  ChartSection,
  PieChart,
  LegendWrapper,
  LegendItem,
  LegendBullet,
  LegendLabel,
  LineChart,
  QuickLinkSection,
  QuickLinks,
  MainStatCard,
  MainStatCards,
  ActivityLogs,
  SheduleSection,
  BottomSection,
  LeaveRequests,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import {
  AcadmicLInkSvg,
  AdmissionLinkSvg,
  AffiliationLinkSvg,
  AttendanceLinkSvg,
  AuthLinkSvg,
  CampusSvg,
  CrossSvg,
  DepartmentSvg,
  EventCalendarSvg,
  FacilitiesSvg,
  InitiateLinkSvg,
  NonTeachingStaffSvg,
  OricLinkSvg,
  ProfileLinkSvg,
  SalaryLinkSvg,
  StoreLinkSvg,
  StudenSvg,
  StudentCardSvg,
  SupportLinkSvg,
  TadaSvg,
  TeachingStaffSvg,
  TestingServicesLinkSvg,
  TickSvg,
  UpcommingEventSvg,
} from "assets/images/common/svgs";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import { TestingServiceSvg } from "assets/images/e-portal/svgs";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import dummyImg from "assets/images/common/others/dummy-circle-image.png";
import fakeImg from "assets/images/common/others/dummy-img.jpg";
import { CheckSvg } from "assets/images/organization/svgs";
import { useSelector } from "react-redux";
import useHttp from "hooks/useHttp";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

interface AdminDashboardProps {}

const AdminDashboard: FC<AdminDashboardProps> = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Home", path: siteRoutes.adminDashboard },
  ];
  const { user } = useSelector((state: any) => state.sharedReducer);

  const topCards = [
    {
      img: <TeachingStaffSvg />,
      bgColor: "#e7edff",
      title: "Total Teaching Staff",
      stats_value: "3,200",
      active_value: "Active 3,200",
    },
    {
      img: <NonTeachingStaffSvg />,
      bgColor: "#FFBB3826",
      title: "Total Non-Teaching Staff ",
      stats_value: "1,600",
      active_value: "Active : 705",
    },
    {
      img: <StudentCardSvg />,
      bgColor: "#DB4B4A1A",
      title: "Total Students",
      stats_value: "50,620",
      active_value: "Active : 47,000",
    },
    {
      img: <CampusSvg />,
      bgColor: "#F2E1231A",
      title: "Campuses ",
      stats_value: "5",
      active_value: "Active : 4",
    },
    {
      img: <FacilitiesSvg />,
      bgColor: "#0FCB0226",
      title: "Facilities ",
      stats_value: "23",
      active_value: "Active : 22",
    },
    {
      img: <DepartmentSvg />,
      bgColor: "#718EBF26",
      title: "Departments",
      stats_value: "100",
      active_value: "Engineering: 25",
    },
  ];
  const linkInfo = [
    { img: <AuthLinkSvg />, title: "My Authorities", bgColor: "#ffbb3826" },
    { img: <ProfileLinkSvg />, title: "Update Profile", bgColor: "#0FCB0226" },
    { img: <AttendanceLinkSvg />, title: "My Attendance", bgColor: "#EAEEF5" },
    { img: <InitiateLinkSvg />, title: "Initiate Leave", bgColor: "#FBEDED" },
    { img: <SalaryLinkSvg />, title: "My Salary", bgColor: "#128BAB26" },
    { img: <TadaSvg />, title: "My TADA’s", bgColor: "#FEFCE9" },
    { img: <SupportLinkSvg />, title: "Support Tickets", bgColor: "#4A3AFF1A" },
    { img: <StoreLinkSvg />, title: "My Store Items", bgColor: "#82BDC11A" },
  ];

  const renderLegend = () => {
    const colors = [
      "#128B9B",
      "#78DA5F",
      "#155096",
      "#CAF2E1",
      "#FF5733",
      "#FFC300",
      "#C70039",
    ];
    const labels = [
      "Admission Fee",
      "Semester Fee",
      "Testing services Fee",
      "Degree Fee",
      "Fine",
    ];

    return (
      <LegendWrapper>
        {labels.map((label, index) => (
          <LegendItem key={index}>
            <LegendBullet style={{ backgroundColor: colors[index] }} />
            <LegendLabel>{label}</LegendLabel>
          </LegendItem>
        ))}
      </LegendWrapper>
    );
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },

    cutout: "70%",
  };
  const pieChartData = {
    labels: [],
    datasets: [
      {
        label: "Test Distribution",
        data: [12, 19, 3, 5, 2, 3, 10],
        backgroundColor: [
          "#128B9B",
          "#78DA5F",
          "#155096",
          "#CAF2E1",
          "#FF5733",
          "#FFC300",
          "#C70039",
        ],
      },
    ],
  };
  const data1 = [22, 17, 8, 6, 10, 15, 12, 18, 14, 9, 13, 21, 19]; // Data for the first bar
  const data2 = [13, 9, 12, 8, 5, 4, 6, 11, 7, 13, 15, 10]; // Data for the second bar
  const data3 = [18, 15, 11, 14, 9, 7, 10, 16, 12, 8, 17, 22, 20]; // Data for the third bar
  const data4 = [11, 7, 14, 9, 6, 4, 5, 10, 8, 12, 10, 6]; // Data for the fourth bar

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const labelsChart2 = ["2020", "2021", "2022", "2023", "2024"];
  const chartDataTwo = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: data1,
        backgroundColor: "#C7EBFF",
        borderColor: "#C7EBFF",
        borderWidth: 1,
      },
      {
        label: "Expenses",
        data: data2,
        backgroundColor: "#128B9B",
        borderColor: "#128B9B",
        borderWidth: 1,
      },
    ],
  };

  const chartDataThree = {
    labels: labelsChart2,
    datasets: [
      {
        label: "Professor",
        data: data1,
        backgroundColor: "#D0E8EB",
        borderColor: "#D0E8EB",
        borderWidth: 1,
      },
      {
        label: "Associate Professor",
        data: data2,
        backgroundColor: "#E3E8F2",
        borderColor: "#E3E8F2",
        borderWidth: 1,
      },
      {
        label: "Assistant Professor",
        data: data3,
        backgroundColor: "#E5E5E5",
        borderColor: "#E5E5E5",
        borderWidth: 1,
      },
      {
        label: "Lecturer",
        data: data4,
        backgroundColor: "#D9EED9",
        borderColor: "#D9EED9",
        borderWidth: 1,
      },
      {
        label: "Teaching assistant",
        data: data4,
        backgroundColor: "#FFF1D7",
        borderColor: "#FFF1D7",
        borderWidth: 1,
      },
    ],
  };
  const quickLinks = [
    {
      img: <AdmissionLinkSvg />,
      title: "Admission Dashboard",
      description: " All Admission related states & reports",
    },
    {
      img: <AcadmicLInkSvg />,
      title: "Academic Dashboard",
      description: " All Academic related states & reports",
    },
    {
      img: <AffiliationLinkSvg />,
      title: "Affiliations",
      description: " All Affiliation related states & reports",
    },
    {
      img: <TestingServicesLinkSvg />,
      title: "Testing Services",
      description: " All Testing Services related states & reports",
    },
    {
      img: <OricLinkSvg />,
      title: "ORIC",
      description: " All ORIC related states & reports",
    },
  ];
  const lineLabels = [
    "01-12-2020",
    "02-12-2020",
    "03-12-2020",
    "04-12-2020",
    "05-12-2020",
    "06-12-2020",
  ];
  const lineChartTwo = {
    labels: lineLabels, // Use newLabels for the line chart
    datasets: [
      {
        label: "",
        data: data1,
        backgroundColor: "#128B9B",
        borderColor: "#128B9B",
        borderWidth: 1,
      },
    ],
  };

  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleSelect = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedRange({ startDate, endDate });
  };
  const { getCurrentOrganization } =
  useHttp();
  useEffect(() => {
    getCurrentOrganization()
  }, [])

  // Date formatting
  const formattedStartDate = format(selectedRange.startDate, "MM/dd/yyyy");
  const formattedEndDate = format(selectedRange.endDate, "MM/dd/yyyy");

  const selectionRange = {
    startDate: selectedRange.startDate,
    endDate: selectedRange.endDate,
    key: "selection",
  };

  return (
    // All commits done by Shayan Faisal under guideline of Sir Rizwan Majeed kindly don't remove this commit code
    <AdminDashboarMain>
      <ADashboardTop>
        <div className="left">
          <span className="page-heading">
            Welcome Back {user?.name ? `, ${user?.name}` : ""}
          </span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </ADashboardTop>
      <ADashboradSection>
        <LinkSection className="content-radius-shadow">
          <div className="link-heading">My Links</div>
          <div className="link-card-container">
            {linkInfo.map((card: any, index: number) => {
              return (
                <div
                  className="link-card"
                  key={index}
                  style={{ backgroundColor: card.bgColor }}
                >
                  <div>{card.img}</div>
                  <div className="link-title">{card.title} </div>
                </div>
              );
            })}
          </div>
        </LinkSection>
        <div className="top-cards-container">
          {topCards.map((card, index) => (
            <div className="top-cards" key={index}>
              <div className="icon" style={{ backgroundColor: card.bgColor }}>
                {card?.img}
              </div>
              <div className="card-title-main">
                <div className="title">{card.title} </div>
                <div className="bottom-title">
                  <span className="title-value">{card.stats_value}</span>
                  <span className="title-value2">{card.active_value}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <ChartSection>
          <PieChart>
            <div className="header-section">
              <div className="title">
                <span>Fee Collections</span>
              </div>
              <div className="pie-container">
                <div className="description">
                  <span className="fee">RS 95,000,02</span>
                  {renderLegend()}
                </div>

                <div className="pie-chart-section">
                  <Pie data={pieChartData} options={options} />
                </div>
              </div>
            </div>
          </PieChart>
          <LineChart>
            <div className="header-section">
              <div className="title">
                <span>Earning vs Expenses</span>
              </div>

              <div className="line-chart-section">
                <Bar data={chartDataTwo} options={options} />
              </div>
            </div>
          </LineChart>
        </ChartSection>

        <QuickLinkSection>
          <QuickLinks>
            <div className="quick-links-top">
              <div className="heading">Quick Links</div>
              <div className="left-heading">View all Dashboards</div>
            </div>
            {quickLinks.map((links: any) => {
              return (
                <div className="links-main">
                  <div>{links.img}</div>
                  <div className="link-description-main">
                    <div className="link-title">{links.title}</div>
                    <div className="link-description">{links.description}</div>
                  </div>
                </div>
              );
            })}
          </QuickLinks>
          <ActivityLogs className="content-radius-shadow">
            <div className="quick-links-top">
              <div className="heading">Activity Logs</div>
            </div>
            <ul>
              {[1, 1, 1, 1, 1, 1].map(() => {
                return (
                  <li className="list-item">
                    Muhammad Abu Bakar
                    <span className="gray">
                      logged in to the system. 2 seconds ago.
                    </span>
                    <div className="gray"> System IP: (154.80.113.159)</div>
                  </li>
                );
              })}
            </ul>
          </ActivityLogs>
        </QuickLinkSection>
        <div className="line-graph-container">
   
          <MainStatCards>
            <MainStatCard>
              <div className="header-section">
                <div className="title">
                  <span>Teaching Staff </span>
                </div>
                <div className="sub-heading">
                  Active Staff <span className="primary"> 3,120 </span>
                </div>
              </div>

              <div className="chart-section">
                <Bar data={chartDataThree} options={options} />
              </div>
            </MainStatCard>
          </MainStatCards>
     
          <MainStatCards>
            <MainStatCard>
              <div className="header-section">
                <div className="title">
                  <span>Students </span>
                </div>
                <div className="sub-heading">
                  Active Student <span className="primary"> 3,120 </span>
                </div>
              </div>

              <div className="">
                <Line data={lineChartTwo} options={options} />
              </div>
            </MainStatCard>
          </MainStatCards>
        </div> */}

        <SheduleSection className="content-radius-shadow">
          <div className="shedule-top">
            <div className="shedule-heading">Schedules</div>
            <div className="top-left">Add New</div>
          </div>
          <div className="shedule-container">
            <div className="date-picker-section">
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                staticRanges={[]}
                inputRanges={[]}
              />
            </div>
            <div className="upcomming-event-section">
              <div className="event-top">
                <div className="top-right">Upcoming Events</div>
                <div className="top-left">View All</div>
              </div>
              {[1, 1, 1].map(() => {
                return (
                  <div className="event-description-main">
                    <div className="">
                      <UpcommingEventSvg />
                    </div>
                    <div className="event-desctiption-container">
                      <div className="event-title">
                        UCV International Conference
                      </div>
                      <div className="even-timings">
                        <EventCalendarSvg />
                        <div className="times">
                          15 November 2024 <span>09:10AM - 10:50PM</span>
                        </div>
                      </div>
                    </div>
                    <div className="participant-img-container">
                      <img src={dummyImg} alt="" className="participant-img" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </SheduleSection>

        {/* <BottomSection>
          <PieChart>
            <div className="header-section">
              <div className="title">
                <span>Employees Attendance</span>
              </div>
              <div className="pie-container">
                <div className="description">
                  <span className="fee">Total Employees : 100</span>

                  {renderLegend()}
                </div>

                <div className="pie-chart-section">
                  <Pie data={pieChartData} options={options} />
                </div>
              </div>
            </div>
          </PieChart>
          <LeaveRequests className="content-radius-shadow">
            <div className="upcomming-event-section">
              <div className="event-top">
                <div className="top-right">Leave Requests </div>
                <div className="top-left">View All</div>
              </div>
              {[1, 1].map(() => {
                return (
                  <div className="event-description-main">
                    <div className="">
                      <img width="50" src={fakeImg} alt="" />
                    </div>
                    <div className="event-desctiption-container">
                      <div className="event-title">Faizan Rasheed</div>
                      <div className="even-timings">
                        <div className="times">Voice chancelor</div>
                      </div>
                    </div>
                    <div>
                      <span className="status-tile">Allocated</span>
                    </div>
                    <div className="table-action-icons">
                      <div className="action-icons">
                        <TickSvg />
                      </div>
                      <div className="action-icons">
                        <CrossSvg />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </LeaveRequests>
        </BottomSection> */}
      </ADashboradSection>
    </AdminDashboarMain>
    // All commits done by Shayan Faisal under guideline of Sir Rizwan Majeed kindly don't remove this commit code
  );
};

export default AdminDashboard;
