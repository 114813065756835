import { FC, useEffect, useState, ChangeEvent } from "react";
import {
  CreateOrgSTMain,
  OrgSTCreateTop,
  OrgSTContentSection,
  FormSection,
} from "./style";
import profileLogo from "assets/images/organization/others/profile-logo.png";
import { useForm } from "react-hook-form";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { warningToaster } from "utils/helpers/common/alert-service";
import { StudentPersonalInfoDTO } from "utils/helpers/models/e-portal/edit-student-profile.dto";
import useEportal from "../../useHooks";
import useComponentVisible from "hooks/click-outside";
import SingleSelectionDropdown from "components/particles/forms/single-selection-dropdown";

const EditEPStudentProfile: FC<any> = () => {
  const [formData, setFormData] = useState<StudentPersonalInfoDTO>(
    new StudentPersonalInfoDTO()
  );
  const {
    isComponentVisible: showDomicileDropdown,
    setIsComponentVisible: setShowDomicileDropdown,
    ref: domicileDropdownRef,
  } = useComponentVisible();
  const {
    isComponentVisible: showCurrentAddressDropdown,
    setIsComponentVisible: setShowCurrentAddressDropdown,
    ref: currentAddressDropdownRef,
  } = useComponentVisible();
  const {
    isComponentVisible: showPermanentAddressDropdown,
    setIsComponentVisible: setShowPermanentAddressDropdown,
    ref: permanentAddressDropdown,
  } = useComponentVisible();
  const [cities, setCities] = useState<any[]>([]);
  const { getStudentPersonalInfo, updateUserPersonalInfo, getCitiesByUser } = useEportal();
  const {
    register,
    reset,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<StudentPersonalInfoDTO>();

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger([name]);
    setFormData({ ...formData, [name]: value });
  };

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files![0];
    if (file) {
      setValue("profile_image", file);
      setFormData({ ...formData, profile_image: URL.createObjectURL(file) });
      trigger(["profile_image"]);
    }
  };

  useEffect(() => {
    getStudentPersonalInfo(formData, setFormData, setValue);
    getCitiesByUser(setCities);
  }, []);

  const onSubmit = (data: StudentPersonalInfoDTO) => {
    if (!formData.profile_image) {
      warningToaster("Profile image is required");
      return;
    }
    const form_data = new FormData();
    for (let key in data) {
      if (key === 'is_self_dependent') {
        form_data.append(key, (formData as any)[key]);
      } else {
        form_data.append(key, (data as any)[key]);
      }
    }
    updateUserPersonalInfo(form_data);
  };

  const resetForm = () => {
    for (let key in formData) {
      setValue(key as keyof StudentPersonalInfoDTO, '');
    }

    setFormData({ ...new StudentPersonalInfoDTO() });
  }

  const handleIsDependentCheckbox = (event: any) => {
    const { checked } = event.target;
    const value = checked ? 1 : 0;
    setValue("is_self_dependent", value);
    setFormData({ ...formData, is_self_dependent: value });
  };

  const onSelectDomicile = (city: any) => {
    const { id: value } = city;
    handleChange({ target: { name: 'domicile', value } });
  }
  const onSelectCurrentAddress = (city: any) => {
    const { id: value } = city;
    handleChange({ target: { name: 'm_city', value } });
  }
  const onSelectPermanentAddress = (city: any) => {
    const { id: value } = city;
    handleChange({ target: { name: 'p_city', value } });
  }

  const getValue = (name: string) => {
    const value = cities.find(c => c.id == (formData as any)[name])?.title;
    return value ?? '';
  }
  return (
    <CreateOrgSTMain>
      <OrgSTCreateTop>
        <span className="page-heading">Edit Profile</span>
        {/* {!params?.id && <Breadcrumb links={breadcrumbLinks} />} */}
      </OrgSTCreateTop>
      <OrgSTContentSection>
        <FormSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="step-2">
              <div className="upload-profile-image-field">
                <div className="field">
                  <label className="image" htmlFor="profile-image">
                    <img
                      src={formData?.profile_image ?? profileLogo}
                      alt="Profile"
                    />
                    <input
                      type="file"
                      className="d-none"
                      id="profile-image"
                      accept="image/*"
                      {...register("profile_image")}
                      onChange={handleUpload}
                    />
                  </label>
                  <label htmlFor="profile-image">Profile Photo</label>
                </div>
                <FormErrorMessage error={errors.profile_image} />
              </div>
              <div className="common-fields">
                <div className="input-field">
                  <label>First Name</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="text"
                        value={formData.first_name}
                        {...register("first_name", { required: false })}
                        onChange={handleChange}
                      />
                    </div>
                    <FormErrorMessage error={errors.first_name} />
                  </div>
                </div>
                <div className="input-field">
                  <label>Last Name</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="text"
                        value={formData.last_name}
                        {...register("last_name", { required: false })}
                        onChange={handleChange}
                      />
                    </div>
                    <FormErrorMessage error={errors.last_name} />
                  </div>
                </div>
                <div className="input-field">
                  <label>Father Name</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="text"
                        value={formData.father_name}
                        {...register("father_name", { required: false })}
                        onChange={handleChange}
                      />
                    </div>
                    {/* <FormErrorMessage error={errors.website} /> */}
                  </div>
                </div>
                <div className="input-field">
                  <label>Gender</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select
                        value={formData?.gender}
                        {...register("gender", { required: false })}
                        onChange={handleChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <FormErrorMessage error={errors.gender} />
                  </div>
                </div>
                <div className="input-field">
                  <label>Email</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="tel"
                        placeholder="Enter Your Email"
                        value={formData.email}
                        {...register("email", { required: false })}
                        onChange={handleChange}
                      />
                    </div>
                    <FormErrorMessage error={errors.email} />
                  </div>
                </div>
                <div className="input-field">
                  <label>Phone</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="text"
                        placeholder="Street abc"
                        value={formData.phone_no}
                        {...register("phone_no", { required: false })}
                        onChange={handleChange}
                      />
                    </div>
                    <FormErrorMessage error={errors.phone_no} />
                  </div>
                </div>

                {formData.cnic ? <div className="input-field">
                  <label>CNIC/B.Form</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="text"
                        value={formData.cnic}
                        readOnly
                        {...register("cnic", { required: false })}
                        onChange={handleChange}
                      />
                    </div>
                    <FormErrorMessage error={errors.cnic} />
                  </div>
                </div>: <div className="input-field">
                  <label>Passport</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="text"
                        value={formData.passport_no}
                        readOnly
                        {...register("passport_no", { required: false })}
                        onChange={handleChange}
                      />
                    </div>
                    <FormErrorMessage error={errors.passport_no} />
                  </div>
                </div>}
                <div className="input-field">
                  <label>Address</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="text"
                        value={formData.address}
                        {...register("address", { required: false })}
                        onChange={handleChange}
                      />
                    </div>

                    <FormErrorMessage error={errors.address} />
                  </div>
                </div>

                <div className="input-field">
                  <label>Permanent Mailing Address( Address 1, 2)</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="text"
                        placeholder="Enter Mailing Address"
                        {...register("p_address", { required: true })}
                        value={formData.p_address}
                        onChange={handleChange}
                      />
                    </div>
                    <FormErrorMessage error={errors.p_address} />
                  </div>
                </div>
                <div className="multiselect-field" ref={permanentAddressDropdown}>
                  <div
                    className="input-field"
                    onClick={() => setShowPermanentAddressDropdown(!showPermanentAddressDropdown)}
                  >
                    <label>Permanent Address (City/Tehsil)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <div className="selected-items">
                          <input
                            type="text"
                            className="cp"
                            value={getValue('p_city')}
                            placeholder="Select City/Tehsil"
                            readOnly
                          />
                        </div>
                      </div>
                      <FormErrorMessage error={errors.p_city} />
                    </div>
                  </div>
                  {showPermanentAddressDropdown && (
                    <SingleSelectionDropdown
                      options={cities}
                      setShow={setShowPermanentAddressDropdown}
                      value={
                        formData.p_city
                      }
                      onSelect={onSelectPermanentAddress}
                    />
                  )}
                </div>
                <div className="checkbox-field">
                  <input
                    type="checkbox"
                    id="is_self_dependent"
                    {...register("is_self_dependent", { required: false })}
                    checked={formData.is_self_dependent == 1}
                    onChange={handleIsDependentCheckbox}
                  />
                  <label htmlFor="is_self_dependent">I am self dependent</label>
                </div>

                <div className="input-field">
                  <label>Date Of Birth</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="date"
                        {...register("date_of_birth", { required: true })}
                        value={formData.date_of_birth}
                        onChange={handleChange}
                      />
                    </div>
                    <FormErrorMessage error={errors.date_of_birth} />
                  </div>
                </div>
                <div className="multiselect-field" ref={domicileDropdownRef}>
                  <div
                    className="input-field"
                    onClick={() => setShowDomicileDropdown(!showDomicileDropdown)}
                  >
                    <label>Domicile city</label>
                    <div className="field-wrap">
                      <div className="field">
                        <div className="selected-items">
                          <input
                            type="text"
                            className="cp"
                            value={getValue('domicile')}
                            placeholder="Select domicile city"
                            readOnly
                          />
                        </div>
                      </div>
                      <FormErrorMessage error={errors.domicile} />
                    </div>
                  </div>
                  {showDomicileDropdown && (
                    <SingleSelectionDropdown
                      options={cities}
                      setShow={setShowDomicileDropdown}
                      value={
                        formData.domicile
                      }
                      onSelect={onSelectDomicile}
                    />
                  )}
                </div>
                <div className="input-field">
                  <label>Current Mailing Address( Address 1, 2)</label>
                  <div className="field-wrap">
                    <div className="field">
                      <input
                        type="text"
                        placeholder="Enter Mailing Address"
                        {...register("m_address", { required: true })}
                        value={formData.m_address}
                        onChange={handleChange}
                      />
                    </div>
                    <FormErrorMessage error={errors.m_address} />
                  </div>
                </div>
                <div className="multiselect-field" ref={currentAddressDropdownRef}>
                  <div
                    className="input-field"
                    onClick={() => setShowCurrentAddressDropdown(!showCurrentAddressDropdown)}
                  >
                    <label>Current Address (City/Tehsil)</label>
                    <div className="field-wrap">
                      <div className="field">
                        <div className="selected-items">
                          <input
                            type="text"
                            className="cp"
                            value={getValue('m_city')}
                            placeholder="Select City/Tehsil"
                            readOnly
                          />
                        </div>
                      </div>
                      <FormErrorMessage error={errors.m_city} />
                    </div>
                  </div>
                  {showCurrentAddressDropdown && (
                    <SingleSelectionDropdown
                      options={cities}
                      setShow={setShowCurrentAddressDropdown}
                      value={
                        formData.m_city
                      }
                      onSelect={onSelectCurrentAddress}
                    />
                  )}
                </div>
              </div>

              <div className="submit-buttons">
                <div className="buttons">
                  <button className="lg-rounded-btn gray" type="button" onClick={resetForm}>Reset</button>
                  <button type="submit" className="lg-rounded-btn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </FormSection>
      </OrgSTContentSection>
    </CreateOrgSTMain>
  );
};

export default EditEPStudentProfile;