import { FC, useEffect, useState } from "react";
import {
  AdminDashboarMain,
  ADashboardTop,
  ADashboradSection,
  LinkSection,
  AllApplicantsSection,
  RegisteredSection,
  FilterSection,
  FilterHeader,
  Filters,
} from "./style";
import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import {
  AttendanceLinkSvg,
  AuthLinkSvg,
  CampusSvg,
  FacilitiesSvg,
  InitiateLinkSvg,
  NonTeachingStaffSvg,
  ProfileLinkSvg,
  StudentCardSvg,
  TeachingStaffSvg,
} from "assets/images/common/svgs";
import plus from "assets/images/common/others/plus.png";
import minus from "assets/images/common/others/minus.png";

import useDSSystem from "../useHook";
import useAdmissions from "containers/private/admissions/useHooks";

const AdmissionDashboard: FC = ({}) => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Decision Support System /", path: "" },

    { title: "Admission Dashboard", path: siteRoutes.admissionDashboard },
  ];
  const { getAdmissionDashboard, getFilterDataForAdmDashboard } = useDSSystem();

  const [isLinkOpen, setIsLinkOpen] = useState<boolean>(true);
  const [isApplicantOpen, setIsApplicantOpen] = useState<boolean>(true);
  const [isRegisteredOpen, setIsRegisteredOpen] = useState<boolean>(true);
  const [isFilterOpen, setIsFilterFilter] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [studentdata, setStudentData] = useState<any>([]);
  const [filters, setFilters] = useState<any>({
    campus_id: "",
    session_year: "",
    session_type: "",
  });
  const getFilteredAdmissionDashboard = () => {
    const filterOptions = { ...filters };

    for (let key in filterOptions) {
      if (!filters[key]) {
        delete filterOptions[key];
      }
    }

    const queryParams = {
      ...filterOptions,
    };

    getAdmissionDashboard(setData, queryParams);
  };

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const toggleContainer = () => {
    setIsLinkOpen((prevState) => !prevState);
  };

  const toggleApplicant = () => {
    setIsApplicantOpen((prevState) => !prevState);
  };
  const toggleRegistered = () => {
    setIsRegisteredOpen((prevState) => !prevState);
  };

  const toggleFilter = () => {
    setIsFilterFilter((prevState) => !prevState);
  };

  useEffect(() => {
    getAdmissionDashboard(setData, filters);
    getFilterDataForAdmDashboard(setStudentData);
  }, []);
  const handleApplyFilters = () => {
    getFilteredAdmissionDashboard();
  };

  return (
    <AdminDashboarMain>
      <ADashboardTop>
        <div className="left">
          <span className="page-heading">Admission Dashboard</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </ADashboardTop>
      <ADashboradSection>
        <FilterSection className="content-radius-shadow">
          <FilterHeader>
            <span className="filter-heading">Filter</span>
            <div className="icon-main" onClick={toggleFilter}>
              {isFilterOpen ? (
                <img src={minus} alt="plus-icon" className="toggle-icon" />
              ) : (
                <img src={plus} alt="plus-icon" className="toggle-icon" />
              )}
            </div>
          </FilterHeader>
          {isFilterOpen && (
            <Filters>
              <div className="filter-fields">
                <div className="input-field">
                  <label>Campus</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select name="campus_id" onChange={handleFilterChange}>
                        <option value="">All Campus</option>
                        {studentdata?.map((item: any, index: number) => {
                          return (
                            <option value={item?.campus_id}>
                              {item?.campus_title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label>Session Year</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select name="session_year" onChange={handleFilterChange}>
                        <option value="">All Year</option>
                        {studentdata?.map((item: any, index: number) => {
                          return (
                            <option value={item?.session_year}>
                              {item?.session_year}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  <label>Session type</label>
                  <div className="field-wrap">
                    <div className="field">
                      <select name="session_type" onChange={handleFilterChange}>
                        <option value="">All Types</option>
                        {studentdata?.map((item: any, index: number) => {
                          return (
                            <option value={item?.session_type}>
                              {item?.session_type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-buttons">
                <div className="buttons">
                  <button className="lg-rounded-btn gray">Reset</button>
                  <button
                    className="lg-rounded-btn"
                    onClick={handleApplyFilters}
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </Filters>
          )}
        </FilterSection>

        <LinkSection className="content-radius-shadow">
        
            <div className="link-card-container">
              {[
                {
                  title: "Total Users",
                  value: data?.total_users ?? "-", // Primitive value
                  color: "#ffbb3826",
                  Icon: AuthLinkSvg,
                },
                {
                  title: "Signed Up Users",
                  value: data?.signed_up_users?.total_count ?? "-", // Extract total_count
                  color: "#0FCB0226",
                  Icon: ProfileLinkSvg,
                },
                {
                  title: "All Applicants",
                  value: data?.all_applications_count?.total_count ?? "-", // Extract total_count
                  color: "#128BAB26",
                  Icon: AuthLinkSvg,
                },
                {
                  title: "Registered Students",
                  value: data?.all_register_users?.total_count ?? "-", // Extract total_count
                  color: "#4A3AFF1A",
                  Icon: AuthLinkSvg,
                },
              ].map((card, index) => (
                <div
                  className="link-card"
                  style={{ backgroundColor: card.color }}
                  key={index}
                >
                  <div>
                    <card.Icon />
                  </div>
                  <div className="link-card-text">
                    <div className="link-title">{card.title}</div>
                    <div className="total-amount">{card.value}</div>
                  </div>
                </div>
              ))}
            </div>
  
        </LinkSection>

        <AllApplicantsSection>
          <div className="link-main">
            <div className="link-heading">All Applications</div>
            <div className="icon-main" onClick={toggleApplicant}>
              {isApplicantOpen ? (
                <img src={minus} alt="plus-icon" className="toggle-icon" />
              ) : (
                <img src={plus} alt="plus-icon" className="toggle-icon" />
              )}
            </div>
          </div>
          {isApplicantOpen && (
            <div className="top-cards-container">
              {data?.all_applications_by_program?.map(
                (item: any, index: number) => (
                  <div className="top-cards" key={index}>
                    <div
                      className="icon"
                      style={{ backgroundColor: "#0FCB0226" }}
                    >
                      <FacilitiesSvg />
                    </div>
                    <div className="card-title-main">
                      <div className="title">{item?.program} </div>
                      <div className="bottom-title">
                        <span className="title-value">
                        {item?.total_count}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </AllApplicantsSection>
        <RegisteredSection>
          <div className="link-main">
            <div className="link-heading">Registered</div>
            <div className="icon-main" onClick={toggleRegistered}>
              {isRegisteredOpen ? (
                <img src={minus} alt="plus-icon" className="toggle-icon" />
              ) : (
                <img src={plus} alt="plus-icon" className="toggle-icon" />
              )}
            </div>
          </div>
          {isRegisteredOpen && (
            <div className="top-cards-container">
              {data?.all_reg_applications_by_program?.map(
                (item: any, index: number) => (
                  <div className="top-cards" key={index}>
                    <div
                      className="icon"
                      style={{ backgroundColor: "#0FCB0226" }}
                    >
                      <FacilitiesSvg />
                    </div>
                    <div className="card-title-main">
                      <div className="title">{item?.program} </div>
                      <div className="bottom-title">
                        <span className="title-value">{item?.total_count}</span>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </RegisteredSection>
      </ADashboradSection>
    </AdminDashboarMain>
  );
};

export default AdmissionDashboard;
