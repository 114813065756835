import useUtils from "hooks/useUtils";
import { DSSYSTEM_APIS } from "libs/apis/d-support-system.api";
import { ORGANIZATION_APIS } from "libs/apis/organization.api";
import { SUPER_ADMIN_APIS } from "libs/apis/super-admin.api";
import { useNavigate } from "react-router-dom";
import {
  errorToaster,
  successToaster,
} from "utils/helpers/common/alert-service";
import { errorMessages } from "utils/helpers/enums/messages.enum";
import { siteRoutes } from "utils/helpers/enums/routes.enum";

const useDSSystem = () => {
  const { downloadFileWithUrl, openExternalLink } = useUtils();
  const navigate = useNavigate();

  const getAdmissionDashboard = async (setData: Function, queryParams: any) => {
    const response = await DSSYSTEM_APIS.getAdmissionDashboard(queryParams);

    if (response?.status) {
      setData(response?.response);
    }
  };
  const getFilterDataForAdmDashboard = async (setData: Function) => {
    const response = await DSSYSTEM_APIS.getFilterDataForAdmDashboard();

    if (response?.status) {
      setData(response?.response);
    }
  };

  return {
    getAdmissionDashboard,
    getFilterDataForAdmDashboard,
  };
};

export default useDSSystem;
