import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { EMAILTEMPLATE_API } from "libs/apis/email-template.api";
import { AddEmailTemplateDTO } from "utils/helpers/models/email-templates/email-templates.dto";

const useEmailTemplate = () => {
  const navigate = useNavigate();

  const createEmailTemplate = async (
    body: AddEmailTemplateDTO,
    addMore: boolean,
    resetForm: Function
  ) => {
    const response = await EMAILTEMPLATE_API.createEmailTEmplate(body);
    if (response?.status) {
      if (addMore) {
        successToaster(response?.message);
        resetForm();
      } else {
        successToaster(response?.message);
        navigate(siteRoutes.emailTemplateListing);
      }
    }
  };

  const getEmailTemplateList = async (
    setData: Function,
    queryParams: any = { per_page: "All", page: 1 }
  ) => {
    const response = await EMAILTEMPLATE_API.getEmailTEmplate(queryParams);
    const data = response?.response;
    if (response?.status) {
      setData(data);
    }
  };
  const getEmailTemplateListById = async (
    id: number,
    formData: any,
    setFormData: Function,
    setValue: Function
  ) => {
    const response = await EMAILTEMPLATE_API.getEmailTEmplateByID(id);
    const data = response?.response;
    if (response?.status) {
      for (let key in data) {
        formData[key] = data[key];
        setValue(key, formData[key]);
      }
      setFormData({ ...formData });
    }
  };

  const updateEmailTemplateListById = async (
    id: number,
    addMore: boolean,
    resetForm: Function,
    body: AddEmailTemplateDTO | any
  ) => {
    const response = await EMAILTEMPLATE_API.updateEmailTEmplateByID(id, body);
    const data = response?.response;
    if (response?.status) {
      successToaster(response?.message);
      resetForm();
      navigate(siteRoutes.emailTemplateListing);
    }
  };
  const getEmailTemplateKeys = async (setTempKey: Function) => {
    const response = await EMAILTEMPLATE_API.getEmailTemplateKey();
    const data = response?.response;
    if (response?.status) {
      setTempKey(data);
    }
  };

  const deleteEmailTemplatesById = async (id: number) => {
    const response = await EMAILTEMPLATE_API.deleteEmailTEmplateById(id);
    if (response?.status) {
      successToaster(response?.message);
    }
  };

  return {
    createEmailTemplate,
    getEmailTemplateList,
    getEmailTemplateListById,
    updateEmailTemplateListById,
    getEmailTemplateKeys,
    deleteEmailTemplatesById,
  };
};

export default useEmailTemplate;
