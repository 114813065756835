export class AddRoomDto {
  is_lab!: number;
  length: string = "";
  width: string = "";
  building!: number;
  building_id!: number;
  room_type_id!: number;
  seating_capacity!: number;
  exam_seating_capacity!: number;
  is_available!: number;
  room_number: string = "";
  room_name: string = "";
  room_title: string = "";
  room_type: string = "";
  slug: string = "";
  room_type_title: string = "";
  floor!: number;
  room_image: any = "";
}
