import styled from "styled-components";

export const RegisterPageStudent = styled.div`
  display: block;
  height: 100vh;
  overflow-y: auto;

  @media screen and (max-width: 450px) {
    overflow-y: auto;
    padding: 1em 0;
  }

`;
export const StudentRegisterMain = styled.div`
  width: 100%;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  @media screen and (max-width: 630px) {
    padding: 0 1em;
  }
`;

export const RegisterForm = styled.form`
  width: 75%;
  margin: 2em auto;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  justify-content: space-between;

  .heading-wrapper {
    display: flex;
    gap: 0.5em;
    p {
      padding-top: 0.8em;
    }

    .main-heading {
      font-size: var(--font-32);
      font-weight: 500;
      color: var(--black-text);
      margin-bottom: 1.2em;

      
    @media screen and (max-width: 630px){
      font-size: var(--font-24);
    }
    }

    @media screen and (max-width: 930px) {
      flex-direction: column;
      .main-heading {
        margin-bottom: 0;
      }
      p {
        padding-top: 0;
      }
    }
  }

  @media screen and (max-width: 930px) {
    .heading-wrapper {
      padding-bottom: 1em;
    }
  }
  @media screen and (max-width: 1260px) {
    border-radius: 12px !important;
  }

  @media screen and (max-width: 630px) {
    width: 100%;
    padding: 2.5em 1em;
  }

  .flex_radio {
    display: flex;
    gap: 2rem;
    padding-top: 1em;
  }
  .flex_radio label {
    font-size: 14px;
  }

  .error-text {
    display: grid;
    grid-auto-flow: column; 
    grid-auto-columns: auto; 
    gap: 1rem;
    font-weight: 400;
    font-size: 16px;
    color: var(--black-text);
  }

  @media screen and (max-width: 1280px) {
    .error-text {
      grid-auto-flow: row;
      gap: 0;
      font-size: 12px;
    }
  }

  .error-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -0.5em;
  }
  .error-box {
    background-color: var(--primary-extralight);
    padding: 10px 20px;
    margin-top: 30px;
    width: 100%;
  }
  .bottom-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    @media (max-width: 690px) {
      flex-direction: column-reverse;
    }
  }

  .form-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    /* align-items: center; */

    .heading {
      font-size: 18px;
      font-weight: 400;
      color: var(--primary);

      @media screen and (max-width: 610px) {
        font-size: 20px;
      }
    }
    .sub-heading {
      font-size: 14px;
      font-weight: 400;
      color: var(--lightgray-light);

      @media screen and (max-width: 610px) {
        font-size: 12px;
      }
    }
  }

  .tag-line {
    color: var(--lightgray-light);
    font-size: 15px;
    /* line-height: 30px; */
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .common-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;
    margin-top: -0.5em;

    @media screen and (max-width: 490px) {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

      .field{
        height: 45px;
      }
    }
  }

  .bottom-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;

    @media screen and (max-width: 610px) {
      font-size: 12px;
    }

    .remember-me {
      display: flex;
      gap: 0.5em;
      align-items: center;
      padding-left: 0.5em;

      input[type="checkbox"] {
        width: 18px;
        height: 18px;
        accent-color: var(--primary);
      }

      label {
        color: var(--black-text);
      }
    }

    span {
      color: var(--black-text);
    }
  }

  .submit-button {
    width: 100%;
    padding-top: 1em;
    display: flex;
    justify-content: end;
    white-space: nowrap;
    @media screen and (max-width: 610px) {
      font-size: 16px;
      width: 100%;
      justify-content: center;
    }

    button {
      width: 15rem;
      height: 50px;
      padding: 0 20px;
      color: white;
      font-size: var(--font-20);
      background: var(--primary);
      border-radius: 5px;
      font-weight: 600;
      @media screen and (max-width: 610px) {
        width: 100%;
      }

      @media screen and (max-width: 490px){
        height: 45px;
        font-size: var(--font-16);
      }

      .loader {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .password-icon {
    width: 14px;
    height: 13px;
  }

  .bottom-text {
    width: 50%;
    display: flex;
    color: var(--black-text);
    margin-top: 20px;
    @media screen and (max-width: 610px) {
      font-size: 14px;
      width: 100%;
      justify-content: center;
    }
  }
`;


