import { FC, useEffect, useState } from "react";
import { AddEntryTestMain, Container, ContentWrapper } from "./style";
import { CloseMediumSvg, DepartmentSvg } from "assets/images/common/svgs";
import { PlanOfStudyCourseDTO } from "utils/helpers/models/academics/plan-of-study-course.dto";
import { useForm } from "react-hook-form";
import useAcademics from "containers/private/academics/useHooks";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { TemplateCourseDTO } from "utils/helpers/models/academics/template-course.dto";

interface AddEntryTestProps {
  setOpen: Function;
  id?: number;
}

const CreateCourseRoom: FC<AddEntryTestProps> = ({ setOpen }) => {
  const { getQueryParams } = useUtils();
  const { pos_id } = getQueryParams();
  const [formData, setFormData] = useState<PlanOfStudyCourseDTO>({ ...new PlanOfStudyCourseDTO(), pos_id });
  const { register, formState: { errors }, setValue, trigger, handleSubmit } = useForm<PlanOfStudyCourseDTO>();
  const [templateCourses, setTemplateCourses] = useState<TemplateCourseDTO[]>([]);
  const { createPlanOfStudyCourse, getTemplateCourses } = useAcademics();

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    createPlanOfStudyCourse(formData, setOpen);
  }

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  }

  useEffect(() => {
    getTemplateCourses(setTemplateCourses);
  }, []);

  return (
    <AddEntryTestMain>
      <Container>
        <ContentWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Add New Course</span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="common-fields">
              <div className="input-field">
                <label>Courses</label>
                <div className="field-wrap">
                  <div className="field">
                    <select {...register('tc_id', { required: true })} value={formData.tc_id} onChange={handleChange}>
                      <option value="">{"Code \u00A0 \u00A0 | \u00A0 \u00A0 Course Name \u00A0 \u00A0 | \u00A0 \u00A0 CH \u00A0 \u00A0 | \u00A0 \u00A0 is Lab"}</option>
                      {templateCourses.map((course, index) => {
                        return <option value={course.id} key={index}>{course.tc_code} {"\u00A0 \u00A0"} | {course.tc_title} | {course.department.title} | {course.tc_is_lab ? 'Yes' : 'No'}</option>
                      })}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.tc_id} />
                </div>
              </div>

              <div className="input-field">
                <label>Semester</label>
                <div className="field-wrap">
                  <div className="field">
                    <select {...register('semester_seq_number', { required: true })} value={formData.semester_seq_number} onChange={handleChange}>
                      <option value="">Select one</option>
                      {Array.from({ length: 8 }, (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                  <FormErrorMessage error={errors.semester_seq_number} />
                </div>
              </div>
              {/* <div className="input-field">
                <label>Semester Sequence Number</label>
                <div className="field-wrap">
                  <div className="field">
                    <input type="number" {...register('semester_seq_number', { required: true })} value={formData.semester_seq_number} onChange={handleChange} />
                  </div>
                  <FormErrorMessage error={errors.semester_seq_number} />
                </div>
              </div> */}

              <div className="radio-field">
                <label>Show on transcript?</label>
                <div className="field-wrap">
                  <div className="field">
                    <label htmlFor="show_on_transcript_yes">Yes</label>
                    <input
                      type="radio"
                      id="show_on_transcript_yes"
                      value={1}
                      checked={formData.show_on_transcript == 1}
                      {...register("show_on_transcript", { required: true })}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="show_on_transcript_no">No</label>
                    <input
                      type="radio"
                      id="show_on_transcript_no"
                      value={0}
                      checked={formData.show_on_transcript == 0}
                      {...register("show_on_transcript", { required: true })}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <FormErrorMessage error={errors?.show_on_transcript} />
              </div>
            </div>

            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="reset"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button className="lg-rounded-btn">
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </ContentWrapper>
      </Container>
    </AddEntryTestMain>
  );
};

export default CreateCourseRoom;
