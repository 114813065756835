import { FC } from "react"
import { NotificationsMain } from "./style";
import { EmailSvg, NotificationEmailSvg } from "assets/images/common/svgs";
interface NotificationsProps{}

const NotificationsPopup: FC<NotificationsProps> = () => {
    return(
        <NotificationsMain>
           <div className="header-section">
            <div className="header-section-left">
                <div className="title">
                    <span>Notifications</span>
                </div>
            </div>
            <div className="header-section-right">
                <div className="icon">
                    <NotificationEmailSvg/>
                </div>
            </div>
           </div>
           <div className="content-section">
            <div className="view-all">
                <span>View all notifications</span>
            </div>
           </div>
        </NotificationsMain>
    )
}

export default NotificationsPopup;