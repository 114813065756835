import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useEffect, useState } from "react";
import {
  CreatePlanOfStudyForm,
  CreatePlanOfStudyMain,
  CreatePlanOfStudyTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useAcademics from "../../useHooks";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { GradeTemplateDTO } from "utils/helpers/models/academics/grade-template.dto";
import useOrganization from "containers/private/organization/useHooks";
import SingleSelectionDropdown from "components/particles/forms/single-selection-dropdown";
import useComponentVisible from "hooks/click-outside";
import { AddProgramDTO } from "utils/helpers/models/organization/add-program.dto";
import { ResultTemplateDTO } from "utils/helpers/models/academics/result-template.dto";

export const CreatePlanOfStudy: FC = () => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    { title: "Plan of Studies  / ", path: siteRoutes.academicPlanofStudies },
    { title: `${params?.id ? 'Update' : 'Add'} Plan Of Studies`, path: siteRoutes.createStudyPlans },
  ];

  const { handleSubmit, register, setValue, trigger, formState: { errors } } = useForm<PlanOfStudyDTO>();
  let [formData, setFormData] = useState<PlanOfStudyDTO>(new PlanOfStudyDTO());
  const [programs, setPrograms] = useState<AddProgramDTO[]>([]);
  const [gradeTemplates, setGradeTemplates] = useState<GradeTemplateDTO[]>([]);
  const [resultTemplates, setResultTemplates] = useState<ResultTemplateDTO[]>([]);
  const {
    createPlanOfStudy,
    updatePlanOfStudy,
    getPlanOfStudyById,
    getGradeTemplates,
    getResultTemplate,
  } = useAcademics();
  const {
    isComponentVisible: showProgramDropdown,
    setIsComponentVisible: setShowProgramDropdown,
    ref: programDropdownRef,
  } = useComponentVisible();
  const {
    isComponentVisible: showResultTemplateDropdown,
    setIsComponentVisible: setShowResultTemplateDropdown,
    ref: resultTemplateRef,
  } = useComponentVisible();
  const {
    isComponentVisible: showGradeTemplateDropdown,
    setIsComponentVisible: setShowGradeTemplateDropdown,
    ref: gradeTemplateRef,
  } = useComponentVisible();
  const { getPrograms } = useOrganization();

  const onSubmit = () => {
    if (params?.id) {
      updatePlanOfStudy(params?.id, formData);
    } else {
      createPlanOfStudy(formData);
    }
  }

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  }

  const resetForm = () => {
    formData = new PlanOfStudyDTO();
    for (let key in formData) {
      setValue(key as keyof PlanOfStudyDTO, formData[key as keyof PlanOfStudyDTO]);
    }

    setFormData({ ...formData });
  }

  useEffect(() => {
    if (params?.id) {
      getPlanOfStudyById(params?.id, formData, setFormData, setValue);
    }
    getPrograms(setPrograms);
    getGradeTemplates(setGradeTemplates);
    getResultTemplate(setResultTemplates);
  }, []);

  const onSelectCustom = (item: any,name: string) => {
    const { id: value } = item;
    handleChange({ target: { name, value } });
  }

  const getCustomSelectValue = (name: string) => {
    if(name === 'program_id'){
      return programs.find(p => p.id == +formData.program_id)?.title ?? ''
    }else if(name === 'grade_template_id'){
      return gradeTemplates.find(gt => gt.id == +formData.grade_template_id)?.title ?? ''
    }else{
      return resultTemplates.find(rt => rt.id == +formData.result_template_id)?.rst_header?.title ?? ''
    }
  }

  useEffect(() => {
    register('program_id', {required: true});
    // register('grade_template_id', {required: true});
    // register('result_template_id', {required: true});
  }, []);

  return (
    <CreatePlanOfStudyMain>
      <CreatePlanOfStudyTop>
        <div className="left">
          <span className="page-heading">{params?.id ? 'Update' : 'Add'} Plan of study</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreatePlanOfStudyTop>

      <CreatePlanOfStudyForm className="content-radius-shadow" onSubmit={handleSubmit(onSubmit)}>
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input type="text" placeholder="Enter Title" {...register('title', { required: true })} value={formData.title} onChange={handleChange} />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>
          <div className="multiselect-field" ref={programDropdownRef}>
            <div
              className="input-field"
              onClick={() => setShowProgramDropdown(!showProgramDropdown)}
            >
              <label>Program</label>
              <div className="field-wrap">
                <div className="field">
                  <div className="selected-items">
                    <input
                      type="text"
                      className="cp"
                      value={getCustomSelectValue('program_id')}
                      placeholder="Select Program"
                      readOnly
                    />
                  </div>
                </div>
                <FormErrorMessage error={errors.program_id} />
              </div>
            </div>
            {showProgramDropdown && (
              <SingleSelectionDropdown
                options={programs}
                setShow={setShowProgramDropdown}
                value={
                  formData.program_id
                }
                onSelect={(program) => onSelectCustom(program, 'program_id')}
              />
            )}
          </div>
          {/* <div className="multiselect-field" ref={gradeTemplateRef}>
            <div
              className="input-field"
              onClick={() => setShowGradeTemplateDropdown(!showGradeTemplateDropdown)}
            >
              <label>External Grade Template</label>
              <div className="field-wrap">
                <div className="field">
                  <div className="selected-items">
                    <input
                      type="text"
                      className="cp"
                      value={getCustomSelectValue('grade_template_id')}
                      placeholder="Select one"
                      readOnly
                    />
                  </div>
                </div>
                <FormErrorMessage error={errors.grade_template_id} />
              </div>
            </div>
            {showGradeTemplateDropdown && (
              <SingleSelectionDropdown
                options={gradeTemplates}
                setShow={setShowGradeTemplateDropdown}
                value={
                  formData.grade_template_id
                }
                onSelect={(gradeTemplate) => onSelectCustom(gradeTemplate, 'grade_template_id')}
              />
            )}
          </div>
          <div className="multiselect-field" ref={resultTemplateRef}>
            <div
              className="input-field"
              onClick={() => setShowResultTemplateDropdown(!showResultTemplateDropdown)}
            >
              <label>Result Template</label>
              <div className="field-wrap">
                <div className="field">
                  <div className="selected-items">
                    <input
                      type="text"
                      className="cp"
                      value={getCustomSelectValue('result_template_id')}
                      placeholder="Select one"
                      readOnly
                    />
                  </div>
                </div>
                <FormErrorMessage error={errors.result_template_id} />
              </div>
            </div>
            {showResultTemplateDropdown && (
              <SingleSelectionDropdown
                options={resultTemplates.map(i => ({...i, title: i.rst_header.title}))}
                setShow={setShowResultTemplateDropdown}
                value={
                  formData.result_template_id
                }
                onSelect={(resultTemplate) => onSelectCustom(resultTemplate,'result_template_id')}
              />
            )}
          </div> */}

          <div className="input-field">
            <label>Total Semesters</label>
            <div className="field-wrap">
              <div className="field">
                <select {...register('total_semesters', { required: true })} value={formData.total_semesters} onChange={handleChange}>
                  <option value="">Select Semester</option>
                  {Array.from({ length: 8 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
              <FormErrorMessage error={errors.total_semesters} />
            </div>
          </div>

        </div>
        <div className="submit-buttons">
          <div className="buttons">
            <button className="lg-rounded-btn gray" type="button" onClick={resetForm}>
              Reset
            </button>
            <button className="lg-rounded-btn">{params?.id ? 'Update' : 'Submit'}</button>
          </div>
        </div>
      </CreatePlanOfStudyForm>
    </CreatePlanOfStudyMain>
  );
};

export default CreatePlanOfStudy;
