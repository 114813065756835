import styled from "styled-components";

export const NotificationsMain = styled.div`
    border: 1px solid var(--field-border);
    border-radius: 8px;
    width: 308px;
    background-color: var(--white-color);
    position: absolute;
    z-index: 100;
    right: 9rem;
    top: 5rem;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    gap: 1em;

    .header-section{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: .5em;
        border-bottom: 2px solid var(--lightgray-extralight);

        &-left{
            .title{
                font-size: var(--font-18);
                font-weight: 700;
                color: var(--lightgray-medium);
                padding-left: 1em;
            }
        }

        &-right{
            padding-right: 1em;
            .icon{
                width: 20px;
                height: 20px;

                svg{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .content-section{
        width: 100%;
        display: flex;
        justify-content: center;

        .view-all{
            font-size: var(--font-16);
            font-weight: 400;
            color: var(--primary);

            span{
                cursor: pointer;
            }
        }
    }
    `;