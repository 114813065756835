
import { FC, Fragment, useEffect, useState } from "react";

import {
  CoReqListingMain,
  CoReqListingSection,
  CoReqListingTop,
} from "./style";

import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  RoomActionIconSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";

import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

import Breadcrumb from "components/particles/breadcrumb";
import useStore from "hooks/useStore";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import TemplateCourseCoReqCreate from "../create";
import useUtils from "hooks/useUtils";
import useAcademics from "../../useHooks";

interface CorequestProps { }

const columns: string[] = [
  "Image",
  "Campus",
  "Building title",
  "Building Length",
  "Building Width",
  "Latitude",
  "Longitude",
  "Short Name",
  "Action",
];

const TemplateCoursesCoReqListing: FC<CorequestProps> = ({ }) => {

  const breadcrumbLinks = [
    { title: "Academics / ", path: "" },
    { title: "Template Course /", path: siteRoutes.academicTemplateCoursesListing },
    { title: "Co Req Course Listing", path: siteRoutes.academicTemplateCoursesCoReqListing },
  ];

  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  
  const navigate = useNavigate();
  const { hasAccess } = useStore();
  const { confirmationPopup } = useAlert();

  const {
    GetCoRequsiteCourses,
  } = useAcademics();

  const [data, setData] = useState<any>([])
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const AddCoReqCourses = () => {
    setOpenModal(true);
  };

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    console.log(result);

    if (result.isConfirmed) {
      // deleteBuildingListById(id, setData, pagination, setPagination);
      getAllCoReqCoursesList(pagination.page, search);
    };
  };

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllCoReqCoursesList(page + 1, search);
  };

  const goToUpdateBuilding = (id: number) => {
    navigate(`${siteRoutes.createBuilding}?id=${id}`);
  };

  const getAllCoReqCoursesList = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    // setData([]); // temporary set due to page get prev page some items
    GetCoRequsiteCourses(setData, queryParams, setPagination);
  }

  useEffect(() => {
    getAllCoReqCoursesList(pagination.page, search);
  }, [pagination.page, search]);


  return (
    <CoReqListingMain>
      <CoReqListingTop>
        <div className="left">
          <span className="page-heading">Co Requisite Courses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.academicTemplateCoursesCoReqCreate) && (
            <div className="create-org-btn">
              <button className="lg-rounded-btn" onClick={AddCoReqCourses}>
                + Add Co Req Course
              </button>
            </div>
          )}
        </div>
      </CoReqListingTop>
      <CoReqListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {hasAccess(sitePermissions.downloadBuildingListPdf) && (
              <div className="export-btn">
                <span>
                  <PdfSvg className="icon" />
                </span>
                <span className="text">PDF</span>
              </div>
            )}

            {hasAccess(sitePermissions.downloadBuildingListExcel) && (
              <div className="export-btn">
                <span>
                  <ExcelSvg className="icon" />
                </span>
                <span className="text">Excel</span>
              </div>
            )}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data && data?.length !== 0 ? (
                data.map((item: any, index: number) => (
                  <tr key={item?.id | index}>
                    <td>{item?.campus ?? "-"}</td>
                    <td>
                      <div className="mw-150">{item?.title}</div>
                    </td>
                    <td>{item?.building_length ?? "-"}</td>
                    <td>{item?.building_width ?? "-"}</td>
                    <td>{item?.latitude ?? "-"}</td>
                    <td>{item?.longitude ?? "-"}</td>
                    <td>
                      <div className="mw-150">{item?.short_name}</div>
                    </td>
                    <td>
                      <div className="table-action-icons">
                        {hasAccess(sitePermissions.academicTemplateCoursesCoReqUpdate) && (
                          <div
                            className="action-icon"
                            onClick={() => goToUpdateBuilding(item?.id)}
                          >
                            <EditTableSvg />
                          </div>
                        )}
                        {hasAccess(sitePermissions.deleteBuilding) && (
                          <div
                            className="action-icon"
                            onClick={() => handleDelete(item?.id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}>
                    <DataNotFound show={!isLoading && !data.length} />
                  </td>
                </tr>
              )}

            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination {...pagination} onPageChange={onPageChange} />
          {openModal && (
            <TemplateCourseCoReqCreate setOpen={setOpenModal} headerId={params?.id} />
          )}
        </Fragment>
      </CoReqListingSection>
    </CoReqListingMain >
  );
};

export default TemplateCoursesCoReqListing;
