import styled, { css } from "styled-components";

export const SidebarMain = styled.div<{showIconedMenu: boolean}>`
  width: ${({showIconedMenu}) => showIconedMenu ? '50px' : '280px'};
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({showIconedMenu}) => showIconedMenu ? '0' : '2em'};
  background: var(--white-color);
`;

export const Menus = styled.div<{showIconedMenu: boolean}>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: .5em;
  padding: ${({showIconedMenu}) => showIconedMenu ? '0 0 2em 0' : '0 1em 2em 0'};
  /* box-shadow: 4px 0px 15px 0px #0000001a; */

  .particular-menu {
    display: flex;
    flex-direction: column;
    gap: .5em;
  }
`;

export const MenuItem = styled.div<{
  active: boolean;
  isDropdownOpen: boolean;
  isSidebarOpened: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .link-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5em;
    background: ${({active}) => active ? 'var(--primary)' : 'transparent'};
    padding: .5em 1em;
    border-radius: 4px;

    .link{
      display: flex;
      align-items: center;
      gap: 1em;
    }
  }

  .menu-icon,
  .dropdown-icon {
    .icon {
      path {
        fill: ${({ active }) =>
          active ? "var(--white-constant)" : "var(--lightgray-medium)"};
      }
    }
  }

  .menu-icon{
    margin-bottom: .1em;
    width: 18px;
    height: 18px;

    .icon{
      width: 100%;
      height: 100%;
    }
  }

  .dropdown-icon {
    width: 7px;

    .icon{
      width: 15px;
      height: 15px;
      margin-top: ${({isDropdownOpen}) => isDropdownOpen ? '0' : '.5em'};

    rotate: ${({ isDropdownOpen }) => (isDropdownOpen ? "180deg" : "90deg")};
    }
  }

  .menu-text {
    font-size: 16px;
    font-weight: 500;
    color: ${({ active }) =>
      active ? "var(--white-constant)" : "var(--lightgray-medium)"};
  }
`;

export const DropdownMenu = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  gap: .5em;
  padding-left: 2em;
`;

export const DropdownMenuItem = styled.div<{ active: boolean }>`
  width: 100%;
  background: ${({active}) => active ? 'var(--primary)' : 'transparent'};
  padding: .5em 1em;
  border-radius: 4px;
  cursor: pointer;
  .menu-text {
    font-size: 16px;
    font-weight: 500;
    color: ${({ active }) =>
      active ? "var(--white-constant)" : "var(--lightgray-medium)"};
  }
`;

export const SiteLogo = styled.div`
  padding: 1.2em 1em 0 1em;
  display: flex;
  
  .org-logo{
    max-width: 220px;

    img{
      width: 100%;
      height: 100%;
    }
  }
`;

export const IconedMenu = styled.div<{active: boolean}>`
  width: 100%;
  cursor: pointer;

  .menu-icon{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({active}) => active ? 'var(--primary)' : 'transparent'};

    svg{
      width: 20px;
      height: 20px;

      path{
        fill: ${({active}) => active ? 'var(--white-constant)' : 'var(--lightgray-medium)'};
      }
    }
  }
`;

export const ShortLogo = styled.div`
  width: 30px;
  margin: 0 auto;
  padding-top: 1em;

  img, svg{
    width: 100%;
    padding-bottom: 1em;
  }
`;