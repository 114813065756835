import { useState, useEffect, useRef } from "react";

const useMultiClickOutside = (initialOpenedIndex: number | null = null) => {
    const [currentIndex, setCurrentIndex ] = useState<number | null>(initialOpenedIndex);
    const currentIndexRef = useRef<number | null>(initialOpenedIndex);
    const refs = useRef<any[]>([]);

    const handleClickOutside = (event: any) => {  
        const currentIndex = currentIndexRef.current;      
        if (currentIndex !== null && refs.current[currentIndex as number] && !refs.current[currentIndex as number].contains(event.target)) {
            setCurrentIndex(null);
        }
    };

    const setOpen = (index: number | null) => {
        if(currentIndex === index){
            setCurrentIndex(null);
        }else{
            setCurrentIndex(index);
        }
        currentIndexRef.current = index;
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return { refs, currentIndex, setOpen };
}

export default useMultiClickOutside;