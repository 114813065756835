export class AddQualificationDTO {
    id!: number;
    certificate_level_id: string = '';
    certificate_type_id: string = '';
    board_id: string = '';
    result_type_id: string = '';
    roll_num: string = '';
    passing_year: string = '';
    reg_num: string = '';
    total_marks !: number;
    obt_marks !: number;
    result_document: any;
    subject_results: SubjectResultDTO[] = [];
}

export class SubjectResultDTO{
    subject_id !: number;
    obt_marks : number = 0;
    total_marks : number = 0;
    required_marks!: boolean;

    constructor(data = {}){
        Object.assign(this, data);
    }
}