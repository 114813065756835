import styled from "styled-components";

export const AdminDashboarMain = styled.div`
  width: 100%;
`;

export const ADashboardTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }
`;
export const ADashboradSection = styled.div`
  .top-cards-container {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  .top-cards {
    background-color: var(--white-color);
    padding: 1em;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 20px;
    align-items: center;
    width: 32%;

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 51px;
    padding: 18px;
  }
  .title {
    font-size: 15px;
    color: var(--black-text);
    font-weight: 600;
    
    padding-bottom: 0.5em;
  }
  .title-value {
    color: var(--lightgray-medium);
    font-size: 25px;
    font-weight: 400;
    margin-right: 22px;
  }
  .title-value2 {
    color: var(--lightgray-medium);
    font-size: 13px;
    font-weight: 400;
  }
  .card-title-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .bottom-title {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .line-graph-container {
    margin-top: 2em;
    display: flex;
    gap: 3em;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .sub-heading {
    font-size: 30px;
    color: var(--lightgray-light);
    font-weight: 400;
  }
  .primary {
    color: var(--primary);
  }
`;
export const LinkSection = styled.div`
  margin-top: 2rem;
  padding: 2em 1em;
  .link-heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-medium);
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
  .link-card-container {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    /* width: 24%; */
    border-radius: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    width: 24%;
    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }
  .link-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--black-text);
  }
  .total-amount{
    color: var(--black-text);
    font-weight: 500;
  }
`;
export const AllApplicantsSection = styled.div`
  margin-top: 2em;
  padding: 1em;
  .link-heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-medium);
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;
export const RegisteredSection = styled.div`
  margin-top: 2em;
  padding: 1em;
  .link-heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-text);
    border-bottom: 2px solid var(--lightgray-medium);
  }
  .link-main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }
  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;
export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 1em;
  margin-top: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2em;
  transition: all 2s ease-in-out;

  .filter-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const FilterHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .filter-heading {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid var(--lightgray-extralight);
    color: var(--black-text);

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }
  .icon-main {
    display: flex;
    justify-content: end;
  }

  .toggle-icon {
    width: 7%;
    cursor: pointer;
  }
`;
