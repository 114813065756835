import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useEffect, useState } from "react";
import {
  CreatePlanOfStudyForm,
  CreatePlanOfStudyMain,
  CreatePlanOfStudyTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useAcademics from "../../useHooks";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { GradeTemplateDTO } from "utils/helpers/models/academics/grade-template.dto";
import useOrganization from "containers/private/organization/useHooks";
import { ResultTemplateDTO } from "utils/helpers/models/academics/result-template.dto";
import { warningToaster } from "utils/helpers/common/alert-service";

export const CreateResultSettingTemplate: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "Result Setting Template  / ",
      path: siteRoutes.resultSettingTemplateListing,
    },
    {
      title: "Add Result Setting Template",
      path: siteRoutes.createResultSettingTemplateListing,
    },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<ResultTemplateDTO>();
  let [formData, setFormData] = useState<ResultTemplateDTO>(
    new ResultTemplateDTO()
  );
  const [programs, setPrograms] = useState<any[]>([]);

  const [total_marks_distribution, setTotal_marks_distribution] =
    useState<string>("");

  const {
    createResultTemplate,
    updateResultTemplate,
    getResultTemplateById,
    getResultTemplateHeader,
  } = useAcademics();
  const { getPrograms } = useOrganization();
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  // const onSubmit = () => {
  //   if (params?.id) {
  //     updateResultTemplate(params?.id, formData);
  //   } else {
  //     createResultTemplate(formData);
  //   }
  // };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });

    // Track changes to the distribution criteria field
    if (name === "total_marks_distribution") {
      setTotal_marks_distribution(value);
    }
  };

  const resetForm = () => {
    formData = new ResultTemplateDTO();
    for (let key in formData) {
      setValue(
        key as keyof ResultTemplateDTO,
        formData[key as keyof ResultTemplateDTO]
      );
    }

    setFormData({ ...formData });
    setTotal_marks_distribution(""); // Reset the criteria state
  };

  useEffect(() => {
    if (params?.id) {
      getResultTemplateById(params?.id, formData, setValue, setFormData);
    }
    getResultTemplateHeader(setHeader);
  }, []);

  const [header, setHeader] = useState<any[]>([]);
  console.log(formData);

  const onSubmit = () => {
    if (
      Number(formData.theory_percentage) > 100 ||
      Number(formData.practical_percentage) > 100
    ) {
      warningToaster(
        "Theory % and Practical % values cannot be greater than 100."
      );
      return;
    }

    if (params?.id) {
      updateResultTemplate(params?.id, formData);
    } else {
      createResultTemplate(formData);
    }
  };

  return (
    <CreatePlanOfStudyMain>
      <CreatePlanOfStudyTop>
        <div className="left">
          <span className="page-heading">Add Result Setting Template</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreatePlanOfStudyTop>

      <CreatePlanOfStudyForm
        className="content-radius-shadow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="common-fields">
          <div className="input-field">
            <label>Template Header</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("template_header_id", { required: true })}
                  value={formData.template_header_id}
                  onChange={handleChange}
                >
                  <option value="">Select Template Header</option>
                  {header?.map((item: any) => {
                    return <option value={item?.id}>{item?.title}</option>;
                  })}
                </select>
              </div>
              <FormErrorMessage error={errors.template_header_id} />
            </div>
          </div>
          <div className="input-field">
            <label>Distribution Criteria</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("total_marks_distribution", { required: true })}
                  value={formData?.total_marks_distribution}
                  onChange={handleChange}
                  name="total_marks_distribution"
                >
                  <option value="">Select Distribution Criteria</option>
                  <option value="Percentage">Percentage</option>
                  <option value="Credit Hours">Credit Hours</option>
                </select>
              </div>
              <FormErrorMessage error={errors.total_marks_distribution} />
            </div>
          </div>
          {total_marks_distribution === "Percentage" && (
            <>
              <div className="input-field">
                <label>Theory %</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="number"
                      {...register("theory_percentage", { required: true })}
                      value={formData.theory_percentage}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.theory_percentage} />
                </div>
              </div>
              <div className="input-field">
                <label>Practical %</label>
                <div className="field-wrap">
                  <div className="field">
                    <input
                      type="number"
                      {...register("practical_percentage", { required: true })}
                      value={formData.practical_percentage}
                      onChange={handleChange}
                    />
                  </div>
                  <FormErrorMessage error={errors.practical_percentage} />
                </div>
              </div>
            </>
          )}

          <div className="input-field">
            <label>Theory Passing %</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("theory_passing_percentage", { required: true })}
                  value={formData.theory_passing_percentage}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.theory_passing_percentage} />
            </div>
          </div>
          <div className="input-field">
            <label>Practical Passing %</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="number"
                  {...register("practical_passing_percentage", {
                    required: true,
                  })}
                  value={formData.practical_passing_percentage}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.practical_passing_percentage} />
            </div>
          </div>
          <div className="input-field">
            <label>Is Generic</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("is_generic", { required: true })}
                  value={formData.is_generic}
                  onChange={handleChange}
                >
                  <option value="">Select Is Generic</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <FormErrorMessage error={errors.is_generic} />
            </div>
          </div>
          <div className="input-field">
            <label>Theory Credit Hours</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("credit_hours_theory", { required: true })}
                  value={formData.credit_hours_theory}
                  onChange={handleChange}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <FormErrorMessage error={errors.credit_hours_theory} />
            </div>
          </div>
          <div className="input-field">
            <label>Practical Credit Hours</label>
            <div className="field-wrap">
              <div className="field">
                <select
                  {...register("credit_hours_practical", { required: true })}
                  value={formData.credit_hours_practical}
                  onChange={handleChange}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <FormErrorMessage error={errors.credit_hours_practical} />
            </div>
          </div>
        </div>
        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            <button className="lg-rounded-btn">Submit</button>
          </div>
        </div>
      </CreatePlanOfStudyForm>
    </CreatePlanOfStudyMain>
  );
};

export default CreateResultSettingTemplate;
