export class AddMeritFormulaDTO {
  title: string = "";
  merit: string = "";
  is_active!: number;
  // merit_keys: MeritKey[] = [];
  merit_weighted_data: WeightedData[] = [];
}

// export class MeritKey {
//   title: string = "";
//   id!: number;
// }

export class WeightedData {
  certificate_level_id: string = "";
  result_type_id: any = "";
  is_hafiz: number = 0;
  is_hafiz_value: number = 0;
  is_test: number = 0;
  certificate_level_weighted: number = 1;
  test_weighted: number = 1;
  options: string[] = [];
}
