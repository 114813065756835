import styled from "styled-components";

export const OrgSTListingMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
  .column-main {
    display: flex;
    justify-content: between;
    align-items: between;
  }
  .table-arrow-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }
`;

export const OrgSTListingTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 860px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 860px) {
      width: 100%;
    }
  }

  .right {
    @media screen and (max-width: 860px) {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;

export const FilterSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em 3em;

  @media screen and (max-width: 630px) {
    padding: 1em 1.5em;
  }

  .css-13cymwt-control,
  .css-13cymwt-control:focus {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: none !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: var(--input-field-bg);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    height: 50px;
    border: 1px solid var(--field-border);
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2em;
  transition: all 2s ease-in-out;

  .filter-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
  .submit-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const FilterHeader = styled.div<{ showFilterDropdown: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .filter-heading {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 2px solid var(--lightgray-extralight);
    color: var(--black-text);

    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
  }

  .dropdown-arrow {
    .icon {
      width: 20px;
      height: 28px;
      rotate: ${({ showFilterDropdown }) =>
        showFilterDropdown ? "0" : "180deg"};
      transition: all 0.1s ease-in-out;

      path {
        fill: var(--lightgray-medium);
      }

      @media screen and (max-width: 480px) {
        width: 18px;
        height: 24px;
      }
    }
  }
`;
export const OrgSTListingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }

  .d-none {
    display: none;
  }

  .list-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      gap: 1em;
    }
  }

  table td {
    .fallback-logo {
      width: 38px;
      height: 35px;
      background: var(--lightgray-extralight);
    }

    .org-logo {
      width: 38px;
      height: 35px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .mw-150 {
      min-width: 150px;
    }
  }
`;
