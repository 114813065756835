
import { FC, Fragment, useEffect, useState } from "react";

import {
  PreReqListingMain,
  PreReqListingSection,
  PreReqListingTop,
} from "./style";

import {
  DeleteTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";

import useAcademics from "../../useHooks";

import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

import Breadcrumb from "components/particles/breadcrumb";
import useStore from "hooks/useStore";
import Pagination from "components/particles/table/pagination";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useUtils from "hooks/useUtils";
import TemplateCoursePreReqCreate from "../create";

interface CorequestProps { }

const columns: string[] = [
  "pre requsite courses listing",
  "pre requsite courses listing",
  "pre requsite courses listing",
  "pre requsite courses listingddd",
  "pre requsite courses listing",
];

const TemplateCoursePreReqListing: FC<CorequestProps> = ({ }) => {

  const breadcrumbLinks = [
    { title: "Academics /", path: "" },
    { title: "Template Course /", path: siteRoutes.academicTemplateCoursesListing },
    { title: "Pre Requsite Course Listing", path: siteRoutes.academicTemplateCoursesPreReqListing },
  ];

  const { confirmationPopup } = useAlert();
  const { hasAccess } = useStore();
  const { getQueryParams } = useUtils();
  const params = getQueryParams();
  const {
    GetPreRequsiteCourses,
  } = useAcademics();

  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<any>([])
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });

  const goToAddPreReqCourses = () => {
    setOpenModal(true);
  };

  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const handleDelete = async (id: number) => {
    const result = await confirmationPopup();
    console.log(result);

    if (result.isConfirmed) {
      // deletePreReqCoursesItemById(id, setData, pagination, setPagination);
      GetPreReqCoursesList(pagination.page, search);
    };
  };

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    GetPreReqCoursesList(page + 1, search);
  };

  const GetPreReqCoursesList = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
    };
    GetPreRequsiteCourses(setData, queryParams, setPagination);
  }

  useEffect(() => {
    GetPreReqCoursesList(pagination.page, search);
  }, [pagination.page, search]);


  return (
    <PreReqListingMain>
      <PreReqListingTop>
        <div className="left">
          <span className="page-heading">Pre Requsite Courses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          {hasAccess(sitePermissions.academicTemplateCoursesPreReqCreate) && (
            <div className="create-org-btn">
              <button className="lg-rounded-btn" onClick={goToAddPreReqCourses}>
                + Add Pre Req Courses
              </button>
            </div>
          )}
        </div>
      </PreReqListingTop>
      <PreReqListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            {/* {hasAccess(sitePermissions.downloadBuildingListPdf) && ( */}
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>
            {/* )} */}

            {/* {hasAccess(sitePermissions.downloadBuildingListExcel) && ( */}
            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
            {/* )} */}
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input type="search" placeholder="Search" />
          </div>
        </div>
        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data && data?.length !== 0 ? (
                !data.map((item: any, index: number) => (
                  <tr key={item?.id | index}>

                    <td>pre requisite courses</td>

                    <td>
                      <div className="table-action-icons">
                        {/* {hasAccess(sitePermissions.editBuilding) && ( */}
                        {/* <div
                          className="action-icon"
                        // onClick={() => goToUpdateBuilding(item?.id)}
                        >
                          <EditTableSvg />
                        </div> */}
                        {/* )} */}

                        {hasAccess(sitePermissions.academicTemplateCoursesPreReqDelete) && (
                          <div
                            className="action-icon"
                            onClick={() => handleDelete(item?.id)}
                          >
                            <DeleteTableSvg />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12}>
                    <DataNotFound show={!isLoading && !data.length} />
                  </td>
                </tr>
              )}

            </tbody>
          </table>
        </div>
        <Fragment>
          <Pagination {...pagination} onPageChange={onPageChange} />
          {openModal && (
            <TemplateCoursePreReqCreate setOpen={setOpenModal} headerId={params?.id} />
          )}
        </Fragment>
      </PreReqListingSection>
    </PreReqListingMain >
  );
};

export default TemplateCoursePreReqListing;
