import styled from "styled-components";

export const EportalDashboardMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  /* padding: 1em; */

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const EportalDashboardTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  @media screen and (max-width: 490px) {
    flex-direction: column;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }
`;

export const EportalFeatures = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24%, 1fr));
  gap: 1.5em;

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }

  .particular-feature {
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    padding: 1.5em 0;
    border-radius: 15px;
    background: var(--white-color);
    color: var(--black-text);
    cursor: pointer;

    .inner-content {
      display: flex;
      flex-direction: column;
      gap: 1em;
      align-items: center;
      font-size: 16px;
      font-weight: 500;

      @media screen and (max-width: 450px) {
        font-size: 14px;
      }

      .feature-icon {
        .icon {
          path {
            fill: var(--black-text);
          }
        }
      }
    }
  }
`;

export const EDContentSection = styled.div`
  width: 100%;
  /* padding: 2em;  */
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;

export const BasicInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border-top: none;

  .cover-part {
    width: 100%;
    height: 210px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    @media screen and (max-width: 490px){
      height: 160px;
    }
  }

  .info-section {
    width: 100%;
    background: var(--white-color);
    padding: 1em 1em 2em 1em;
    display: flex;
    justify-content: space-between;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    @media (max-width: 900px) {
      flex-wrap: wrap;
    }

    &-left {
      .profile-info {
        position: relative;
        .profile-image {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          padding: 0.1em;
          border-radius: 50%;
          position: absolute;
          top: -5em;
          @media (max-width: 490px) {
            left: 80px;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .user-name {
          color: var(--black-text);
          font-size: 32px;
          font-weight: 800;

          padding-left: 150px;
          @media (max-width: 900px) {
            font-size: 20px;
            padding-left: 0%;
            padding-top: 70px;
          }
          @media (max-width: 690px) {
            font-size: 18px;
            padding-left: 0%;
            padding-top: 70px;
          }
        }
      }
    }

    &-right {
      .buttons-part {
        display: flex;
        gap: 0.5em;
        button.w-auto {
          color: var(--extra-dark-gray) !important;
          .icon {
            padding-top: 0.3em;
            svg {
              width: 12px;
              height: 15px;
              rotate: 180deg;

              path {
                fill: var(--extra-dark-gray);
              }
            }
          }
        }
      }
    }
  }
`;

export const AdditionalInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5em;
  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .left-section {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    @media (max-width: 1000px) {
      width: 100%;
    }
    .quick-links-section {
      .quick-links-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
        gap: 1em;

        @media screen and (max-width: 960px) {
          grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        }

        .particular-link {
          box-shadow: 0px 0px 14px 0px #0000000d;
          display: flex;
          justify-content: center;
          padding: 1.5em 0;
          border-radius: 15px;
          background: var(--primary-extralight-transparent);
          /* background: var(--white-color) */
          color: var(--black-text);
          cursor: pointer;

          .inner-content {
            display: flex;
            flex-direction: column;
            gap: 1em;
            align-items: center;
            font-size: 16px;
            font-weight: 500;

            @media screen and (max-width: 450px) {
              font-size: 14px;
            }

            .feature-icon {
              .icon {
                path {
                  fill: var(--black-text);
                }
              }
            }
          }
        }
      }
    }
  }
  table.bottom-bordered-cells {
    th {
      font-size: 16px;
      font-weight: 500;
      color: var(--black-text);
      text-align: left;
      padding: 0.5em;
      border-bottom: 1px solid var(--lightgray-extralight);
      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }
  .section {
    width: 100%;
    background: var(--white-color);
    border-radius: 8px;
    padding: 0.5em 0 2em 0;
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    &-header {
      width: 100%;
      border-bottom: 1px solid var(--lightgray-extralight);
      padding: 0 1em 0.5em 1em;

      .heading {
        font-size: var(--font-24);
        font-weight: 500;
        color: var(--black-text);
        @media (max-width: 1024px) {
          font-size: 18px;
        }
      }
    }

    &-content {
      padding: 0 1em;
    }
  }

  .right-section {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    @media (max-width: 1000px) {
      width: 100%;
    }

    .upcoming-events-section {
      width: 100%;
      padding: 1em;
      .events-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        .particular-event {
        }
      }
    }
  }

  .event-top {
    display: flex;
    justify-content: space-between;
    .top-right {
      color: var(--black-text);
    }
    .top-left {
      color: var(--black-text);
    }
  }
  .shedule-heading {
    font-size: 24px;
    color: var(--black-text);
    font-weight: 500;
  }
  .shedule-container {
    display: flex;
    gap: 3em;
    margin-top: 2em;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .event-description-main {
    box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.2),
      2px 2px 10px rgba(0, 0, 0, 0.2), -2px 2px 10px rgba(0, 0, 0, 0.2);
    border-left: 3px solid var(--primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
    margin-block: 1em;
  }
  .times {
    font-size: 14px;
    font-weight: 400;
    color: var(--lightgray-medium);
    white-space: wrap;
  }
  .even-timings {
    display: flex;
    gap: 7px;
    svg {
      .fill {
        fill: var(--black-text);
      }
    }
  }
  .event-title {
    color: var(--black-text);
    font-size: 15px;
    font-weight: 500;
  }
  .participant-img {
    width: 60%;
  }
  .event-desctiption-container {
    width: 100%;
    margin-left: 15px;
  }
  .participant-img-container {
    display: flex;
    width: 50%;
    justify-content: end;
  }
  .no-records {
    margin-top: 10px;
    color: var(--black-text);
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const NoticeBoardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 1em 1.5em;
  .no-records {
    margin-top: 10px;
    color: var(--black-text);
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notice-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    /* flex-wrap: wrap; */

    .particular-notice {
      width: 100%;
      display: flex;
      gap: 1.5em;
      justify-content: space-between;
      align-items: start;
      /* flex-wrap: wrap; */

      .left {
        display: flex;
        gap: 1em;
        align-items: flex-start;
        /* flex-wrap: wrap; */
        .notice-thumbnail {
          width: 77px;
          height: 90px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .notice-info {
          display: flex;
          flex-direction: column;
          gap: 1em;

          .notice-title {
            font-size: 12px;
            font-weight: 500;
            color: var(--black-text);

            @media screen and (max-width: 500px) {
              font-size: 14px;
            }
          }

          .notice-description {
            color: var(--lightgray-light);
            font-size: 12px;
            font-weight: 400;

            @media screen and (max-width: 500px) {
              font-size: 13px;
            }
          }
        }
      }

      .right {
        .notice-issue-info {
          display: flex;
          flex-direction: column;
          color: var(--lightgray-light);
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .issuance-date {
    display: flex;
    justify-content: end;
    width: 100%;

    .date {
      font-weight: 400;
      font-size: 14px;
      color: var(--lightgray-light);
    }
  }
`;
