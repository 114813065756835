import { FC, Fragment, useState, useEffect } from "react";
import {
  DeleteTableSvg,
  EditTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  StudyPlanCourseListingMain,
  StudyPlanCourseListingSection,
  StudyPlanCourseListingTop,
  StudyPlanCourseListingStatsSection,
} from "./style";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import CreateCourseRoom from "./components/add-student";
import useAcademics from "../../useHooks";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import DataNotFound from "components/particles/table/data-not-found";
import { useSelector } from "react-redux";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";

const StudyPlanStudentListing: FC = () => {
  const breadcrumbLinks = [
    { title: "Academics /", path: "" },
    {
      title: "Plan of Studies / ",
      path: siteRoutes.academicPlanofStudies,
    },
    {
      title: "Plan of Study Students ",
      path: siteRoutes.planOfStudyStudentListing,
    },
  ];
  const { getPlanOfStudyById} = useAcademics();
  const navigate = useNavigate();
  const { hasAccess } = useStore();
  const { getQueryParams, handleSearchChange, handleTableSearch } = useUtils();
  const [data, setData] = useState<any[]>([]);
  const [planOfStudy, setPlanOfStudy] = useState<PlanOfStudyDTO>(new PlanOfStudyDTO());
  const { getPlanOfStudyStudents, deletePlanOfStudyStudent} = useAcademics();
  const { pos_id = ''} = getQueryParams();
  const { isLoading } = useSelector((state: any) => state.sharedReducer);

  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const columns: string[] = [
    "Name",
    "Registration No.",
    "CNIC",
    "Department",
    "Action",
  ];

  const goToAddStudents = () => {
    navigate(`${siteRoutes.addStudentsToPlanOfStudy}?pos_id=${pos_id}`)
  };

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
        pos_id
      };
      deletePlanOfStudyStudent(id, setData, queryParams, setPagination);
    }
  };

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllPlanOfStudyStudents(page + 1, search);
  };
  const getAllPlanOfStudyStudents = (page: number, search: string) => {
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      pos_id,
    };
    getPlanOfStudyStudents(setData, queryParams, setPagination);
  };

  useEffect(() => {
    getPlanOfStudyById(pos_id, planOfStudy, setPlanOfStudy);
    getAllPlanOfStudyStudents(pagination.page,search);
  }, []);

  return (
    <StudyPlanCourseListingMain>
      <StudyPlanCourseListingTop>
        <div className="left">
          <span className="page-heading">Plan of Study Students</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="download-list-button">
            <button className="lg-rounded-btn black">Upload File</button>
            {hasAccess(sitePermissions.addStudentsToPlanOfStudy) &&  <button className="lg-rounded-btn" onClick={goToAddStudents}>
              Add Student
            </button>}
          </div>
        </div>
      </StudyPlanCourseListingTop>
      <StudyPlanCourseListingStatsSection className="content-radius-shadow">
      <div className="stats">
          <div className="sats-item">
            <div className="stats-title">Title</div>
            <div className="stats-value">{planOfStudy?.title ?? '-'}</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Program</div>
            <div className="stats-value">{planOfStudy?.program?.title ?? '-'}</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Total Credit Hours (Theory-Lab)</div>
            <div className="stats-value">{+planOfStudy?.total_theory_credit_hours + +planOfStudy?.total_lab_credit_hours}({+planOfStudy?.total_theory_credit_hours}-{+planOfStudy?.total_lab_credit_hours})</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Status</div>
            <div className="stats-value">{planOfStudy?.is_validated ? <span className="status-tile green">Validated</span> : <span className="status-tile red">Not Validated</span>}</div>
          </div>
        </div>
      </StudyPlanCourseListingStatsSection>

      <StudyPlanCourseListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, getAllPlanOfStudyStudents)
              }
              onKeyUp={(e) => handleTableSearch(e, getAllPlanOfStudyStudents)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((student,index) => {
                return <tr key={index}>
                <td>
                  <div className="mw-150">
                    {student?.user?.name ?? '-'}
                  </div>
                </td>
                <td>
                  {student?.reg_number ?? '-'}
                </td>
                <td>
                  <div className="mw-150">{student?.user?.cnic ?? '-'}</div>
                </td>
                <td>
                  <div className="mw-150">{student?.department?.title}</div>
                </td>

                <td>
                    <div className="table-action-icons">
                      {hasAccess(sitePermissions.deletePlanOfStudyStudents) && <div className="action-icon" onClick={() => handleDelete(student.id)}>
                        <DeleteTableSvg />
                      </div>}
                  </div>
                </td>
              </tr>
              })}
            </tbody>
          </table>
        </div>

          <Fragment>
            <DataNotFound show={!isLoading && !data.length}/>
            <Pagination
              onPageChange={onPageChange}
              {...pagination}
            />
          </Fragment>
      </StudyPlanCourseListingSection>
    </StudyPlanCourseListingMain>
  );
};

export default StudyPlanStudentListing;
