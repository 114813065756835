import useStore from "hooks/useStore";
import { ADMIN_APIS } from "libs/apis/admin.api";
import { useNavigate } from "react-router-dom";
import { successToaster } from "utils/helpers/common/alert-service";
import { ROLES } from "utils/helpers/enums/roles.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { LoginFormDTO } from "utils/helpers/models/auth/login";

interface UseAuthReturnType {
  adminLogin: (body: LoginFormDTO) => void;
}

const useAdmin = (): UseAuthReturnType => {
  // const { postRequest } = useHttp();
  const { setToken, setUser, setPermissions, setOrganization, setRole, setLastLoggedInUserRole } =
    useStore();
  const navigate = useNavigate();

  const adminLogin = async (body: LoginFormDTO) => {
    const response = await ADMIN_APIS.adminLogin(body);
    if (response?.status) {
      setOrganization(response?.organization);
      setRole(response?.data?.roles[0]);
      delete response?.data?.role_id;
      delete response?.data?.roles;
      setUser(response?.data);
      setToken(response?.token);
      setLastLoggedInUserRole(ROLES.ADMIN);
      setPermissions(response?.permissions[0]?.permissions);
      navigate(siteRoutes.adminDashboard, { replace: true });
      successToaster(response?.message);
    }
  };
  return {
    adminLogin,
  };
};

export default useAdmin;
