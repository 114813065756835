import { FC, useState } from "react"
import { AddAdmissionApplicationDTO } from "utils/helpers/models/e-portal/add-admission-application.dto"
import { ContentSection, InfoField, ViewStudentApplicationMain } from "./style"
import useUtils from "hooks/useUtils";
import { CloseMediumSvg } from "assets/images/common/svgs";
import useEportal from "containers/private/e-portal/useHooks";
import { confirmationPopup } from "utils/helpers/common/alert-service";

interface ViewStudentApplicationProps{
    application: any;
    setOpen: Function;
    setApplications:any
}

const ViewStudentApplication: FC<ViewStudentApplicationProps> = ({application, setOpen}) => {
    const {getDateFromDateTime} = useUtils();
    const { admissionDownloadApplication, admissionGenerateFeeChallan , deleteUserAdmissionApplication,} = useEportal();

    const downloadApplication = () => {
        admissionDownloadApplication(application.app_no);
      }
 
      
    
      const downloadVoucher = () => {
        admissionGenerateFeeChallan(application.app_no);
      }
      const handleDeleteApplication = async (appId: number) => {
        const result = await confirmationPopup();
        if(result.isConfirmed){
          deleteUserAdmissionApplication(appId);
          setOpen(false)
        }
      }
    
      
    return(
        <ViewStudentApplicationMain>
            <ContentSection>
                <div className="header-section">
                    <div className="heading">
                        <span>Application Details</span>
                    </div>
                    <div className="close-icon" onClick={() => setOpen(false)}>
                        <CloseMediumSvg />
                    </div>
                </div>
                <div className="info-wrapper">

              <InfoField gray>
                <span className="title">Application No.</span>
                <span className="info">{application?.app_no}</span>
              </InfoField>
              <InfoField gray>
                <span className="title">Program</span>
                <span className="info">{application?.program}</span>
              </InfoField>
              <InfoField gray>
                <span className="title">Applied Date</span>
                <span className="info">{getDateFromDateTime(application?.app_date)}</span>
              </InfoField>
              <InfoField gray>
                <span className="title">Name</span>
                <span className="info">{application?.name}</span>
              </InfoField>
              <InfoField gray>
                <span className="title">CNIC</span>
                <span className="info">{application?.cnic}</span>
              </InfoField>
                </div>

                <div className="action-buttons">
                    <div className="particular-button" onClick={downloadApplication}>
                        <button>Download Application</button>
                    </div>
                    {application?.p_fee_template_link && <div className="particular-button" onClick={downloadVoucher}>
                        <button className="green">Download Fee Challan</button>
                    </div>}
                    <div className="particular-button" onClick={() => handleDeleteApplication(application?.id)}>
                        <button className="red">Delete Application</button>
                    </div>
                </div>
            </ContentSection>
        </ViewStudentApplicationMain>
    )
}

export default ViewStudentApplication;