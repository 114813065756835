import { FC, Fragment, useState } from "react";
import { ContactContainerMain, LoginForm, RightSideContainer, SiteLogo, StudentLoginMain } from "./style";
import {
  CyfyLogoDark,
  CyfyLogoSvg,
  EyeHidingSvg,
  EyeSvg,
} from "assets/images/common/svgs";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import FormErrorMessage from "components/particles/forms/form-error-message";
import useEportalAuth from "../useHooks";
import { useSelector } from "react-redux";
import { EportalLoginDTO } from "utils/helpers/models/e-portal/eportal-login-dto";
import InputMask from "react-input-mask";
import LoginPageImage from "../../../../assets/images/e-portal/auth/login-cover.png"

export const ContactInformation = () => {
  const { organization } =
    useSelector((state: any) => state.sharedReducer) || {};
  return (
    <ContactContainerMain>
      <div className="contact_heading">Got a question or issue ?</div>
      <div className="contact_text">Got a question or issue? Please contact our Admission Officer at <span className="link-text bold">{organization?.phone}</span>  (Call , WhatsApp) or <span className="link-text bold">{organization?.email}</span> for assistance.</div>
    </ContactContainerMain>
  )
}

interface StudentLoginProps { }
const EPortalLogin: FC<StudentLoginProps> = ({ }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<EportalLoginDTO>(
    new EportalLoginDTO()
  );
  const { organization } =
    useSelector((state: any) => state.sharedReducer) || {};
  const navigate = useNavigate();
  const { userLogin } = useEportalAuth();
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, touchedFields },
  } = useForm<EportalLoginDTO>();
  const { isDarkTheme, isLoading } = useSelector(
    (state: any) => state.sharedReducer
  );

  const onSubmit = () => {
    userLogin(formData);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(name, value);
    trigger(name);
    if (name === "login_type") {
      if (value === "cnic") {
        setValue("passport_no", "");
        setFormData({ ...formData, [name]: value, passport_no: "" });
      } else {
        setValue("cnic", "");
        setFormData({ ...formData, [name]: value, cnic: "" });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  return (
    <StudentLoginMain className="p-custom-scrollbar-8">
      {/* <LoginForm className="" onSubmit={handleSubmit(onSubmit)}> */}
      <LoginForm className="content-radius-shadow" onSubmit={handleSubmit(onSubmit)} aria-disabled={isLoading}>

        {organization && (
          <SiteLogo>
            <img
              src={organization?.sidebar_dark_logo}
              alt="logo"
            />
          </SiteLogo>
        )}

        <div className="form-header">
          {/* <span className="heading">Welcome Back!</span> */}
          <span className="sub-heading">
            Enter your CNIC / Passport No. and password to login
          </span>
        </div>

        <div className="fields">
          {/* Radio Buttons for Login Type */}
          <div className="radio-field">
            <div className="field-wrap">
              <div className="field">
                <input
                  type="radio"
                  id="cnic"
                  {...register("login_type", { required: true })}
                  value="cnic"
                  onChange={handleChange}
                  checked={formData.login_type === "cnic"}
                />
                <label htmlFor="cnic">Login By CNIC</label>
              </div>
              <div className="field">
                <input
                  type="radio"
                  id="passport"
                  {...register("login_type", { required: true })}
                  value="passport"
                  onChange={handleChange}
                  checked={formData.login_type === "passport"}
                />
                <label htmlFor="passport">Login By Passport</label>
              </div>
            </div>
          </div>

          {/* CNIC or Passport Field */}
          {formData.login_type === "cnic" ? (
            <div className="input-field">
              <div className="field-wrap">
                <div className="field">
                  <InputMask
                    mask="99999-9999999-9"
                    {...register("cnic", { required: true })}
                    placeholder="CNIC / B.Form"
                    value={formData.cnic}
                    onChange={handleChange}
                  >
                    {(inputProps) => <input type="text" {...inputProps} />}
                  </InputMask>
                </div>
              </div>
            </div>

          ) : (
            <div className="input-field">
              <div className="field-wrap">
                <div className="field">
                  <input
                    type="text"
                    {...register("passport_no", { required: true })}
                    onChange={handleChange}
                    placeholder="Passport"
                  />
                </div>
              </div>
            </div>
          )}


          <div className="input-field">
            <div className="field-wrap">
              <div className="field">
                <input
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  {...register("password", { required: true })}
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
                <span
                  className="field-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSvg className="password-icon" /> : <EyeHidingSvg className="password-icon" />}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-options">
          <div className="remember-me">
            <input type="checkbox" id="rememberMe" {...register("rememberMe")} />
            <label htmlFor="rememberMe">Remember Me</label>
          </div>
          <span className="link-text" onClick={() => navigate(siteRoutes.ePortalResetPassword)}>Forget Password?</span>
        </div>

        <div className="submit-button">
          <button type="submit" disabled={isLoading}>
            {isLoading ? <div className="loader">
              <div className="sm-w-rounded-loader"></div>
            </div> : "Sign In"}
          </button>
        </div>

        <div className="bottom-text">
          <span>Don't have an account?</span>
          <span className="link-text" onClick={() => navigate(siteRoutes.ePortalRegister)}>Register here!</span>
        </div>

        <div className="bottom_contact_container">
          <ContactInformation />
        </div>
        {/* <div className="contact-text-container contact_bottom">
          <div className="contact_heading">Got a question or issue?</div>
          <div className="contact_text">Got a question or issue? Please contact our Admission Officer at <span className="link-text bold">{organization?.phone}</span>  (Call , WhatsApp) or <span className="link-text bold">{organization?.email}</span> for assistance.</div>
        </div> */}
      </LoginForm>

      <RightSideContainer>
        {organization?.login_page_image && (<div className="sideImg">
          <img src={organization?.login_page_image} alt="" />
        </div>
        )}
        <ContactInformation />
      </RightSideContainer>
    </StudentLoginMain >

  );
};

export default EPortalLogin;
