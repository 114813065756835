import { FC, Fragment, useState, useEffect } from "react";
import {
  DeleteTableSvg,
  ExcelSvg,
  PdfSvg,
  SearchFieldSvg,
} from "assets/images/common/svgs";
import Pagination from "components/particles/table/pagination";
import Breadcrumb from "components/particles/breadcrumb";
import {
  StudyPlanCourseListingMain,
  StudyPlanCourseListingSection,
  StudyPlanCourseListingTop,
  StudyPlanCourseListingStatsSection,
} from "./style";

import useUtils from "hooks/useUtils";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { useNavigate } from "react-router-dom";
import CreateCourseRoom from "./components/create-study-plan-course";
import useAcademics from "../useHooks";
import { useSelector } from "react-redux";
import useStore from "hooks/useStore";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { PlanOfStudyCourseDTO } from "utils/helpers/models/academics/plan-of-study-course.dto";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import DataNotFound from "components/particles/table/data-not-found";
import { CoursesListingSection } from "containers/private/e-portal/short-courses/listing/style";

const StudyPlanCourseListing: FC = () => {
  const breadcrumbLinks = [
    { title: "Academics /", path: "" },
    {
      title: "Plan of Studies / ",
      path: siteRoutes.academicPlanofStudies,
    },
    {
      title: "Plan of Study Courses ",
      path: siteRoutes.planOfStudyCourseListing,
    },
  ];
  const columns: string[] = [
    "Semester",
    "Course Code",
    "Course Title",
    "Department",
    "Credit Hours",
    "Show On Transcript",
    "Action",
  ];
  const [openAddCourseModal, setopenAddCourseModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const [planOfStudy, setPlanOfStudy] = useState<PlanOfStudyDTO>(new PlanOfStudyDTO());
  const { getPlanOfStudyCourses, deletePlanOfStudyCourse, updatePlanOfStudyCourse, getPlanOfStudyById } = useAcademics();
  const [data, setData] = useState<PlanOfStudyCourseDTO[]>([]);
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
    totalRecords: 1,
  });
  const { handleSearchChange, handleTableSearch, getQueryParams } = useUtils();
  const params = getQueryParams();
  const [search, setSearch] = useState<string>("");

  const handleDelete = async (id: number) => {
    const response = await confirmationPopup();
    if (response.isConfirmed) {
      const queryParams = {
        page: 1,
        per_page: pagination.per_page,
      };
      deletePlanOfStudyCourse(id, setData, queryParams, setPagination, params?.pos_id, planOfStudy, setPlanOfStudy);
    }
  };

  const onPageChange = (pageInfo: { selected: number }) => {
    const { selected: page } = pageInfo;
    setPagination({ ...pagination, page: page + 1 });
    getAllPlanOfStudyCourses(page + 1, search);
  };
  const getAllPlanOfStudyCourses = (page: number, search: string) => {
    const { pos_id = null} = params;
    const queryParams = {
      per_page: pagination.per_page,
      page,
      search,
      pos_id
    };
    getPlanOfStudyCourses(setData, queryParams, setPagination);
  };


  const goToCreateCourse = () => {
    setopenAddCourseModal(true);
  };

  useEffect(() => {
    if(!openAddCourseModal){
      getAllPlanOfStudyCourses(pagination.page, search);
      getPlanOfStudyById(params.pos_id, planOfStudy, setPlanOfStudy);
    }
  }, [openAddCourseModal]);

  const handleSequenceChange = (event: any, index: number) => {
    const { value } = event.target;
    data[index].semester_seq_number = value;
    setData([...data]);
    updatePlanOfStudyCourse(data[index].id, data[index]);
  }

  const handleTranscriptChange = (course: PlanOfStudyCourseDTO, index: number) => {
    data[index] = course;
    setData([...data]);
    updatePlanOfStudyCourse(course.id, course);
  }

  return (
    <StudyPlanCourseListingMain>
      <StudyPlanCourseListingTop>
        <div className="left">
          <span className="page-heading">Plan of Study Courses</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
        <div className="right">
          <div className="download-list-button">
            <button className="lg-rounded-btn black">Validate POS</button>
            <button className="lg-rounded-btn" onClick={goToCreateCourse}>
              Add Course
            </button>
            <button className="lg-rounded-btn gray">Reset Result Cache</button>
            <button className="lg-rounded-btn red">Delete Bulck</button>
          </div>
        </div>
      </StudyPlanCourseListingTop>
      <StudyPlanCourseListingStatsSection className="content-radius-shadow">
        <div className="stats">
          <div className="sats-item">
            <div className="stats-title">Title</div>
            <div className="stats-value">{planOfStudy?.title ?? '-'}</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Program</div>
            <div className="stats-value">{planOfStudy?.program?.title ?? '-'}</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Total Credit Hours (Theory-Lab)</div>
            <div className="stats-value">{+planOfStudy?.total_theory_credit_hours + +planOfStudy?.total_lab_credit_hours}({+planOfStudy?.total_theory_credit_hours}-{+planOfStudy?.total_lab_credit_hours})</div>
          </div>
          <div className="sats-item">
            <div className="stats-title">Status</div>
            <div className="stats-value">{planOfStudy?.is_validated ? <span className="status-tile green">Validated</span> : <span className="status-tile red">Not Validated</span>}</div>
          </div>
        </div>
      </StudyPlanCourseListingStatsSection>

      <StudyPlanCourseListingSection className="content-radius-shadow">
        <div className="list-header">
          <div className="table-data-export-buttons">
            <div className="export-btn">
              <span>
                <PdfSvg className="icon" />
              </span>
              <span className="text">PDF</span>
            </div>

            <div className="export-btn">
              <span>
                <ExcelSvg className="icon" />
              </span>
              <span className="text">Excel</span>
            </div>
          </div>
          <div className="table-search-field">
            <span className="search-icon">
              <SearchFieldSvg className="icon" />
            </span>
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) =>
                handleSearchChange(e, setSearch, getAllPlanOfStudyCourses)
              }
              onKeyUp={(e) => handleTableSearch(e, getAllPlanOfStudyCourses)}
            />
          </div>
        </div>

        <div className="data-table">
          <table className="bottom-bordered-cells">
            <thead>
              <tr>
                {columns.map((column: string, index: number) => {
                  return <th key={index}>{column}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((course, index) => {
                return <tr key={index}>
                  <td>
                    {course.semester_seq_number}
                  </td>
                  <td>
                    {course.template_course.tc_code}
                  </td>
                  <td>
                    <div className="mw-150">{course?.template_course?.tc_title ?? '-'}</div>
                  </td>
                  <td>
                    <div className="mw-150">{course?.template_course?.department?.title ?? '-'}</div>
                  </td>
                  <td>
                  {course.template_course?.tc_credit_hours + course?.template_course?.tc_lab_credit_hours}({course.template_course?.tc_credit_hours}-{course?.template_course?.tc_lab_credit_hours})
                  </td>
                  <td>
                    <div className="table-radio-field">
                      <div className="radio">
                        <input type="radio" checked={course.show_on_transcript == 1} onChange={() => handleTranscriptChange({ ...course, show_on_transcript: 1 }, index)} />
                        <label>Yes</label>
                      </div>
                      <div className="radio">
                        <input type="radio" checked={course.show_on_transcript == 0} onChange={() => handleTranscriptChange({ ...course, show_on_transcript: 0 }, index)} />
                        <label>No</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="table-action-icons">
                      <div className="action-icon" onClick={() => handleDelete(course.id)}>
                        <DeleteTableSvg />
                      </div>
                    </div>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>

        <Fragment>
          <DataNotFound show={!isLoading && !data.length} />
          <Pagination
            onPageChange={onPageChange}
            {...pagination}
          />

          {openAddCourseModal && (
            <CreateCourseRoom setOpen={setopenAddCourseModal} />
          )}
        </Fragment>
      </StudyPlanCourseListingSection>
    </StudyPlanCourseListingMain>
  );
};

export default StudyPlanCourseListing;
