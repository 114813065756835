import Breadcrumb, { BreadcrumbLink } from "components/particles/breadcrumb";
import { FC, useEffect, useState } from "react";
import {
  CreatePlanOfStudyForm,
  CreatePlanOfStudyMain,
  CreatePlanOfStudyTop,
} from "./style";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useAcademics from "../../useHooks";
import { PlanOfStudyDTO } from "utils/helpers/models/academics/plan-of-study.dto";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";
import { GradeTemplateDTO } from "utils/helpers/models/academics/grade-template.dto";
import useOrganization from "containers/private/organization/useHooks";
import { ResultTemplateHeaderDTO } from "utils/helpers/models/academics/resault-template-header.dto";

export const CreateTemplateHeader: FC = () => {
  const breadcrumbLinks: BreadcrumbLink[] = [
    { title: "Academics / ", path: siteRoutes.academicSessionListing },
    {
      title: "Template Header / ",
      path: siteRoutes.templateHeaderListing,
    },
    {
      title: "Add Template Header",
      path: siteRoutes.createTemplateHeader,
    },
  ];

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<ResultTemplateHeaderDTO>();
  let [formData, setFormData] = useState<ResultTemplateHeaderDTO>(
    new ResultTemplateHeaderDTO()
  );
  const [programs, setPrograms] = useState<any[]>([]);

  const {
    createResultTemplateHeader,
    updateResultTemplateHeader,
    getResultTemplateHeaderById,
  } = useAcademics();

  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const onSubmit = () => {
    if (params?.id) {
      updateResultTemplateHeader(params?.id, formData);
    } else {
      createResultTemplateHeader(formData);
    }
  };

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setValue(name, value);
    trigger(name);
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    formData = new ResultTemplateHeaderDTO();
    for (let key in formData) {
      setValue(
        key as keyof ResultTemplateHeaderDTO,
        formData[key as keyof ResultTemplateHeaderDTO]
      );
    }

    setFormData({ ...formData });
  };

  useEffect(() => {
    if (params?.id) {
      getResultTemplateHeaderById(params?.id, formData, setValue, setFormData);
    }
  }, []);

  return (
    <CreatePlanOfStudyMain>
      <CreatePlanOfStudyTop>
        <div className="left">
          <span className="page-heading">Add Template Header</span>
          <Breadcrumb links={breadcrumbLinks} />
        </div>
      </CreatePlanOfStudyTop>

      <CreatePlanOfStudyForm
        className="content-radius-shadow"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="common-fields">
          <div className="input-field">
            <label>Title</label>
            <div className="field-wrap">
              <div className="field">
                <input
                  type="text"
                  placeholder="Enter Title"
                  {...register("title", { required: true })}
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage error={errors.title} />
            </div>
          </div>
        </div>
        <div className="submit-buttons">
          <div className="buttons">
            <button
              className="lg-rounded-btn gray"
              type="button"
              onClick={resetForm}
            >
              Reset
            </button>
            <button className="lg-rounded-btn">Submit</button>
          </div>
        </div>
      </CreatePlanOfStudyForm>
    </CreatePlanOfStudyMain>
  );
};

export default CreateTemplateHeader;
