import { FC, Fragment, useEffect, useState } from "react";
import {
    AdmissionOfferLetterListingMain,
    AdmissionOfferLetterListingSection,
    AdmissionOfferLetterListingTop,
} from "./style";
import {
    DeleteTableSvg,
    EditTableSvg,
    ExcelSvg,
    PdfSvg,
    SearchFieldSvg,
} from "assets/images/common/svgs";
import Breadcrumb from "components/particles/breadcrumb";
import Pagination from "components/particles/table/pagination";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useStore from "hooks/useStore";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import useAdmissions from "../../useHooks";
import { OfferLetterTemplateDTO } from "utils/helpers/models/admissions/offer-letter-template.dto";
import { confirmationPopup } from "utils/helpers/common/alert-service";
import { useSelector } from "react-redux";

interface AdmissionOfferLetterListingProps { }

const AdmissionOfferLetterListing: FC<AdmissionOfferLetterListingProps> = () => {
    const breadcrumbLinks = [
        { title: "Admission /", path: "" },
        {
            title: "Manage Offer Letter Templates",
            path: siteRoutes.admissionOfferLetterListing,
        },
    ]
    const navigate = useNavigate();
    const { hasAccess } = useStore();
    const { getOfferLetterTemplates, deleteOfferLetterTemplate,updateOfferLetterTemplate } = useAdmissions();
    const [search, setSearch] = useState<string>("");
    const columns: string[] = ["Template Title", "Status", "Action"];
    const [data, setData] = useState<OfferLetterTemplateDTO[]>([]);
    const { isLoading } = useSelector((state: any) => state.sharedReducer);
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        totalRecords: 1,
    });
    useEffect(() => {
        getAllOfferLetterTemplates(pagination.page, search);
    }, []);

    const handleDelete = async (id: number) => {
        const response = await confirmationPopup();
        if (response.isConfirmed) {
            const queryParams = {
                page: 1,
                per_page: pagination.per_page,
            };
            deleteOfferLetterTemplate(id, setData, queryParams, setPagination);
        }
    };

    const onPageChange = (pageInfo: { selected: number }) => {
        const { selected: page } = pageInfo;
        setPagination({ ...pagination, page: page + 1 });
        getAllOfferLetterTemplates(page + 1, search);
    };
    const getAllOfferLetterTemplates = (page: number, search: string) => {
        const queryParams = {
            per_page: pagination.per_page,
            page,
            search,
        };
        getOfferLetterTemplates(setData, queryParams, setPagination);
    };

    const goToEdit = (id: number) => {
        navigate(`${siteRoutes.createAdmissionOfferLetter}?id=${id}`);
    }

    const handleUpdate = (template: OfferLetterTemplateDTO,index: number) => {
        data[index] = template;
        setData([...data]);
        updateOfferLetterTemplate(template.id, template);
    }


    return (
        <AdmissionOfferLetterListingMain>
            <AdmissionOfferLetterListingTop>
                <div className="left">
                    <span className="page-heading">Offer Letter Templates</span>
                    <Breadcrumb links={breadcrumbLinks} />
                </div>
                <div className="right">
                    {hasAccess(sitePermissions.createAdmissionOfferLetter) && (
                        <div className="create-org-btn">
                            <button
                                className="lg-rounded-btn"
                                onClick={() => navigate(siteRoutes.createAdmissionOfferLetter)}
                            >
                                + New Template
                            </button>
                        </div>
                    )}
                </div>
            </AdmissionOfferLetterListingTop>
            <AdmissionOfferLetterListingSection className="content-radius-shadow">
                <div className="list-header">
                    <div className="table-data-export-buttons">
                        {hasAccess(sitePermissions.downloadAdmissionOfferLetterPDF) && (
                            <div className="export-btn">
                                <span>
                                    <PdfSvg className="icon" />
                                </span>
                                <span className="text">PDF</span>
                            </div>
                        )}

                        {hasAccess(sitePermissions.downloadAdmissionOfferLetterExcel) && (
                            <div className="export-btn">
                                <span>
                                    <ExcelSvg className="icon" />
                                </span>
                                <span className="text">Excel</span>
                            </div>
                        )}
                    </div>
                    <div className="table-search-field">
                        <span className="search-icon">
                            <SearchFieldSvg className="icon" />
                        </span>
                        <input type="search" placeholder="Search" />
                    </div>
                </div>
                <div className="data-table">
                    <table className="bottom-bordered-cells">
                        <thead>
                            <tr>
                                {columns.map((column: string, index: number) => {
                                    return <th key={index}>{column}</th>;
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((template,index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="mw-150">
                                                {template.title}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-radio-field">
                                            <div className="radio">
                                                    <input
                                                        type="radio"
                                                        name={`is-active-${index}`}
                                                        id={`is-active-yes-${index}`}
                                                        checked={template.status == 1}
                                                        onChange={() => handleUpdate({...template,status: 1},index)}
                                                    />
                                                    <label htmlFor={`is-active-yes-${index}`}>
                                                        Active
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <input
                                                        type="radio"
                                                        name={`is-active-${index}`}
                                                        id={`is-active-no-${index}`}
                                                        checked={template.status == 0}
                                                        onChange={() => handleUpdate({...template,status: 0},index)}
                                                    />
                                                    <label htmlFor={`is-active-no-${index}`}>
                                                        De - Active
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-action-icons">
                                                {hasAccess(
                                                    sitePermissions.editAdmissionOfferLetter
                                                ) && (
                                                        <div className="action-icon" onClick={() => goToEdit(template.id)}>
                                                            <EditTableSvg />
                                                        </div>
                                                    )}

                                                {hasAccess(
                                                    sitePermissions.deleteAdmissionOfferLetter
                                                ) && (
                                                        <div className="action-icon" onClick={() => handleDelete(template.id)}>
                                                            <DeleteTableSvg />
                                                        </div>
                                                    )}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Fragment>
                    <Pagination
                        {...pagination}
                        onPageChange={onPageChange}
                    />
                </Fragment>
            </AdmissionOfferLetterListingSection>
        </AdmissionOfferLetterListingMain>
    );
};

export default AdmissionOfferLetterListing;
