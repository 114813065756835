import { FC, useEffect, useRef, useState } from "react";
import { DropdownMain } from "./style";
import { useSelector } from "react-redux";

interface MultiselectDropdownProps {
  options: any[];
  onSelect: (item: any) => void;
  value: any;
  property?: string;
  searchable?: boolean;
  closeOnSelect?: boolean;
  setShow?: Function
}

const SingleSelectionDropdown: FC<MultiselectDropdownProps> = ({
  options,
  onSelect,
  value,
  property = 'title',
  searchable = true,
  setShow,
}) => {
  const [searchedOptions, setSearchedOptions] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');
  const { isLoading } = useSelector((state: any) => state.sharedReducer);
  const searchRef = useRef(null);

  const handleSelect = (item: any) => {
    item.selected = !item.selected;
    onSelect(item);
    if(setShow){
      setShow(false);
    }
  };

const handleSearchChange = (event: any) => {
  const { value } = event.target;
  setSearch(value);
  const helper = options.filter((board) =>
    board.title.toLowerCase().includes(search.toLowerCase())
  );
  setSearchedOptions([...helper]);
};

const getOptions = (): any[] => {
  return search === '' ? options : searchedOptions;
}

useEffect(() => {
  if(searchRef && options.length){
    (searchRef as any).current.focus();
  }
}, [options]);

  return (
    <DropdownMain>
      <ul className="p-custom-scrollbar-8">
        {(searchable && options.length) ? 
        <div className="search-section">
          <input type="search" placeholder="Search" onChange={handleSearchChange} ref={searchRef}/>
        </div> : ''
        }
        {getOptions().map((item: any, index: number) => {
          return (
            <li key={index} onClick={() => handleSelect(item)} className={`${value == item.id ? 'selected' : ''}`}>
              <div className="item-text">
                <span className="text">
                  {item?.[property]}
                </span>
              </div>
            </li>
          );
        })}
        {!(getOptions()).length && !isLoading && (
          <span className="no-options-found">No options found</span>
        )}
      </ul>
    </DropdownMain>
  );
};

export default SingleSelectionDropdown;
