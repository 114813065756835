import { useDispatch } from "react-redux";
import { UnknownAction } from "redux";
import { SHARED_REDUCER_ACTIONS } from "store/reducers/shared.reducer";
import { ROLES } from "utils/helpers/enums/roles.enums";
import { domains } from "utils/helpers/enums/shared.enums";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import { ROUTES_PERMISSIONS } from "utils/constants/pages-routes";
import useUtils from "./useUtils";
import { OrganizationDTO } from "utils/helpers/models/organization/organization.dto";
import useHttp from "./useHttp";

const useStore = () => {
  const dispatch = useDispatch();
  const { setCSSVariable } = useUtils();
  const dispatchAction = (type: string, payload?: any): void => {
    const dispatcher: UnknownAction = { type, payload };
    dispatch(dispatcher);
  };

  const setSiteZoomLevel = (level?: number) => {
    if(level){
      const remZoom = (100 - level) + 100 + (((100 - level) / 10) * 2.5);
      setCSSVariable('--zoom-level', level + '%');
      setCSSVariable('--zoom-view-height', `${remZoom}vh`);
      setCSSVariable('--zoom-view-width', `${remZoom}vw`);
      localStorage.setItem('zoomLevel', `${level}%`);
    }else{
      const zoomLevel = localStorage.getItem('zoomLevel');
      const level = +(zoomLevel as string)?.replace('%', '');
      const remZoom = (100 - level) + 100 + (((100 - level) / 10) * 2.5);
      if(zoomLevel){
        setCSSVariable('--zoom-view-height', `${remZoom}vh`);
        setCSSVariable('--zoom-view-width', `${remZoom}vw`);
        setCSSVariable('--zoom-level', zoomLevel);
      }
    }
  }

  const setLoading = (isLoading: boolean): void => {
    dispatchAction(SHARED_REDUCER_ACTIONS.LOADING, isLoading);
  };

  const setToken = (payload: null | string): void => {
    dispatch({ type: SHARED_REDUCER_ACTIONS.SAVE_TOKEN, payload });
    if (payload) localStorage.setItem("token", payload);
  };

  const setUser = (payload: any): void => {
    dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_USER, payload);
    localStorage.setItem("user", JSON.stringify(payload));
  };

  const getFilePathWithBackend = (path: string): string => {
    return `${process.env.REACT_APP_BASE_URL}/${path}`;
  };

  const setPermissions = (permissions: any[]) => {
    const permissionsToSave = permissions.map((permission) => {
      return typeof permission === "string" ? permission : permission.name;
    });
    permissionsToSave.push(sitePermissions.addStudentsToPlanOfStudy);
    localStorage.setItem("permissions", JSON.stringify(permissionsToSave));
    dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_PERMISSIONS, [
      ...permissionsToSave,
    ]);
  };

  const getPermissions = () => {
    const permissions = localStorage.getItem("permissions");
    if (permissions) {
      return JSON.parse(permissions);
    } else {
      return [];
    }
  };

  const getToken = (): string | null => {
    return localStorage.getItem("token");
  };

  const setRole = (role: any): void => {
    localStorage.setItem("role", JSON.stringify(role));
  };

  const hasAccess = (permission: string): boolean => {
    const permissions = getPermissions();
    return permissions.includes(permission);
  };

  const setUserAlreadyApplied = (applied: boolean) => {
    dispatchAction(SHARED_REDUCER_ACTIONS.HAS_USER_ALREADY_APPLIED, applied);
  }

  const doesFileSizeExceed = (file: File, size: number = 1) => {
    const sizeInKbs = size * 1024;
    return ((file.size / 1024) > sizeInKbs);
  }

  const getRole = (): any => {
    const role = localStorage.getItem("role");
    if (role) {
      return JSON.parse(role);
    } else {
      return null;
    }
  };

  const setLastLoggedInUserRole = (role: ROLES) => {
    localStorage.setItem('lastUserRole', role);
  }

  const logout = () => {
    const lastUserRole = getLastLoggedInUserRole();
    const organization = getOrganization();
    localStorage.clear();
    setLastLoggedInUserRole(lastUserRole as ROLES);
    setOrganization(organization);
    dispatchAction(SHARED_REDUCER_ACTIONS.LOGOUT);
    setDarkTheme(false);
  };

  const setDarkTheme = (isDarkTheme: boolean) => {
    addThemeTransitionToEveryElement();
    if (isDarkTheme) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    dispatchAction(SHARED_REDUCER_ACTIONS.THEME_CHANGE, isDarkTheme);

    setTimeout(() => {
      removeThemeTransitionFromEveryElement();
    }, 500);
  };

  const addThemeTransitionToEveryElement = () => {
    const elements = document.querySelectorAll("*");
    elements.forEach((element) => {
      element.classList.add("theme-transition");
    });
  };

  const removeThemeTransitionFromEveryElement = () => {
    const elements = document.querySelectorAll("*");
    elements.forEach((element) => {
      element.classList.remove("theme-transition");
    });
  };

  const getDomainDefaultUnAuthRoute: any = () => {
    const domainType = getDomainType();
    const lastUserRole = getLastLoggedInUserRole();
    if (domainType == domains.mainDomain) {
      return siteRoutes.superAdminLogin;
    } else if(lastUserRole === ROLES.ADMIN){
      return siteRoutes.adminLogin;
    }else{
      return siteRoutes.ePortalLogin;
    }
  };

  const getUser = (): any => {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  };

  const getDomainDefaultAuthRoute: any = () => {
    const domainType = getDomainType();
    if (domainType == domains.mainDomain) {
      return siteRoutes.superAdminDashboard;
    } else {
      const userRole = getRole();
      if (userRole?.guard_name == ROLES.ADMIN) {
        if (hasAccess(sitePermissions.adminDashboard)) {
          return siteRoutes.adminDashboard;
        } else {
          const permissions = getPermissions();
          const firstPermissionRoute = ROUTES_PERMISSIONS.find(
            (p: any) => p.permission === permissions[0]
          );
          return firstPermissionRoute?.path;
        }
      } else if (userRole?.guard_name == ROLES.USER) {
        return siteRoutes.ePortalDashboard;
      }
    }
  };

  const isSuperAdmin = () => {
    const role = getRole();
    return role?.guard_name === ROLES.SUPER_ADMIN;
  };

  const setOrganization = (payload: any) => {
    dispatchAction(SHARED_REDUCER_ACTIONS.SAVE_ORGANIZATION, payload);
    localStorage.setItem("organization", JSON.stringify(payload));
  };

  // (domain === '192.168.100.44' || domain === '192.168.0.196' || domain === domain.replace(domain.split('.')[0], 'org'))

  const getDomainType = () => {
    const domain = getDomain();
    if (
      domain === "localhost" ||
      domain === "www.umsoncloud.com" ||
      domain === "umsoncloud.com"
    ) {
      return domains.mainDomain;
    } else {
      return domains.subDomain;
    }
  };
  const getDomain = (): string => {
    return window.location.hostname;
  };

  const getOrganization = (): OrganizationDTO | null => {
    const org = localStorage.getItem("organization");
    if (org) {
      return JSON.parse(org);
    } else {
      return null;
    }
  };

  const getSiteZoomLevel = () => {
    const root = document.documentElement;
    const zoom = getComputedStyle(root).getPropertyValue('--zoom-level').trim();
    return +zoom.replace('%', '');
  }

  const getLastLoggedInUserRole = () => {
    return localStorage.getItem('lastUserRole');
  }

  return {
    setToken,
    setLoading,
    dispatchAction,
    setDarkTheme,
    logout,
    setUser,
    getLastLoggedInUserRole,
    getDomainDefaultUnAuthRoute,
    getDomainDefaultAuthRoute,
    setLastLoggedInUserRole,
    setSiteZoomLevel,
    getFilePathWithBackend,
    setPermissions,
    hasAccess,
    getDomainType,
    getSiteZoomLevel,
    getPermissions,
    getToken,
    doesFileSizeExceed,
    setOrganization,
    getOrganization,
    getRole,
    setUserAlreadyApplied,
    setRole,
    isSuperAdmin,
  };
};

export default useStore;
