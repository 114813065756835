import { FC, Fragment, useEffect, useState } from "react";
import {
  HeadingDiv,
  ApplicationListSection,
  MainApplicationList,
  ApplicationsWrapper,
} from "./style";
import Breadcrumb from "components/particles/breadcrumb";
import {
  ApplicationBtnIcon,
  ApplyIconSvg,
  FormBuildingSvg,
  InfoIconSvg,
  LastDateIconSvg,
  RedCrossIconSvg,
} from "assets/images/common/svgs";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import useEportal from "../../useHooks";
import useUtils from "hooks/useUtils";
import UploadChallan from "./components/upload-challan-popup";
import { useSelector } from "react-redux";
import { confirmationPopup } from "utils/helpers/common/alert-service";

const ApplicationList: FC = () => {
  const breadcrumbLinks = [
    { title: "Eportal /", path: siteRoutes.ePortalDashboard },
    { title: "Application List ", path: siteRoutes.eportalAdmissionsListing },
  ];
  const navigate = useNavigate();
  const { getDateFromDateTime } = useUtils();
  const {
    getStudentApplications,
    admissionGenerateFeeChallan,
    admissionDownloadApplication,
    deleteUserAdmissionApplication,
  } = useEportal();
  const [data, setData] = useState<any[]>([]);
  const { user, isLoading } = useSelector((state: any) => state.sharedReducer);
  const goToApplyOtherProgram = () => {
    navigate(siteRoutes.createEportalAdmissionList);
  };
  useEffect(() => {
    getStudentApplications(setData);
  }, []);  

  const generate = (app_id: number) => {
    admissionGenerateFeeChallan(app_id);
  };

  const downloadApplication = (app_id: string) => {
    admissionDownloadApplication(app_id);
  };

  const [testAppId, setTestAppId] = useState<number>(0);
  const [uploadChallan, setUploadChallan] = useState<boolean>(false);

  const goToUploadChallan = (index: number, app_id: number) => {
    (data as any)[index].isDropdownOpen = false;

    setTestAppId(app_id);
    setUploadChallan(true);
  };

  const handleDeleteApplication = async (appId: number) => {
    const result = await confirmationPopup();
    if (result.isConfirmed) {
      deleteUserAdmissionApplication(appId, setData);
    }
  };
  return (
    <>
      <MainApplicationList>
        <HeadingDiv>
          <div className="left">
            <span className="page-heading">Application List</span>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
          <div className="right">
            <div className="update-profile-btn">
              <button
                className="lg-rounded-btn"
                onClick={() => navigate(siteRoutes.ePortalEditProfile)}
              >
                Update Admission Application
              </button>
            </div>
            <div className="create-btn">
              <button
                className="lg-rounded-btn link-btn"
                onClick={goToApplyOtherProgram}
              >
                <div className="icon">
                  <ApplicationBtnIcon />
                </div>
                {user?.is_already_apply
                  ? "Apply in another program"
                  : "Apply Now"}
              </button>
            </div>
          </div>
        </HeadingDiv>

        <ApplicationsWrapper>
          {data?.length
            ? data?.map((item: any, index: number) => {
                return (
                  <ApplicationListSection key={index}>
                    <div className="main-heading-div">
                      <div className="left-section">
                        <p className="form-heading">
                          {item?.program} <span>({item?.department})</span>
                        </p>
                        <div className="campus-name">
                          <span className="city-name">
                            {item?.campus_title}
                          </span>
                        </div>
                      </div>
                      <div className="right-section">
                        <div className="apply-date-main-div">
                          <span className="sub-heading">Apply Date:</span>
                          <div className="apply-date-div">
                            <ApplyIconSvg />
                            <span className="apply-date">{item?.app_date}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sub-heading-div">
                      <p className="sub-heading">
                        <span>Application No: </span> {item?.app_no}
                      </p>
                      <div className="last-date-div">
                        <p className="sub-heading">Admission close Date:</p>
                        <div className="last-date">
                          <LastDateIconSvg />
                          <p className="last-date-para">
                            {getDateFromDateTime(item?.close_date)}
                          </p>
                        </div>
                      </div>
                    </div>

                    <hr className="line" />

                    {item?.is_eligible ? <div className="info-div">
                    <InfoIconSvg className="info-icon" />
                    <p className="info-para">
                    Based on the information provided, you are eligible for admission consideration, achieving a current aggregate of <span>{item?.app_merit}%.</span> We will contact you via [email/phone/notification] if you are selected for admission.
                    </p>
                  </div> : <Fragment>
                    <div className="info-div red">
                      <RedCrossIconSvg className="info-icon" />
                      <p className="info-para red">
                        We appreciate the updated information you provided.
                        Unfortunately, it indicates that you do not meet the
                        eligibility requirements for admission to the {item?.program} program. The specific reason for this decision
                        is outlined below.
                      </p>
                    </div>
                    <div className="criteria-section">
                      <p
                        className="red-text"
                        dangerouslySetInnerHTML={{ __html: item?.eligibility?.criteria }}
                      ></p>
                    </div>
                  </Fragment>} 
                    <div className="uploaded-parent">
                      {item?.p_fee_template_link && (
                        <div className="status-div">
                          <p className="sub-heading">Application Fee Status</p>
                          <div className="status">
                            <RedCrossIconSvg />
                            <p className="status-para">Not Uploaded</p>
                          </div>
                        </div>
                      )}
                    </div>

                    <hr className="line" />

                    <div className="action-button">
                      <button
                        type="button"
                        className="download-btn"
                        onClick={() => downloadApplication(item.app_no)}
                      >
                        Download Application
                      </button>
                      {item?.p_fee_template_link && (
                        <Fragment>
                          <button
                            type="button"
                            className=""
                            onClick={() => generate(item.id)}
                          >
                            Download Fee Challan
                          </button>
                          <button
                            type="button"
                            className="upload-btn"
                            onClick={() =>
                              goToUploadChallan(index, item?.app_no)
                            }
                          >
                            Upload Fee Challan
                          </button>
                        </Fragment>
                      )}
                      <button
                        className="pay-btn"
                        type="button"
                        onClick={() => handleDeleteApplication(item?.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </ApplicationListSection>
                );
              })
            : ""}

          {!isLoading && !data.length ? (
            <div className="content-radius-shadow no-data-container">
              <p>No Applied Programs.</p>
            </div>
          ) : (
            <div className="loader"></div>
          )}
        </ApplicationsWrapper>
      </MainApplicationList>
      {uploadChallan && (
        <UploadChallan app_id={testAppId} setOpen={setUploadChallan} />
      )}
    </>
  );
};

export default ApplicationList;
