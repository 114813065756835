import styled from "styled-components";

export const CreateOrgMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;

  @media screen and (max-width: 630px) {
    padding: 0.5em;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;

  @media screen and (max-width: 630px) {
    padding: 1em;
  }
  .image-fields {
    width: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;
    margin: 2em 0;
  }
  .bottom-upload{
    display: flex;
    width: 100%;
    gap: 2em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .organiztion-upload-images {
    width: 30%;
    &.cover{
      width: 70%;
      @media (max-width: 768px) {
      width: 100%;
    }
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 450px) {
      width: 100%;
    }
    .image-field {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      background-color: var(--silver-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    &.light{
  background-color: transparent;
  border: 1px solid var(--lightgray-light);
}

      .image {
        height: 160px;
        width: 100%;
        .placeholder-heading{
          color: var(--black-text);
          font-size:17px;
          font-weight: 600;
        }
       
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      
      }
      .login-image {
        height: 160px;
        width: 100%;
        .placeholder-heading{
          color: var(--black-text);
          font-size:17px;
          font-weight: 600;
        }


        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      label {
        text-align: center;
        color: var(--black-text);
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        display: flex;
    justify-content: center;
    align-items: center;
      }
    }
  }
  .organiztion-admin-top-images {
    .image-field {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      background-color: var(--silver-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    height: 140px;
    &.light{
  background-color: transparent;
  border: 1px solid var(--lightgray-light);
}

      .image {
        width: 120px;
        height: 120px;
        .placeholder-heading{
          color: var(--black-text);
          font-size:17px;
          font-weight: 600;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      .side-bar-image {
        width: 100%;
        height: 50px;
        object-fit: cover;
        .placeholder-heading{
          color: var(--black-text);
          font-size:17px;
          font-weight: 600;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      label {
        text-align: center;
        color: var(--black-text);
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        display: flex;
    justify-content: center;
    align-items: center;
      }
    }
  }
 
  .common-fields {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 1.5em;
    color: var(--lightgray-medium);
    font-size: 15px;
    font-weight: 400;

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }

  .upload-input-field {
    @media screen and (max-width: 800px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .detail-fields {
    padding-top: 1em;
    display: flex;
    gap: 1.5em;
    @media (max-width:1279px) {
    flex-direction: column;
      
    }
  }

  .action-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .buttons {
      display: flex;
      gap: 1em;

      @media screen and (max-width: 500px) {
        width: 100%;
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }

  .css-13cymwt-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: none !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: var(--input--field-bg);
    color: "";
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    height: 50px;
    border: 1px solid var(--field-border);
  }
`;
