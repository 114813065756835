import styled from "styled-components";

export const AdminDashboarMain = styled.div`
  width: 100%;
`;

export const ADashboardTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;

  .left {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }
`;
export const ADashboradSection = styled.div`
  .top-cards-container {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-wrap: wrap;
  }
  .top-cards {
    background-color: var(--white-color);
    padding: 1em;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 20px;
    align-items: center;
    width: 32%;

    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 51px;
    padding: 25px;
  }
  .title {
    font-size: 17px;
    color: var(--black-text);
    font-weight: 600;
  }
  .title-value {
    color: var(--lightgray-medium);
    font-size: 22px;
    font-weight: 400;
  }
  .title-value2 {
    color: var(--lightgray-medium);
    font-size: 13px;
    font-weight: 400;
  }
  .card-title-main {
    width: 100%;
  }
  .bottom-title {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .line-graph-container {
    margin-top: 2em;
    display: flex;
    gap: 3em;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .sub-heading {
    font-size: 30px;
    color: var(--lightgray-light);
    font-weight: 400;
  }
  .primary {
    color: var(--primary);
  }
`;
export const LinkSection = styled.div`
  margin-top: 2rem;
  padding: 1em;
  .link-heading {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-text);
    padding-bottom: 1em;
    border-bottom: 1px solid var(--lightgray-extralight);
  }
  .link-card-container {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-wrap: wrap;
  }
  .link-card {
    background-color: #ffbb3826;
    padding: 1em;
    /* width: 24%; */
    border-radius: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    width: 24%;
    @media (max-width: 768px) {
      width: 48%;
    }
    @media (max-width: 540px) {
      width: 100%;
    }
  }
  .link-title {
    font-size: 16px;
    font-weight: 600;
    color: black;
  }
`;
export const ChartSection = styled.div`
  margin-top: 2em;
  display: flex;
  gap: 3em;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const PieChart = styled.div`
  width: 50%;
  box-shadow: 0px 4px 70px 0px #0000001a;
  border-radius: 20px;
  background: var(--white-color);
  padding: 1em;
  @media (max-width: 768px) {
    width: 100%;
  }

  .header-section {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    .title {
      span {
        font-size: 32px;
        font-weight: 500;
        color: var(--black-text);

        @media screen and (max-width: 1280px) {
          font-size: 24px;
        }
      }
    }

    .description {
      color: var(--black-text);
      font-size: 14px;
      font-weight: 400;
    }
  }
  .fee {
    color: #128b9b;
    font-size: 32px;
    font-weight: 400;
  }
  .pie-chart-section {
    width: 50%;
  }
  .pie-container {
    display: flex;
    gap: 30px;
  }
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const LegendBullet = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const LegendLabel = styled.span`
  font-size: 14px;
  /* color: #333; */
`;
export const LineChart = styled.div`
  width: 50%;
  box-shadow: 0px 4px 70px 0px #0000001a;
  border-radius: 20px;
  background: var(--white-color);
  padding: 1em;
  @media (max-width: 768px) {
    width: 100%;
  }
  .header-section {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    .title {
      span {
        font-size: 32px;
        font-weight: 500;
        color: var(--black-text);

        @media screen and (max-width: 1280px) {
          font-size: 24px;
        }
      }
    }

    .description {
      color: var(--black-text);
      font-size: 14px;
      font-weight: 400;
    }
  }
  .fee {
    color: #128b9b;
    font-size: 32px;
    font-weight: 400;
    white-space: nowrap;
  }
  .line-chart-section {
    width: 100%;
  }
`;
export const QuickLinkSection = styled.div`
  margin-top: 2em;
  display: flex;
  gap: 3em;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const QuickLinks = styled.div`
  width: 50%;
  box-shadow: 0px 4px 70px 0px #0000001a;
  border-radius: 20px;
  background: var(--white-color);
  padding: 1em;
  @media (max-width: 768px) {
    width: 100%;
  }
  .heading {
    font-weight: 500;
    font-size: 24px;
    color: var(--black-text);
  }
  .left-heading {
    color: #128b9b;
    font-weight: 400;
    font-size: 14px;
  }
  .quick-links-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .links-main {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-top: 2em;
  }
  .link-title {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-text);
  }
  .link-description {
    color: var(--lightgray-light);
    font-size: 15px;
    font-weight: 400;
  }
`;

export const MainStatCards = styled.div`
  width: 50%;
  display: flex;
  gap: 1em;
  @media (max-width: 768px) {
    width: 100%;
  }

  /* @media screen and (max-width: 950px) {
    flex-direction: column;
  } */
  .pie-chart-section {
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 101%;
    height: 83%;
  }
`;

export const MainStatCard = styled.div`
  width: 100%;
  background-color: var(--white-color);
  border-radius: 20px;
  padding: 1em;

  @media screen and (max-width: 950px) {
    width: 100%;
  }

  .header-section {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    .title {
      span {
        font-size: 32px;
        font-weight: 500;
        color: var(--black-text);

        @media screen and (max-width: 1280px) {
          font-size: 24px;
        }
      }
    }

    .description {
      color: var(--black-text);
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
export const ActivityLogs = styled.div`
  width: 50%;
  padding: 1em;
  @media (max-width: 768px) {
    width: 100%;
  }
  .heading {
    font-weight: 500;
    font-size: 24px;
    color: var(--black-text);
  }
  .list-item {
    color: var(--primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    margin-left: 20px;
    margin-top: 1.6em;
  }
  .gray {
    color: var(--lightgray-medium);
    margin-left: 3px;
  }
`;
export const SheduleSection = styled.div`
  margin-top: 2em;
  padding: 1em;
  .shedule-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--lightgray-extralight) !important;
  }
  .top-right {
    color: var(--black-text);
    font-weight: 400;
    font-size: 16px;
  }
  .top-left {
    color: var(--primary);
    font-weight: 400;
    font-size: 14px;
  }
  .date-picker-section {
    width: 50%;
    margin-top: 2em;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrDateRangePickerWrapper {
    display: inline-flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 100%;

    border-radius: 20px;
  }
  .rdrCalendarWrapper {
    color: var(--black-text);
    font-size: 12px;
    width: 100%;
    background-color: var(--white-color);
  }
  .rdrDateDisplayWrapper {
    background-color: var(--lightgray-extralight);
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    border: 1px solid var(--black-text);
  }
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
    padding-top: 10px;
    width: 100%;
    border-left: 1px solid var(--black-text);
    border-right: 1px solid var(--black-text);
  }
  .rdrMonth {
    width: 100%;
    padding: 0 0.833em 1.666em 0.833em;
    border: 1px solid var(--black-text);
  }
  .rdrDateDisplayItem input {
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: var(--white-color);
    width: 100%;
    color: var(--lightgray-medium);
    outline: none;
  }
  .rdrMonthAndYearPickers select {
    border: 0;
    background: var(--lightgray-extralight);
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: black;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center;
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
    color: var(--primary) !important;
  }
  .rdrDayNumber span {
    color: var(--black-text);
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
    color: var(--primary) !important;
    outline: var(--primary) !important;
  }
  .rdrStartEdge {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
    color: var(--primary) !important;
    outline: var(--primary) !important;
  }

  .upcomming-event-section {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .event-top {
    display: flex;
    justify-content: space-between;
  }
  .shedule-heading {
    font-size: 24px;
    color: var(--black-text);
    font-weight: 500;
  }
  .shedule-container {
    display: flex;
    gap: 3em;
    margin-top: 2em;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .event-description-main {
    box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.2),
      2px 2px 10px rgba(0, 0, 0, 0.2), -2px 2px 10px rgba(0, 0, 0, 0.2);
    border-left: 3px solid #128b9b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
    margin-block: 1em;
  }
  .times {
    font-size: 14px;
    font-weight: 400;
    color: var(--lightgray-medium);
    white-space: nowrap;
  }
  .even-timings {
    display: flex;
  }
  .event-title {
    color: var(--black-text);
    font-size: 15px;
    font-weight: 500;
  }
  .participant-img {
    width: 60%;
  }
  .event-desctiption-container {
    width: 100%;
    margin-left: 15px;
  }
  .participant-img-container {
    display: flex;
    width: 50%;
    justify-content: end;
  }
`;
export const BottomSection = styled.div`
  display: flex;
  gap: 3em;
  margin-top: 2em;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
export const LeaveRequests = styled.div`
  width: 50%;
  padding: 1em;
  @media (max-width: 768px) {
    width: 100%;
  }
  .upcomming-event-section {
    width: 100%;
  }
  .top-right {
    color: var(--black-text);
    font-weight: 400;
    font-size: 16px;
  }
  .top-left {
    color: #128b9b;
    font-weight: 400;
    font-size: 14px;
  }
  .event-top {
    display: flex;
    justify-content: space-between;
  }
  .shedule-heading {
    font-size: 24px;
    color: var(--black-text);
    font-weight: 500;
  }
  .shedule-container {
    display: flex;
    gap: 3em;
    margin-top: 2em;
  }
  .event-description-main {
    box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.2),
      2px 2px 10px rgba(0, 0, 0, 0.2), -2px 2px 10px rgba(0, 0, 0, 0.2);
    border-left: 3px solid #128b9b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
    margin-block: 1.5em;
  }
  .times {
    font-size: 14px;
    font-weight: 400;
    color: var(--lightgray-medium);
    white-space: nowrap;
  }
  .even-timings {
    display: flex;
  }
  .event-title {
    color: var(--black-text);
    font-size: 15px;
    font-weight: 500;
  }
  .participant-img {
    width: 60%;
  }
  .event-desctiption-container {
  }
  .participant-img-container {
    display: flex;
    width: 50%;
    justify-content: end;
  }
`;
