import styled from "styled-components";

export const ViewStudentApplicationMain = styled.div`
    width: var(--zoom-view-width);
    min-height: var(--zoom-view-height);
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    background: var(--black-extralight);
    padding: 0 1em;
`;

export const ContentSection = styled.div`
    width: 670px;
    padding: 1.5em 1.5em 2em 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    background: var(--white-color);
    border-radius: 18px;

    @media screen and (max-width: 650px){
        width: 100%;
    }

    .header-section{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .heading{
            font-size: var(--font-18);
            color: var(--black-text);
            font-weight: 600;
        }

        .close-icon{
            cursor: pointer;
            svg path{
                fill: var(--black-text);
            }
        }
    }

    .info-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;

        .particular-info{
            width: 100%;
            display: flex;
            justify-content: space-between;
            &-left{
                .title{
                    color: var(--black-text);
                    font-size: var(--font-14);
                    font-weight: 500;
                }
            }

            &-right{
                .info{
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--lightgray-medium);
                }
            }
        }

    }
    .action-buttons{
        width: 100%;
        display: flex;
        gap: 1em;
        justify-content: space-between;

        @media screen and (max-width: 450px){
            flex-direction: column;
        }

        .particular-button{
            @media screen and (max-width: 450px){
                width: 100%;
            }
            button{
                width: 190px;
                height: 35px;
                font-size: var(--font-14);
                color: var(--primary);
                border: none;
                outline: none;
                background: var(--primary-extralight-transparent);
                border-radius: 5px;
                @media screen and (max-width: 450px){
                    width: 100%;
                }

                &.green{
                    background: var(--green-extralight);
                    color: var(--spring-green);
                }
                &.red{
                    background-color: var(--crimson-shadow);
                    color: var(--medium-crimson);
                }
            }
        }
    }
`;

export const InfoField = styled.div<{ gray: boolean }>`
  width: 100%;
  height: 42px;

  @media screen and (max-width: 780px){
    height: unset;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 1em;
  background: ${({ gray }) =>
    gray ? "var(--info-field-bg2)" : "var(--info-field-bg1)"};
  .title {
    font-size: 14px;
    font-weight: 600;
    color: var(--black-text);
  }

  .info {
    font-size: 14px;
    font-weight: 600;
    color: var(--lightgray-medium);
  }
`;