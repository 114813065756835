import { FC, Fragment, useEffect, useState } from "react";
import {
  HeaderMain,
  HeaderMenuBar,
  MenuBarLeft,
  MenuBarRight,
  MenuIcon,
  ModalMain,
  ProfileImage,
  ProfileSection,
  SearchField,
  UtilityIcons,
} from "./style";
import {
  DarkThemeSvg,
  LightThemeSvg,
  MenuIconSvg,
  ModalAccountSvg,
  ModalDocumentSvg,
  ModalLockSvg,
  ModalLogoutSvg,
  NotificationSvg,
  SearchSvg,
  SettingsSvg,
} from "assets/images/common/svgs";
import useStore from "hooks/useStore";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useComponentVisible from "hooks/click-outside";
import { siteRoutes } from "utils/helpers/enums/routes.enum";
import { sitePermissions } from "utils/helpers/enums/permissions.enum";
import NotificationsPopup from "./components/notifications";
import { HEADER_UTILITIES } from "utils/helpers/enums/shared.enums";
import SettingsPopup from "./components/settings";
import ChangePasswordModal from "./components/change-password";

interface HeaderProps {
  openSidebar: boolean;
  setOpenSidebar: Function;
}

const Header: FC<HeaderProps> = ({ openSidebar, setOpenSidebar }) => {
  const { setDarkTheme, logout, getDomainDefaultUnAuthRoute } = useStore();
  const { isDarkTheme, token } = useSelector(
    (state: any) => state.sharedReducer
  );

  const [changePassModal, setChangePasswordModal] = useState<boolean>(false);
  const [currentOpenedDropdown, setCurrentOpenedDropdown] = useState<HEADER_UTILITIES | null>(null);
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.sharedReducer);

  const toggleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  };

  const toggleDarkTheme = () => {
    setDarkTheme(!isDarkTheme);
  };

  useEffect(() => {
    if (!token) {
      const defaultUnAuthRoute: any = getDomainDefaultUnAuthRoute();
      navigate(defaultUnAuthRoute);
    }
  }, [token]);

  const location = useLocation();

  const toggleModal = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    setShowDropdown(false);
  }, [location]);
  const {
    isComponentVisible: showDropdown,
    setIsComponentVisible: setShowDropdown,
    ref: dropdownRef,
  } = useComponentVisible();

  const getAvatarLabel = (): string => {
    const [firstName] = user?.name?.split(" ") || ["", ""];
    return `${firstName[0]}`;
  };
  const goToUpdateOrgAdmin = () => {
    navigate(siteRoutes.updateOrganizationAdmin);
  };
  const goToEditProfile = () => {
    navigate(siteRoutes.editEPStudentProfile);
  };
  const goToViewProfile = () => {
    navigate(siteRoutes.viewEPStudentProfile);
  };
  const { hasAccess, getRole } = useStore();

  const toggleDropdown = (dropdownType: HEADER_UTILITIES) => {
    if (currentOpenedDropdown === dropdownType) {
      setCurrentOpenedDropdown(null);
      setShowDropdown(false);
    } else {
      setCurrentOpenedDropdown(dropdownType);
      setShowDropdown(true);
    }
  }

  const role = getRole();
  const isuser = role?.name === "Users";
  const openChangePasswordModel = () => {
    // if(isuser) {
    setChangePasswordModal(true);
    // }
    console.log(isuser);
  }

  return (
    <Fragment>

      <HeaderMain>
        <HeaderMenuBar>
          <MenuBarRight>
            <MenuIcon onClick={toggleSidebarOpen}>
              <MenuIconSvg className="icon" />
            </MenuIcon>
          </MenuBarRight>
          <MenuBarLeft>
            <SearchField>
              <div className="search-icon">
                <SearchSvg className="icon" />
              </div>
              <div className="field">
                <input type="search" placeholder="Search for something" />
              </div>
            </SearchField>
            <UtilityIcons>
              <div className="utility-icon" onClick={toggleDarkTheme}>
                {/* <LightThemeSvg className="icon" /> */}
                {isDarkTheme ? (
                  <LightThemeSvg className="icon" />
                ) : (
                  <DarkThemeSvg className="icon" />
                )}
              </div>
              <div className="utility">
                <div className="utility-icon" onClick={() => toggleDropdown(HEADER_UTILITIES.SETTINGS)}>
                  <SettingsSvg className="icon" />
                </div>
                {(currentOpenedDropdown == HEADER_UTILITIES.SETTINGS && showDropdown) ? <div className="modal-section" ref={dropdownRef}>
                  <SettingsPopup />
                </div> : ''}
              </div>
              <div className="utility">
                <div className="utility-icon" onClick={() => toggleDropdown(HEADER_UTILITIES.NOTIFICATIONS)}>
                  <NotificationSvg className="icon" />
                </div>
                {(currentOpenedDropdown == HEADER_UTILITIES.NOTIFICATIONS && showDropdown) ? <div className="modal-section" ref={dropdownRef}>
                  <NotificationsPopup />
                </div> : ''}
              </div>
            </UtilityIcons>
            <ProfileSection>
              <ProfileImage ref={dropdownRef}>
                {user?.profile_image ? (
                  <div className="image cp" onClick={() => toggleDropdown(HEADER_UTILITIES.PROFILE)}>
                    <img src={user?.profile_image} alt="" />
                  </div>
                ) : (
                  <div className="avatar cp" onClick={() => toggleDropdown(HEADER_UTILITIES.PROFILE)}>
                    {getAvatarLabel()}
                  </div>
                )}
                {(showDropdown && currentOpenedDropdown == HEADER_UTILITIES.PROFILE) && (
                  <ModalMain>
                    <div className="top-img">
                      <ProfileImage>
                        {user?.profile_image ? (
                          <div className="image cp">
                            <img src={user?.profile_image} alt="" />
                          </div>
                        ) : (
                          <div className="avatar cp">{getAvatarLabel()}</div>
                        )}
                      </ProfileImage>
                      <p className="user-name">{user?.name}</p>
                    </div>
                    <div className="modal-listing-section">
                      <ul>
                        {hasAccess(sitePermissions.updateOrganizationAdmin) && (
                          <li onClick={goToUpdateOrgAdmin}>
                            <div>
                              <ModalAccountSvg className="modal-icon" />
                            </div>
                            <span>My Account</span>
                          </li>
                        )}
                        {hasAccess(sitePermissions.editEPStudentProfile) && (
                          <li onClick={goToEditProfile}>
                            <div>
                              <ModalAccountSvg className="modal-icon" />
                            </div>
                            <span>Edit Profile</span>
                          </li>
                        )}
                        {hasAccess(sitePermissions.viewEPStudentProfile) && (
                          <li onClick={goToViewProfile}>
                            <div>
                              <ModalDocumentSvg className="modal-icon" />
                            </div>
                            <span>View Profile</span>
                          </li>
                        )}

                        {isuser && (<li onClick={openChangePasswordModel}>
                          <div>
                            <ModalLockSvg className="modal-icon" />
                          </div>
                          <span>Change Password</span>
                        </li>)}
                        <li onClick={logout}>
                          <div>
                            <ModalLogoutSvg className="modal-icon" />
                          </div>
                          <span>Sign out</span>
                        </li>
                      </ul>
                    </div>
                  </ModalMain>
                )}
              </ProfileImage>
            </ProfileSection>
          </MenuBarLeft>
        </HeaderMenuBar>
      </HeaderMain>

      {changePassModal && (<ChangePasswordModal setOpen={setChangePasswordModal} />)}

    </Fragment>
  );
};

export default Header;
