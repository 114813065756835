import { FC, useEffect, useState } from "react";
import {
    QuotaAddListProgramMain,
    QuotaAddListProgramContainer,
    QuotaAddListProgramWrapper,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { CoReqDTO } from "utils/helpers/models/academics/co-req-dto";
import useAdmissions from "containers/private/admissions/useHooks";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import { useForm } from "react-hook-form";
import useUtils from "hooks/useUtils";
import useAcademics from "../../useHooks";

interface CoRequisiteProps {
    setOpen: (open: boolean) => void;
    headerId: any;
}

const TemplateCourseCoReqCreate: FC<CoRequisiteProps> = ({
    setOpen,
    headerId,
}) => {

    const { getQueryParams } = useUtils();
    const params = getQueryParams();
    const {
        getTemplateCoursesForAddRequisiteCourses,
        addCoreqCourseToTemplateCourse,
    } = useAcademics();

    const [formData, setFormData] = useState<CoReqDTO>(() => new CoReqDTO());
    const [courses, setCourses] = useState<any[]>([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<CoReqDTO>({
        defaultValues: formData,
        mode: "onChange",
    });

    const handleCloseModal = () => {
        setOpen(false);
    };

    const getTemplateCourses = () => {
        getTemplateCoursesForAddRequisiteCourses(setCourses);
    };

    useEffect(() => {
        getTemplateCourses();
    }, []);

    // Handle course selection
    const handleSelect = (program: any) => {
        const updatedCourses = [...(formData.co_req_course_id || [])];
        const { id } = program;
        if (updatedCourses?.includes(id)) {
            updatedCourses?.splice(updatedCourses?.indexOf(id), 1);
        } else {
            updatedCourses.push(id);
        }
        setFormData({ ...formData, co_req_course_id: updatedCourses });
    };

    const onSubmit = () => {
        if (formData?.co_req_course_id?.length > 0) {
            const body = {
                course_id: headerId,
                co_req_course_id: formData?.co_req_course_id,
            };

            addCoreqCourseToTemplateCourse(body, setOpen);
        } else {
            warningToaster(warningMessages.academicsTemplateCourseNotseletedWarning);
        }
    };

    return (
        <QuotaAddListProgramMain>
            <QuotaAddListProgramContainer>
                <QuotaAddListProgramWrapper className="p-custom-scrollbar-8">
                    <div className="header">
                        <div className="empty"></div>
                        <div className="heading">
                            <span>Add Co Requisite Courses</span>
                        </div>
                        <div className="close-icon cp" onClick={handleCloseModal}>
                            <CloseMediumSvg className="icon" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="common-fields">
                            <div className="input-field">
                                <label>Select Co Requisite Courses</label>
                                <div className="field-wrap">
                                    <ul className="p-custom-scrollbar-8">
                                        {(courses && courses?.length !== 0) ?
                                            (<>
                                                {
                                                    courses.map((item: any) => (
                                                        <li key={item.id} onClick={() => handleSelect(item)}>
                                                            <div className="checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    // checked={formData.co_req_course_id?.includes(item?.id) || false}
                                                                    checked={(formData.co_req_course_id || []).includes(item?.id)}
                                                                    readOnly
                                                                    aria-label={`Select course ${item?.co_req_course_id}`}
                                                                />
                                                            </div>
                                                            <div className="item-text">
                                                                <span className="text">{item.tc_title}</span>
                                                            </div>
                                                        </li>
                                                    ))

                                                }
                                            </>) : (
                                                <h5>courses not available</h5>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="action-buttons">
                            <div className="buttons">
                                <button
                                    className="lg-rounded-btn gray"
                                    type="button"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                                <button className="lg-rounded-btn" type="submit">
                                    Save & Close
                                </button>
                            </div>
                        </div>
                    </form>
                </QuotaAddListProgramWrapper>
            </QuotaAddListProgramContainer>
        </QuotaAddListProgramMain>
    );
};

export default TemplateCourseCoReqCreate;
