import { FC, useEffect, useState } from "react";
import {
  QuotaAddListProgramMain,
  QuotaAddListProgramContainer,
  QuotaAddListProgramWrapper,
} from "./style";
import { CloseMediumSvg } from "assets/images/common/svgs";
import { useLocation } from "react-router-dom";
import useOrganization from "containers/private/organization/useHooks";
import { AddQuotaProgramLinkDTO } from "utils/helpers/models/admissions/add-quota-program-link.dto";
import useAdmissions from "containers/private/admissions/useHooks";
import { warningToaster } from "utils/helpers/common/alert-service";
import { warningMessages } from "utils/helpers/enums/messages.enum";
import { useForm, useFormContext } from "react-hook-form";
import useFinance from "containers/private/finance/useHooks";
import useUtils from "hooks/useUtils";
import FormErrorMessage from "components/particles/forms/form-error-message";

interface CreateQoutaListProgramProps {
  setOpen: (open: boolean) => void;
  headerId: any;
}

const CreateQoutaListProgram: FC<CreateQoutaListProgramProps> = ({
  setOpen,
  headerId,
}) => {
  const { getQueryParams } = useUtils();
  const params = getQueryParams();

  const [formData, setFormData] = useState<AddQuotaProgramLinkDTO>({
    ...new AddQuotaProgramLinkDTO(),
  });

  const { getPrograms } = useOrganization();
  const [programs, setPrograms] = useState<any[]>([]);
  const { createQuotaProgramLink, getQuotaPrograms } = useAdmissions();

  const handleCloseModal = () => {
    setOpen(false);
  };
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   setValue,
  // } = useFormContext<AddQuotaProgramLinkDTO>();
  const { getFinanceApplications, getVoucherTemplateHeaders, getVoucherTypes } = useFinance();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<AddQuotaProgramLinkDTO>({
    defaultValues: formData,
    mode: "onChange",
  });

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  // const [application, setApplication] = useState<any>([]);
  const [voucherTemplateHeaders, setVoucherTemplateHeaders] = useState<any>([]);
  const [voucherData, setVocherData] = useState<AddQuotaProgramLinkDTO[]>([]);

  useEffect(() => {
    const queryParams = {
      per_page: "All",
      page: 1,
    };
    getVoucherTemplateHeaders(setVoucherTemplateHeaders);
    getQuotaPrograms(setPrograms, queryParams);
    // getFinanceApplications(setApplication);
    getVoucherTypes(setVocherData);
  }, []);
  console.log(headerId);

  const handleSelect = (program: any) => {
    const { id } = program;
    if (formData.program_id.includes(id)) {
      const index = formData.program_id.findIndex((p: any) => p.id === id);
      formData.program_id.splice(index, 1);
    } else {
      formData.program_id.push(id);
    }

    setFormData({ ...formData });
  };

  const onSubmit = () => {
    if (formData.program_id) {
      createQuotaProgramLink(
        { ...formData, adm_session_id: formData.program_id.join(",") },
        setOpen
      );
    } else {
      warningToaster(warningMessages.programsRequiredMsg);
    }
  };
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      quota_id: headerId,
    }));
  }, [headerId]);
  return (
    <QuotaAddListProgramMain>
      <QuotaAddListProgramContainer>
        <QuotaAddListProgramWrapper className="p-custom-scrollbar-8">
          <div className="header">
            <div className="empty"></div>
            <div className="heading">
              <span>Add Programs</span>
            </div>
            <div className="close-icon cp" onClick={handleCloseModal}>
              <CloseMediumSvg className="icon" />
            </div>
          </div>
          <form>
            <div className="input-field">
              <label>Quota seats</label>

              <div className="field-wrap">
                <div className="field">
                  <input
                    type="number"
                    {...register("quota_seats", { required: true })}
                    value={formData.quota_seats}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="input-field">
              {/* <label>Voucher Type</label>
              <div className="field-wrap">
                <div className="field">
                  <select
                    {...register("acc_app_id", { required: true })}
                    value={formData?.acc_app_id as string}
                    onChange={handleChange}
                  >
                    <option value="">Select Type</option>
                    {application?.map((item: any, index: number) => {
                      return <option value={item?.id}>{item?.title}</option>;
                    })}
                  </select>
                </div>
                {/* {errors.acc_app_id && <FormErrorMessage error="app id reqired" />} */}
              {/* </div> */}
              <label htmlFor="application">Voucher Types</label>
              <div className="field-wrap">
                <div className="field">
                  <select {...register('voucher_type_id', { required: true })} value={formData.voucher_type_id} onChange={handleChange}>
                    <option value="">Select Voucher Type</option>
                    {voucherData.map((voucherData: any, index: number) => {
                      return <option value={voucherData.id} key={index}>{voucherData.title}</option>
                    })}
                  </select>
                </div>
                <FormErrorMessage error={errors.voucher_type_id} />
              </div>
            </div>

            <div className="input-field">
              <label>Voucher Templates</label>
              <div className="field-wrap">
                <div className="field">
                  <select
                    {...register("acc_voucher_id", { required: true })}
                    value={formData?.acc_voucher_id as string}
                    onChange={handleChange}
                  >
                    <option value="">Select Voucher Template</option>
                    {voucherTemplateHeaders?.map((item: any, index: number) => {
                      return <option value={item?.id}>{item?.title}</option>;
                    })}
                  </select>
                </div>

                {/* {errors.acc_voucher_id && <FormErrorMessage error="voucher id reqired" />} */}
              </div>
            </div>
            <div className="common-fields">
              <div className="input-field">
                <label>Programs</label>
                <div className="field-wrap">
                  <ul className="p-custom-scrollbar-8">
                    {programs.map((item: any, index: number) => (
                      <li key={index} onClick={() => handleSelect(item)}>
                        <div className="checkbox">
                          <input
                            type="checkbox"
                            checked={formData.program_id.includes(item?.id)}
                            readOnly
                          />
                        </div>
                        <div className="item-text">
                          <span className="text">{item.title}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="buttons">
                <button
                  className="lg-rounded-btn gray"
                  type="button"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
                <button
                  className="lg-rounded-btn"
                  type="button"
                  onClick={onSubmit}
                >
                  Save & Close
                </button>
              </div>
            </div>
          </form>
        </QuotaAddListProgramWrapper>
      </QuotaAddListProgramContainer>
    </QuotaAddListProgramMain >
  );
};

export default CreateQoutaListProgram;
