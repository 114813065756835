import { AddEmailTemplateDTO } from "utils/helpers/models/email-templates/email-templates.dto";
import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
  } from "utils/helpers/common/http-methods";


export const EMAILTEMPLATE_API = {
    createEmailTEmplate: (body: AddEmailTemplateDTO) => postRequest(`admin/email_templates`, body),
    deleteEmailTEmplateById: (id: number) => deleteRequest(`admin/email_templates/${id}`),
    getEmailTEmplate: (params: any) => getRequest(`admin/email_templates`, params),
    getEmailTEmplateByID: (id: number) => getRequest(`admin/email_templates/${id}`,),
    updateEmailTEmplateByID: (id: number , body: AddEmailTemplateDTO) => putRequest(`admin/email_templates/${id}`, body),
    getEmailTemplateKey: () => getRequest("admin/get_email_template_keys"),
}