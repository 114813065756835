export class ResultTemplateDTO {
  id!: number;
  template_header_id: string = "";
  total_marks_distribution: string = "";
  theory_percentage: string = "";
  theory_passing_percentage: string = "";
  practical_percentage: string = "";
  practical_passing_percentage: string = "";
  is_generic!: number;
  credit_hours_theory!: number;
  credit_hours_practical!: number;
  rst_header!: RSTHeaderDTO
}

class RSTHeaderDTO{
  id!: number;
  title: string = ''
}